import React, { useEffect } from "react";
import Button from "../components/form/Button";
import { useDispatch, useSelector } from "react-redux";
import { setSiteSettingData } from "../store/slice/siteSettingSlice";
import { setPages } from "../store/slice/pageSlice";
import axios from "axios";
import BASE_URL from "../config/host";
import { footerLang } from "../components/elements/language";

const DefaultFooter = ({ footerMenuContent = true }) => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const dispatch = useDispatch();
  const fetchSiteSettingData = async () => {
    // setLoaded(false);
    try {
      const response = await axios.get(`${BASE_URL}/site-settings`);
      // setLoaded(true);
      dispatch(setSiteSettingData(response?.data.data));
    } catch (error) {
      // setLoaded(true);
      console.log(error, "error");
    }
  };
  const fetchPageData = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/page/list`, {
        params: { limit: 200 },
      });

      dispatch(setPages(response?.data?.docs?.data));
    } catch (error) {
      console.log(error, "error");
    }
  };
  useEffect(() => {
    fetchSiteSettingData();
    fetchPageData();
  }, []);

  const siteSettingsData = useSelector((state) => state.siteSettings.data);
  const language = useSelector((state) => state?.languageSlice?.data);

  const footerData = {
    links: {
      title: {
        en: "Quick Links",
        es: "Enlaces Rápidos",
        it: "Link Veloci",
      },
      data: [
        { _id: 1, name: footerLang?.what?.[language], link: "/about" },
        {
          _id: 2,
          name: footerLang?.faq?.[language],
          link: "/frequently-asked-questions",
        },
        {
          _id: 3,
          name: footerLang?.privacy?.[language],
          link: "/terms-of-services",
        },
        { _id: 4, name: footerLang?.about?.[language], link: "/about" },
        {
          _id: 5,
          name: footerLang?.terms?.[language],
          link: "/terms-of-services",
        },
        { _id: 6, name: footerLang?.contact?.[language], link: "/contact-us" },
      ],
    },
    about: {
      title: {
        en: "About Us",
        es: "Sobre Nosotros",
        it: "Chi Siamo",
      },
      description: {
        en: "<p>ProntoPsy is the first professional psychological online aid in the world, available 24/7. We are your immediate and prompt online assistance and resource for all your psychological needs.</p>",
        es: "<p>ProntoPsy es la primera ayuda psicológica profesional en línea del mundo, disponible 24 horas al día, 7 días a la semana. Somos su recurso y asistencia en línea inmediata y rápida para todas sus necesidades psicológicas.</p>",
        it: "<p>ProntoPsy è il primo aiuto psicologico professionale online al mondo, disponibile 24 ore su 24, 7 giorni su 7. Siamo la tua assistenza online immediata e tempestiva e la tua risorsa per tutte le tue esigenze psicologiche.</p>",
      },
    },
    contact: {
      title: {
        en: "Contact",
        es: "Contacto",
        it: "Contatto",
      },
      data: [
        {
          _id: 1,
          name: {
            en: "Phone",
            es: "Teléfono",
            it: "Telefono",
          },
          value: siteSettingsData?.phone || "N/A",
          type: "tel",
        },
        {
          _id: 2,
          name: {
            en: "Email",
            es: "Email",
            it: "Email",
          },
          value: siteSettingsData?.email || "N/A",
          type: "email",
        },
      ],
    },
    social: {
      title: {
        en: "Follow us on",
        es: "Siga con nosotros",
        it: "Seguici su",
      },
      data: siteSettingsData && siteSettingsData?.socialMedia,
      // data: [
      //   {
      //     _id: 1,
      //     name: "Facebook",
      //     icon: "fa-brands fa-facebook-f",
      //     link: "https://www.facebook.com/",
      //   },
      //   {
      //     _id: 2,
      //     name: "Twitter",
      //     icon: "fa-brands fa-x-twitter",
      //     link: "https://twitter.com/",
      //   },
      //   {
      //     _id: 3,
      //     name: "Google",
      //     icon: "fa-brands fa-google",
      //     link: "https://www.google.com/",
      //   },
      //   {
      //     _id: 4,
      //     name: "Instagram",
      //     icon: "fa-brands fa-instagram",
      //     link: "https://www.instagram.com/",
      //   },
      // ],
    },
    copyright: (siteSettingsData && siteSettingsData?.copyright) || "N/A",
  };
  return (
    <>
      <footer className="relative">
        {footerMenuContent && (
          <div className="relative pt-10 sm:pt-16 md:pt-20 lg:pt-24 xl:pt-28 pb-10 md:pb-12 lg:pb-14 xl:pb-16 bg-prontopsy-blue">
            <div className="w-full px-4 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-8xl mx-auto flex items-center">
              <div className="block lg:grid grid-cols-20 gap-10 w-full space-y-10 lg:space-y-0">
                <div className="relative col-span-20 lg:col-span-10 xl:col-span-5 space-y-6">
                  {footerData?.links && (
                    <>
                      {footerData?.links?.title && (
                        <h2 className="text-lg lg:text-xl text-white font-UnisonPro italic font-bold">
                          {footerData?.links?.title?.[language]}
                        </h2>
                      )}
                      {footerData?.links?.data?.length > 0 && (
                        <div className="relative space-y-1">
                          {footerData?.links?.data?.map((item, index) => (
                            <div className="flex" key={index}>
                              <Button
                                buttonHasLink={true}
                                buttonLink={item.link}
                                buttonLabel={item?.name}
                                buttonClasses={
                                  "!justify-start !rounded-none !bg-transparent !border-none !h-10 !px-0 group/link !text-xs !gap-3 hover:!text-prontopsy-pink !overflow-visible"
                                }
                                buttonLabelClasses={
                                  "!text-lg group-hover/link:translate-x-2 uppercase !text-white"
                                }
                                buttonIcon={
                                  "fa-solid fa-sharp fa-triangle fa-rotate-90"
                                }
                                buttonIconPosition={"left"}
                              />
                            </div>
                          ))}
                        </div>
                      )}
                    </>
                  )}
                </div>
                <div className="relative col-span-20 lg:col-span-10 xl:col-span-7 space-y-6">
                  {footerData?.about && (
                    <>
                      {footerData?.about?.title && (
                        <h2 className="text-lg lg:text-xl text-white font-UnisonPro italic font-bold">
                          {footerData?.about?.title?.[language]}
                        </h2>
                      )}
                      {siteSettingsData?.description && (
                        <div
                          className="text-sm md:text-base lg:text-lg !text-white space-y-4 font-light max-w-sm"
                          dangerouslySetInnerHTML={{
                            // __html: siteSettingsData?.description,
                            __html: footerData?.about?.description?.[language],
                          }}
                        ></div>
                      )}
                    </>
                  )}
                </div>
                <div className="relative col-span-20 lg:col-span-10 xl:col-span-8 space-y-16">
                  {footerData?.contact && (
                    <div className="space-y-6">
                      {footerData?.contact?.title && (
                        <h2 className="text-lg lg:text-xl text-white font-UnisonPro italic font-bold">
                          {footerData?.contact?.title?.[language]}
                        </h2>
                      )}
                      {footerData?.contact?.data?.length > 0 && (
                        <div className="relative space-y-2">
                          {footerData?.contact?.data?.map((item, index) => (
                            <div className="flex" key={index}>
                              <Button
                                buttonHasLink={true}
                                buttonLink={
                                  item.type === "tel"
                                    ? "tel:" + item.value
                                    : item.type === "email"
                                    ? "mailto:" + item.value
                                    : item.value
                                }
                                buttonLabel={
                                  item?.name?.[language] + ": " + item.value
                                }
                                buttonClasses={
                                  "!justify-start !rounded-none !bg-transparent !border-none !h-10 !px-0 hover:underline underline-offset-4"
                                }
                                buttonLabelClasses={
                                  "!text-base lg:!text-lg !font-normal"
                                }
                              />
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  )}
                  {footerData?.social && (
                    <div className="space-y-6">
                      {footerData?.social?.title && (
                        <h2 className="text-xl text-white font-UnisonPro italic font-bold">
                          {footerData?.social?.title?.[language]}
                        </h2>
                      )}
                      {footerData?.social?.data?.length > 0 && (
                        <div className="relative space-x-4 flex">
                          {footerData?.social?.data?.map((item, index) => (
                            <div key={index}>
                              <Button
                                buttonHasLink={true}
                                buttonTarget={"_blank"}
                                buttonLink={item.url}
                                buttonIcon={item.icon}
                                buttonIconPosition={"left"}
                                buttonClasses={
                                  "!px-0 aspect-square !h-12 !text-xl !bg-transparent border border-white !rounded-full hover:!bg-white hover:!text-prontopsy-blue"
                                }
                              />
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="relative py-5 lg:py-10 bg-prontopsy-darkblue">
          <div className="w-full px-4 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-8xl mx-auto flex items-center">
            {footerData?.copyright && (
              <div className="flex justify-center w-full">
                <div className="text-base text-white">
                  <span>
                    Copyright &copy; {`${new Date().getFullYear()}`}{" "}
                    <span className="font-weight-semibold">Prontopsy</span> All
                    rights reserved.
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      </footer>
    </>
  );
};

export default DefaultFooter;
