import React from "react";
import Image from "../../elements/Image";
import User from "../../../assets/images/user-01.webp";

const UserChats = ({
  messages,
  inputMessage,
  setInputMessage,
  sendMessage,
}) => {
  const handleSubmit = (event) => {
    event.preventDefault();
    if (inputMessage.trim()) {
      sendMessage(inputMessage);
    }
  };
  return (
    <>
      <div
        className={
          "w-full max-w-80 h-[calc(100vh-4rem)] flex flex-col  bg-slate-900"
        }
      >
        <div className="flex items-center bg-prontopsy-blue p-3 flex-shrink-0 text-white shadow-lg">
          <div>
            <Image
              src={User}
              alt=""
              effect="blur"
              className="object-cover !w-14 !min-h-14"
            />
          </div>
          <div className="pl-4">
            <h2 className="text-lg">chatUserList</h2>
            <h3 className="text-xs">online</h3>
          </div>
          <div className="flex ml-auto">
            <div className="px-2">
              <i class="fa-regular fa-user-plus"></i>
            </div>
            <div className="px-2">
              <i class="fa-regular fa-star"></i>
            </div>
            <div className="px-2">
              <i class="fa-solid fa-ellipsis-vertical"></i>
            </div>
          </div>
        </div>

        <div className="py-6 px-12 space-y-8 flex-shrink flex-grow max-h-full overflow-auto">
          {messages?.map((message, index) => (
            <div
              key={index}
              className={`flex items-end gap-3 ${
                message.sender === "me" ? "flex-row-reverse" : ""
              }`}
            >
              <div className="w-10 aspect-square flex-shrink-0">
                <Image
                  src={User}
                  alt=""
                  effect="blur"
                  className="object-cover rounded-full"
                />
              </div>
              <div className="flex-shrink flex-grow space-y-1 max-w-2xl text-sm">
                <div className="flex justify-start">
                  <div
                    className={`relative py-3 px-4 rounded-lg ${
                      message.sender === "me"
                        ? "bg-slate-100 text-black"
                        : "bg-prontopsy-pink text-white"
                    } tooltip`}
                    style={{
                      maxWidth: "calc(100% - 2.5rem)",
                      wordBreak: "break-word",
                      overflowWrap: "break-word",
                    }}
                  >
                    {message.text}
                    <div
                      className={`absolute ${
                        message.sender === "me"
                          ? "right-[-14px]"
                          : "left-[-14px]"
                      } top-1/2 transform -translate-y-1/2 w-0 h-0 border-8 border-transparent ${
                        message.sender === "me"
                          ? "border-l-slate-100"
                          : "border-r-prontopsy-pink"
                      }`}
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className=" shadow-lg rounded-b-lg py-3 px-2  flex flex-col space-y-4 max-w-lg mx-auto ">
          <form onSubmit={handleSubmit} className="flex items-center gap-2">
            <input
              type="text"
              value={inputMessage}
              onChange={(e) => setInputMessage(e.target.value)}
              placeholder="Type your message here..."
              className="flex-1 px-4 py-2 border border-gray-300 rounded-lg focus:outline-none  focus:ring-blue-500"
            />
            <button
              type="submit"
              className="px-4 py-2 bg-blue-500 text-white font-semibold rounded-lg shadow hover:bg-blue-600 "
            >
              <i class="fa-sharp fa-solid fa-paper-plane-top"></i>
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default UserChats;
