import axios from "axios";
import BASE_URL from "../config/host";

export const fetchBookingTypes = async (setList, setLoaded = () => {}) => {
  setLoaded(false);
  try {
    let response = await axios.get(`${BASE_URL}/category/list`, {
      params: {
        type: "consultantService",
      },
    });
    console.log(response, "hhuhuhu");

    if (response.status === 200) {
      setList(response.data?.docs?.data);
      setLoaded(true);
    }

    return response;
  } catch (error) {
    setLoaded(true);
    return error.response;
  }
};

export const fetchBookingTypeHome = async (setList, setLoaded = () => {}) => {
  setLoaded(false);
  try {
    let response = await axios.get(`${BASE_URL}/category/list`, {
      params: {
        type: "consultation",
      },
    });

    if (response.status === 200) {
      setList(response.data?.docs?.data);
      setLoaded(true);
    }

    return response;
  } catch (error) {
    setLoaded(true);
    return error.response;
  }
};
