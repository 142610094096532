import React, { useEffect, useState } from "react";
import Image from "../../../Image";
import User from "../../../../../assets/images/user-03.webp";
import Button from "../../../../form/Button";
import InputGroup from "../../../../form/Input";
import Select from "../../../../form/Select";
import { useDispatch, useSelector } from "react-redux";
import {
  commonFormLabels,
  findPsychologistLang,
  profile,
  saveChange,
} from "../../../language";
import dayjs from "dayjs";
import {
  fetchCountryList,
  updateProfileService,
} from "../../../../../service/profileService";
import { toast } from "react-toastify";
import UploadImage from "../../../UploadImage";
import { setProfileData } from "../../../../../store/slice/profileSlice";
import { setInitialProfileData } from "../../../../../store/slice/initialProfileSlice";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import InputDate from "../../../../form/InputDate";

const InformazioniPersonall = ({ data, dataLoaded }) => {
  const language = useSelector((state) => state.languageSlice.data);
  const profileData = useSelector((state) => state?.profileSlice?.data);
  const [formData, setFormData] = useState({});
  const [countryList, setCountryList] = useState([]);
  const [countryLoaded, setCountryLoaded] = useState(true);
  const [image, setImage] = useState(null);
  const [loaded, setLoaded] = useState(true);
  const [nationalityTemp, setNationalityTemp] = useState({});
  const [imageUrl, setImageUrl] = useState(null);
  const dispatch = useDispatch();
  const role = profileData && profileData?.roleCode;
  const id = profileData && profileData?._id;
  // const role = JSON.parse(localStorage.getItem("userDetails"))?.roleCode;
  // const id = JSON.parse(localStorage.getItem("userDetails"))?._id;

  const informazioniPersonallData = {
    title: profile?.personalInformation?.[language],
    image: User,
  };
  const nationalityData =
    countryList.length > 0 &&
    countryList.map((elm) => ({
      value: elm._id,
      name: elm.name,
    }));
  const timeZoneData =
    countryList.length > 0 &&
    countryList
      ?.map((country) =>
        country?.timezones?.map((timezone) => ({
          zoneName: timezone?.zoneName,
          gmtOffsetName: timezone?.gmtOffsetName,
        }))
      )
      .flat();
  const zoneData =
    timeZoneData &&
    timeZoneData.map((elm) => ({
      name: elm?.zoneName,
      value: elm?.zoneName,
    }));
  const countryAllData =
    countryList.length > 0 &&
    countryList.map((elm) => ({
      value: elm.name,
      name: elm.name,
    }));

  const genderData = [
    { value: "male", name: "Male" },
    { value: "female", name: "Female" },
    { value: "other", name: "Other" },
  ];

  useEffect(() => {
    fetchCountryList(setCountryList, setCountryLoaded);
  }, []);

  useEffect(() => {
    setFormData((pre) => ({
      ...pre,
      firstName: profileData && profileData?.firstName,
      lastName: profileData && profileData?.lastName,
      "personal[gender]": profileData && profileData?.personal?.gender,
      "personal[addressOfBirth][country]":
        profileData && profileData?.personal?.addressOfBirth?.country,
      "personal[addressOfBirth][zipcode]":
        profileData && profileData?.personal?.addressOfBirth?.zipcode,
      phone: profileData && profileData?.phone,
      dateOfBirth: profileData?.dateOfBirth,
      // profileData && profileData?.dateOfBirth
      //   ? dayjs(profileData?.dateOfBirth).format("MM/DD/YYYY")
      //   : null,
      "personal[addressOfBirth][city]":
        profileData && profileData?.personal?.addressOfBirth?.city,
      "personal[addressOfBirth][state]":
        profileData && profileData?.personal?.addressOfBirth?.state,
      "personal[nationality]":
        profileData && profileData?.personal?.nationality?._id,
      "personal[timeZone][zoneName]":
        profileData && profileData?.personal?.timeZone?.zoneName,
      "personal[timeZone][gmtOffsetName]":
        profileData && profileData?.personal?.timeZone?.gmtOffsetName,
    }));
    setNationalityTemp({
      name: profileData && profileData?.personal?.nationality?.nationality,
      value: profileData && profileData?.personal?.nationality?._id,
    });
  }, [profileData]);

  const handleFormData = (e) => {
    setFormData((pre) => ({
      ...pre,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = () => {
    if (!formData?.firstName) {
      toast.info("First name is required!");
      return;
    }
    if (!formData?.lastName) {
      toast.info("Last name is required!");
      return;
    }
    if (!formData?.["personal[gender]"]) {
      toast.info("Gender is required!");
      return;
    }
    if (!formData?.["personal[addressOfBirth][country]"]) {
      toast.info("Country is required!");
      return;
    }
    if (!formData?.["personal[addressOfBirth][zipcode]"]) {
      toast.info("ZIP code is required!");
      return;
    }
    if (!formData?.phone) {
      toast.info("Phone number is required!");
      return;
    }
    if (!formData?.dateOfBirth) {
      toast.info("Date of birth is required!");
      return;
    }
    if (!formData?.["personal[addressOfBirth][city]"]) {
      toast.info("City name is required!");
      return;
    }
    if (!formData?.["personal[addressOfBirth][state]"]) {
      toast.info("State name is required!");
      return;
    }
    if (!formData?.["personal[nationality]"]) {
      toast.info("Nationality is required!");
      return;
    }
    if (!formData?.["personal[timeZone][zoneName]"]) {
      toast.info("Zone name is required!");
      return;
    }

    //api call to update data
    setLoaded(false);
    updateProfileService({
      ...formData,
      roleCode: role,
      id: id,
      image: image || "",
    }).then((res) => {
      setLoaded(true);
      if (res.data.status == 200) {
        let info = res.data.data;
        let resData = {
          _id: info?._id,
          firstName: info?.firstName,
          lastName: info?.lastName,
          image: info?.image,
          email: info?.email,
          roleCode: info?.roleCode,
          createdAt: info?.createdAt,
        };

        toast.success(res?.data?.message);
        setImageUrl(res?.data?.data?.image?.url);
        dispatch(setProfileData(res.data.data));
        dispatch(setInitialProfileData(resData));
        localStorage.setItem("userDetails", JSON.stringify(resData));
      }
    });
  };
  useEffect(() => {
    setImageUrl(profileData && profileData?.image?.url);
  }, [profileData]);

  useEffect(() => {
    if (image) {
      const imageUrl = URL.createObjectURL(image);
      setImageUrl(imageUrl);
    }
  }, [image]);

  return (
    <div className="relative">
      <div className="relative w-full border-t border-dashed border-neutral-300 py-4">
        {informazioniPersonallData?.title && (
          <h2
            className="text-base text-prontopsy-blue font-RobotoSlab font-bold"
            dangerouslySetInnerHTML={{
              __html: informazioniPersonallData?.title,
            }}
          ></h2>
        )}
      </div>
      <div className="block lg:flex justify-between gap-5 py-10 border-t border-b border-neutral-300 space-y-5 lg:space-y-0">
        {/* <div className="w-full lg:w-60 h-60 relative">
          <div className="w-full h-full rounded-lg lg:rounded-full overflow-hidden">
            <Image
              src={imageUrl && imageUrl}
              alt={""}
              effect={"blur"}
              className={"object-cover w-full h-full"}
            />
          </div>
          <div className="flex items-center justify-center  absolute bottom-3 right-3 ">
            <UploadImage setImage={setImage} />
          </div>
        </div> */}

        <div className="w-full lg:w-60 h-60 relative">
          {!dataLoaded ? (
            <div className="w-full h-full rounded-lg lg:rounded-full overflow-hidden animate-pulse bg-gray-200"></div>
          ) : (
            <>
              <div className="w-full h-full rounded-lg lg:rounded-full overflow-hidden">
                <Image
                  src={imageUrl}
                  alt=""
                  className="object-cover w-full h-full"
                />
              </div>
              <div className="flex items-center justify-center absolute bottom-3 right-3">
                <UploadImage setImage={setImage} />
              </div>
            </>
          )}
        </div>

        <div className="w-full lg:w-[calc(100%-240px)]">
          <div className="grid grid-cols-12 gap-3">
            <div className="col-span-4">
              <div className="relative">
                <InputGroup
                  label={`*${commonFormLabels?.firstName?.[language]} :`}
                  inputPlaceholder={commonFormLabels?.firstName?.[language]}
                  inputValue={formData?.firstName}
                  loaded={dataLoaded}
                  inputName={"firstName"}
                  onChange={handleFormData}
                  labelClasses={
                    "!uppercase !text-prontopsy-blue !text-[10px] font-RobotoSlab"
                  }
                  inputClasses={"!bg-transparent !h-12"}
                />
              </div>
            </div>
            <div className="col-span-4">
              <div className="relative">
                <InputGroup
                  label={`*${commonFormLabels?.lastName?.[language]} :`}
                  inputPlaceholder={commonFormLabels?.lastName?.[language]}
                  inputValue={formData?.lastName}
                  loaded={dataLoaded}
                  inputName={"lastName"}
                  onChange={handleFormData}
                  labelClasses={
                    "!uppercase !text-prontopsy-blue !text-[10px] font-RobotoSlab"
                  }
                  inputClasses={"!bg-transparent !h-12"}
                />
              </div>
            </div>
            <div className="col-span-4">
              <div className="relative">
                <Select
                  label={`*${commonFormLabels?.gender?.[language]} :`}
                  selectedValue={{
                    name: formData?.["personal[gender]"],
                    value: formData?.["personal[gender]"],
                  }}
                  placeholder={`${findPsychologistLang?.select?.[language]} ${commonFormLabels?.gender?.[language]}`}
                  labelClasses={
                    "!uppercase !text-prontopsy-blue !text-[10px] font-RobotoSlab"
                  }
                  dropdownButtonClass={"!h-12 !bg-transparent"}
                  isSearch={true}
                  loaded={dataLoaded}
                  dropdownData={genderData}
                  dropdownClass={"w-full"}
                  handleSelect={(val) =>
                    handleFormData({
                      target: { value: val?.value, name: "personal[gender]" },
                    })
                  }
                />
              </div>
            </div>
            <div className="col-span-4">
              <div className="relative">
                <InputGroup
                  label={`*${commonFormLabels?.phone?.[language]} :`}
                  inputPlaceholder={commonFormLabels?.phone?.[language]}
                  inputName={"phone"}
                  loaded={dataLoaded}
                  onChange={handleFormData}
                  inputValue={formData?.phone}
                  labelClasses={
                    "!uppercase !text-prontopsy-blue !text-[10px] font-RobotoSlab"
                  }
                  inputClasses={"!bg-transparent !h-12"}
                />
              </div>
            </div>
            <div className="col-span-4">
              <div className="relative">
                <InputGroup
                  label={`*${commonFormLabels?.code?.[language]} :`}
                  inputPlaceholder={commonFormLabels?.code?.[language]}
                  loaded={dataLoaded}
                  inputName={"personal[addressOfBirth][zipcode]"}
                  inputValue={formData?.["personal[addressOfBirth][zipcode]"]}
                  onChange={handleFormData}
                  labelClasses={
                    "!uppercase !text-prontopsy-blue !text-[10px] font-RobotoSlab"
                  }
                  inputClasses={"!bg-transparent !h-12"}
                />
              </div>
            </div>
            <div className="col-span-4">
              <div className="relative">
                <Select
                  label={`*${commonFormLabels?.nationality?.[language]} :`}
                  selectedValue={nationalityTemp && { ...nationalityTemp }}
                  loaded={dataLoaded}
                  labelClasses={
                    "!uppercase !text-prontopsy-blue !text-[10px] font-RobotoSlab"
                  }
                  dropdownButtonClass={"!h-12 !bg-transparent"}
                  placeholder={`${findPsychologistLang?.select?.[language]} ${commonFormLabels?.nationality?.[language]}`}
                  isSearch={true}
                  dropdownData={nationalityData}
                  dropdownClass={"w-full"}
                  handleSelect={(val) => {
                    setNationalityTemp(val);
                    handleFormData({
                      target: {
                        value: val?.value,
                        name: "personal[nationality]",
                      },
                    });
                  }}
                />
              </div>
            </div>
            <div className="col-span-4">
              <div className="relative">
                <Select
                  label={`*${commonFormLabels?.timeZone?.[language]} :`}
                  selectedValue={{
                    name: formData?.["personal[timeZone][zoneName]"],
                    value: formData?.["personal[timeZone][zoneName]"],
                  }}
                  loaded={dataLoaded}
                  labelClasses={
                    "!uppercase !text-prontopsy-blue !text-[10px] font-RobotoSlab"
                  }
                  placeholder={`${findPsychologistLang?.select?.[language]} ${commonFormLabels?.timeZone?.[language]}`}
                  dropdownButtonClass={"!h-12 !bg-transparent"}
                  isSearch={true}
                  dropdownData={zoneData}
                  dropdownClass={"w-full"}
                  handleSelect={(val) =>
                    handleFormData({
                      target: {
                        value: val?.value,
                        name: "personal[timeZone][zoneName]",
                      },
                    })
                  }
                />
              </div>
            </div>
            <div className="col-span-4">
              <div className="relative">
                <InputDate
                  label={`*${commonFormLabels?.dateOfBirth?.[language]} :`}
                  // inputPlaceholder={"MM/DD/YYYY"}
                  inputName={"dateOfBirth"}
                  loaded={dataLoaded}
                  onChange={(value) => {
                    setFormData((pre) => ({
                      ...pre,
                      dateOfBirth: value,
                    }));
                  }}
                  value={formData?.dateOfBirth}
                  labelClasses={
                    "!uppercase !text-prontopsy-blue !text-[10px] font-RobotoSlab"
                  }
                  inputClasses={"!bg-transparent !h-12"}
                />
                {/* <span className="!uppercase !text-prontopsy-blue !text-[10px] font-RobotoSlab">
                  *Date of birth :
                </span>
                <DatePicker
                  onChange={(value) => {
                    setFormData((pre) => ({
                      ...pre,
                      dateOfBirth: value,
                    }));
                  }}
                  value={formData?.dateOfBirth}
                  dayPlaceholder="DD"
                  monthPlaceholder="MM"
                  yearPlaceholder="YYYY"
                  format="dd/MM/yyyy"
                  className={
                    "!bg-transparent !h-12 w-full min-w-[40px] flex items-center justify-center !border-0 rounded-md bg-slate-50 text-sm text-slate-500 outline-none"
                  }
                /> */}
              </div>
            </div>
            <div className="col-span-4">
              <div className="relative">
                <InputGroup
                  label={`*${commonFormLabels?.city?.[language]} :`}
                  inputPlaceholder={commonFormLabels?.city?.[language]}
                  loaded={dataLoaded}
                  inputName={"personal[addressOfBirth][city]"}
                  inputValue={formData?.["personal[addressOfBirth][city]"]}
                  labelClasses={
                    "!uppercase !text-prontopsy-blue !text-[10px] font-RobotoSlab"
                  }
                  onChange={handleFormData}
                  inputClasses={"!bg-transparent !h-12"}
                />
              </div>
            </div>
            <div className="col-span-4">
              <div className="relative">
                <InputGroup
                  label={`*${commonFormLabels?.state?.[language]} :`}
                  inputPlaceholder={commonFormLabels?.state?.[language]}
                  inputName={"personal[addressOfBirth][state]"}
                  loaded={dataLoaded}
                  onChange={handleFormData}
                  inputValue={formData?.["personal[addressOfBirth][state]"]}
                  labelClasses={
                    "!uppercase !text-prontopsy-blue !text-[10px] font-RobotoSlab"
                  }
                  inputClasses={"!bg-transparent !h-12"}
                />
              </div>
            </div>
            <div className="col-span-4">
              <div className="relative">
                <Select
                  label={`*${commonFormLabels?.country?.[language]} :`}
                  selectedValue={{
                    name: formData?.["personal[addressOfBirth][country]"],
                    value: formData?.["personal[addressOfBirth][country]"],
                  }}
                  placeholder={`${findPsychologistLang?.select?.[language]} ${commonFormLabels?.country?.[language]}`}
                  loaded={dataLoaded}
                  labelClasses={
                    "!uppercase !text-prontopsy-blue !text-[10px] font-RobotoSlab"
                  }
                  dropdownButtonClass={"!h-12 !bg-transparent"}
                  isSearch={true}
                  dropdownData={countryAllData}
                  dropdownClass={"w-full"}
                  handleSelect={(val) =>
                    handleFormData({
                      target: {
                        value: val?.value,
                        name: "personal[addressOfBirth][country]",
                      },
                    })
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="relative mt-3">
        <Button
          buttonLabel={saveChange?.[language]}
          buttonLabelClasses={
            "!uppercase !text-base !whitespace-nowrap !font-medium"
          }
          buttonClasses={
            "!rounded-xl !px-4 !h-12 !text-prontopsy-pink !text-white !bg-gradient-to-r !from-prontopsy-blue !to-prontopsy-pink !hover:bg-prontopsy-pink !duration-300"
          }
          buttonFunction={handleSubmit}
          isDisable={!loaded}
        />
      </div>
    </div>
  );
};

export default InformazioniPersonall;
