import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import React, { useState } from "react";
import Button from "../../form/Button";

const StripeForm = ({ setToken = () => {} }) => {
  const [stripeToken, setStripeToken] = useState(null);
  const stripe = useStripe();
  const elements = useElements();

  const handleChange = async (event) => {
    if (event.complete) {
      const cardElement = elements.getElement("card");
      const token = await stripe.createToken(cardElement);
      setStripeToken(token.token.id);
      setToken(token.token.id);
    } else {
      setStripeToken(null);
      setToken(null);
    }
  };

  return (
    <div className="w-full">
      <form className="w-full">
        <CardElement
          onChange={handleChange}
          options={{ hidePostalCode: true }}
        />
      </form>
    </div>
  );
};

export default StripeForm;
