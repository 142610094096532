import React, { useState } from "react";
import InputGroup from "../../form/Input";
import Textarea from "../../form/Textarea";
import Button from "../../form/Button";
import { contactUsRequest } from "../../../service/contactService";
import { toast } from "react-toastify";
import { submitBtn } from "../../elements/language";
const BookAnInterview = ({ bannerTitle, language }) => {
  const [loaded, setLoaded] = useState(true);
  const [formData, setFormData] = useState({ type: "interview" });
  const [clicked, setClicked] = useState(false);

  function isValidEmail(email) {
    // Define a regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // Test the input string against the regular expression
    return emailRegex.test(email);
  }
  let validate = isValidEmail(formData.email);

  const handleInput = (e) => {
    setFormData((pre) => ({
      ...pre,
      [e.target.name]: e.target.value,
    }));
    setClicked(false);
  };

  const handleSubmit = () => {
    setClicked(true);
    if (
      formData.name &&
      formData.phone &&
      formData.message &&
      validate == true
    ) {
      contactUsRequest(formData, setLoaded).then((res) => {
        if (res.status === 201) {
          toast.success(res.message);
          setFormData({});
          setClicked(false);
        }
      });
    }
  };

  const interviewData = {
    title: bannerTitle,
  };

  const namePlaceholder = {
    en: "Name",
    es: "Nombre",
    it: "Nome",
  };
  const emailPlaceholder = {
    en: "Email",
    es: "Correo electrónico",
    it: "E-mail",
  };
  const phonePlaceholder = {
    en: "Phone",
    es: "Teléfono",
    it: "Telefono",
  };
  const messagePlaceholder = {
    en: "Feedback Message",
    es: "Mensaje de comentarios",
    it: "Messaggio di feedback",
  };

  const nameErrorText = {
    en: "Name is required!",
    es: "¡Se requiere el nombre!",
    it: "Il nome è obbligatorio!",
  };
  const emailErrorText = {
    en: "Enter a valid email id!",
    es: "¡Ingrese una identificación de correo electrónico válida!",
    it: "Inserisci un ID e-mail valido!",
  };
  const phoneErrorText = {
    en: "Phone is required!",
    es: "¡Se requiere teléfono!",
    it: "È necessario il telefono!",
  };
  const messageErrorText = {
    en: "Message is required!",
    es: "¡Se requiere mensaje!",
    it: "Il messaggio è obbligatorio!",
  };
  return (
    <section className="relative py-32">
      <div className="w-full px-4 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-8xl mx-auto">
        <div className="mx-auto max-w-2xl">
          {interviewData?.title && (
            <h2
              className="!mb-6 sm:!mb-8 md:!mb-10 text-center uppercase text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl text-prontopsy-darkblue font-UnisonPro italic font-bold !leading-[1.1]"
              dangerouslySetInnerHTML={{ __html: interviewData?.title }}
            ></h2>
          )}
        </div>
        <div className="grid grid-cols-12 gap-5 3xl:px-52 xl:px-36">
          <div className="col-span-12 md:col-span-4">
            <div className="relative">
              <InputGroup
                onChange={handleInput}
                inputName={"name"}
                errorType={!formData.name && clicked == true && "warning"}
                errorText={nameErrorText?.[language]}
                inputValue={formData.name}
                labelClasses={"font-RobotoSlab"}
                inputPlaceholder={namePlaceholder?.[language]}
                divClass={"!border !border-black !bg-transparent"}
                inputClasses={
                  "!text-black !border-black  placeholder:text-black h-10 md:h-12 lg:h-14 xl:h-16 rounded-none !bg-white"
                }
              />
            </div>
          </div>
          <div className="col-span-12 md:col-span-4">
            <div className="relative">
              <InputGroup
                onChange={handleInput}
                inputName={"email"}
                errorType={
                  clicked == true &&
                  (!formData.email
                    ? "warning"
                    : isValidEmail(formData.email)
                    ? ""
                    : "danger")
                }
                errorText={
                  formData.email && isValidEmail(formData.email)
                    ? ""
                    : emailErrorText?.[language]
                }
                inputValue={formData.email}
                labelClasses={"font-RobotoSlab"}
                inputType={"email"}
                inputPlaceholder={emailPlaceholder?.[language]}
                divClass={"!border !border-black !bg-transparent"}
                inputClasses={
                  "!text-black !border-black  placeholder:text-black h-10 md:h-12 lg:h-14 xl:h-16 rounded-none !bg-white"
                }
              />
            </div>
          </div>
          <div className="col-span-12 md:col-span-4">
            <div className="relative">
              <InputGroup
                onChange={handleInput}
                inputName={"phone"}
                errorType={!formData.phone && clicked == true && "warning"}
                errorText={phoneErrorText?.[language]}
                inputValue={formData.phone}
                labelClasses={"font-RobotoSlab"}
                inputPlaceholder={phonePlaceholder?.[language]}
                divClass={"!border !border-black !bg-transparent"}
                inputClasses={
                  "!text-black !border-black  placeholder:text-black h-10 md:h-12 lg:h-14 xl:h-16 rounded-none !bg-white"
                }
              />
            </div>
          </div>
          <div className="col-span-12">
            <div className="relative">
              <Textarea
                onChange={handleInput}
                inputName={"message"}
                errorType={!formData.message && clicked == true && "warning"}
                errorText={messageErrorText?.[language]}
                inputValue={formData.message}
                inputPlaceholder={messagePlaceholder?.[language]}
                inputClasses={
                  "!text-black !border-black  placeholder:text-black h-16 lg:h-20 xl:h-24 rounded-none !bg-white"
                }
              />
            </div>
          </div>
          <div className="col-span-12 mt-8">
            <div className="flex justify-center">
              <div className="relative">
                <Button
                  buttonLabel={submitBtn?.[language]}
                  buttonLabelClasses={"!capitalize font-RobotoSlab"}
                  buttonClasses={"!h-10 lg:!h-12 !bg-prontopsy-blue !px-16"}
                  buttonFunction={handleSubmit}
                  isDisable={!loaded}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BookAnInterview;
