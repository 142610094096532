import React, { useContext, useEffect, useRef, useState } from "react";
// import Image from "../../elements/Image";
// import User from "../../../assets/images/user-01.webp";
// import Button from "../../form/Button";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import SpinLoader from "../../elements/table/SpinLoader";
import {
  fetchEmailDetailsList,
  sendMessage,
} from "../../../service/emailService";
import Image from "../../elements/Image";
import Button from "../../form/Button";
// import { fetchEmailDetailsList } from "../../../service/emailService";
// import SpinLoader from "../../elements/table/SpinLoader";
// import useSocket from "../../socket/UseSocket";
// import SocketContext, { useSocket } from "../../socket/SocketProvider";
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import { toast } from "react-toastify";

const UserEmailDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  // const { socket } = useContext(SocketContext);
  const [list, setList] = useState([]);
  const [loaded, setLoaded] = useState(true);
  const user = JSON.parse(localStorage.getItem("userDetails"));
  const location = useLocation();
  let consultantId = new URLSearchParams(location.search).get("consultantId");
  const [consultant, setConsultant] = useState(null);
  const targetRef = useRef(null);
  const [attachments, setAttachments] = useState([]);
  const [submitLoaded, setSubmitLoaded] = useState(true);

  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  useEffect(() => {
    Fancybox.bind("[data-fancybox]", {
      // Customize Fancybox options here
    });
  }, []);
  //*************************************************** */
  const [hasNextPage, setHasNextPage] = useState(false);
  const [page, setPage] = useState(1);
  const [allList, setAllList] = useState([]);
  const [scrolledToTop, setScrolledToTop] = useState(false);
  const scrollableRef = useRef(null);
  const [limit, setLimit] = useState(7);

  useEffect(() => {
    const handleScroll = () => {
      if (scrollableRef.current) {
        const { scrollTop } = scrollableRef.current;
        setScrolledToTop(scrollTop === 0); // Set true when scrolled to top
      }
    };

    const scrollableElement = scrollableRef.current;
    scrollableElement?.addEventListener("scroll", handleScroll);

    return () => {
      scrollableElement?.removeEventListener("scroll", handleScroll);
    };
  }, [scrollableRef.current]); // Dependency on scrollableRef.current

  useEffect(() => {
    if (scrolledToTop && hasNextPage) {
      setPage((prevPage) => prevPage + 1);
    }
  }, [scrolledToTop, hasNextPage]);

  const handleFileUpload = (files) => {
    setAttachments([...attachments, ...files]);
  };
  const mediaObject = {};
  attachments.forEach((attachment, index) => {
    mediaObject[`media[${index}]`] = attachment;
  });
  const handleRemoveAttachment = (index) => {
    const newAttachments = attachments.filter((_, i) => i !== index);
    setAttachments(newAttachments);
  };
  const renderPreview = (file) => {
    const fileType = file.type;
    if (fileType.startsWith("image/")) {
      return (
        <img
          src={URL.createObjectURL(file)}
          alt={file.name}
          className="w-full h-full object-cover"
        />
      );
    } else if (fileType === "application/pdf") {
      return (
        <object
          data={URL.createObjectURL(file)}
          type="application/pdf"
          className="w-full h-full"
        >
          <i className="fa-solid fa-file-pdf text-4xl text-red-600"></i>
        </object>
      );
    } else if (
      fileType === "application/msword" ||
      fileType ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) {
      return <i className="fa-solid fa-file-word text-4xl text-blue-600"></i>;
    } else if (
      fileType === "application/vnd.ms-excel" ||
      fileType ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      return <i className="fa-solid fa-file-excel text-4xl text-green-600"></i>;
    } else {
      return <i className="fa-solid fa-file text-4xl text-gray-600"></i>;
    }
  };
  const renderAttachment = (attachment) => {
    const { url, filename, extension, loading } = attachment;

    if (loading) {
      return <i className="fa fa-spinner fa-spin text-4xl text-gray-600"></i>;
    }

    const handleDownload = async (e) => {
      e.stopPropagation();
      e.preventDefault();
      try {
        const response = await fetch(url, { crossOrigin: "anonymous" });
        const blob = await response.blob();
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error("Download failed", error);
      }
    };

    const downloadIcon = (
      <button
        onClick={handleDownload}
        className="z-40 absolute top-1 right-1 bg-white py-1 px-2 rounded-full hover:bg-slate-200"
      >
        <i className="fa-solid fa-download text-xl text-gray-600"></i>
      </button>
    );

    const commonProps = {
      className: "relative hover:bg-slate-200",
      target: "_blank",
    };

    if (
      extension === "jpeg" ||
      extension === "png" ||
      extension === "jpg" ||
      extension === "svg" ||
      extension == "webp"
    ) {
      return (
        <a href={url} data-fancybox="gallery" {...commonProps}>
          <img
            crossOrigin="anonymous"
            src={url}
            alt={filename}
            className="w-full h-52 object-cover"
          />
          {downloadIcon}
        </a>
      );
    }

    if (extension === "pdf") {
      return (
        <a href={url} {...commonProps}>
          <object data={url} type="application/pdf" className="w-full">
            <i className="fa-solid fa-file-pdf text-9xl text-red-600"></i>
          </object>
          {downloadIcon}
        </a>
      );
    }

    if (extension === "doc" || extension === "docx") {
      return (
        <a href={url} {...commonProps}>
          <i className="fa-solid fa-file-word text-9xl text-blue-600"></i>
          {downloadIcon}
        </a>
      );
    }

    if (extension === "xls" || extension === "xlsx") {
      return (
        <a href={url} {...commonProps}>
          <i className="fa-solid fa-file-excel text-9xl text-green-600"></i>
          {downloadIcon}
        </a>
      );
    }

    return (
      <a href={url} {...commonProps}>
        <i className="fa-solid fa-file text-9xl text-gray-600"></i>
        {downloadIcon}
      </a>
    );
  };

  ///-----------------------------------------------------
  // const isEmptyOrWhitespace = (str) => {
  //   return !str || str.trim().length === 0;
  // };
  const handleSendMessage = () => {
    targetRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
    // if (!message) {
    //   toast.error("Please enter a message!");
    //   return;
    // }
    if (!message || message.trim().length === 0) {
      toast.error("Please enter a message!");
      setMessage("");
      return;
    }

    let data = {
      chatId: id,
      from: user?._id,
      to: consultantId,
      text: message,
      ...mediaObject,
    };
    setSubmitLoaded(false);
    sendMessage({ ...data }).then((res) => {
      if (res?.data?.status === 200) {
        setAttachments([]);
        const newMessage = res?.data?.data?.[0]; // Extract the new message object
        if (newMessage) {
          setAllList((prevList) => [...prevList, newMessage]); // Add the new message to the existing list
        }
        setMessage("");
      }
      setSubmitLoaded(true);
    });
  };

  useEffect(() => {
    if (targetRef.current && allList.length <= limit) {
      targetRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  }, [allList]); // Trigger scroll when 'list' updates

  useEffect(() => {
    setLoaded(false);
    fetchEmailDetailsList(id, {
      consultantId: consultantId,
      limit: limit,
      page: page,
    }).then((res) => {
      setLoaded(true);
      res?.data?.docs?.status === 200 &&
        setHasNextPage(res?.data?.docs?.metadata?.hasNextPage);
      setList(res?.data?.docs?.data);
      setAllList((prevList) =>
        page === 1
          ? res?.data?.docs?.data
          : [...res?.data?.docs?.data, ...prevList]
      );

      if (!consultant && res?.data?.docs?.status === 200) {
        res?.data?.docs?.data.map((elm) => {
          if (elm?.fromUser?._id == consultantId) {
            setConsultant(elm?.fromUser);
          } else if (elm?.toUser?._id == consultantId) {
            setConsultant(elm?.toUser);
          }
        });
      }
    });
  }, [id, consultantId, page]);

  const buttonRef = useRef(null);

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault(); // Prevent default behavior if needed
      buttonRef.current.click(); // Trigger the button click
    }
  };
  return (
    <>
      <div className="w-full h-[calc(100vh-8rem)] flex flex-col pr-12">
        <div className="flex items-center bg-prontopsy-blue p-3 flex-shrink-0 text-white shadow-lg">
          <div>
            <Image
              src={consultant && consultant?.image?.url}
              alt="Consultant Image"
              effect="blur"
              className="object-cover !w-14 !h-14 rounded-full"
            />
          </div>
          <div className="pl-4">
            <h2 className="text-lg">
              {consultant && `${consultant?.firstName} ${consultant?.lastName}`}
            </h2>
            {/* <h3 className="text-xs">online</h3> */}
          </div>
          <div className="flex ml-auto items-center">
            <div className="px-2">
              <Button
                buttonLabel={"Go Back"}
                buttonFunction={() => navigate("/email")}
              />
            </div>
          </div>
        </div>
        {!loaded && allList.length > 0 && (
          <div className="flex justify-center mt-4">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-prontopsy-blue"></div>
          </div>
        )}

        {allList.length === 0 && !loaded ? (
          <SpinLoader />
        ) : (
          <>
            <div
              className="py-6 px-12 space-y-8 flex-grow overflow-auto"
              style={{ maxHeight: "calc(90vh - 14rem)" }}
              ref={scrollableRef}
            >
              {allList.length > 0 &&
                allList.map((elm, index) => (
                  <div
                    className={`flex items-end ${
                      elm?.fromUser?._id === user?._id ? "flex-row-reverse" : ""
                    } gap-3`}
                    key={elm?._id}
                  >
                    <div className="w-10 aspect-square flex-shrink-0">
                      <Image
                        src={elm?.fromUser?.image?.url} // Replace 'User' with default image source
                        alt=""
                        className="object-cover rounded-full"
                      />
                    </div>
                    <div className="flex-shrink space-y-1 max-w-2xl text-sm bg-white">
                      <div className="relative">
                        <div
                          className={`relative ${
                            elm?.fromUser?._id === user?._id
                              ? "bg-slate-100 inline-block text-right"
                              : "bg-prontopsy-pink text-white text-left"
                          } py-4 px-4 rounded-md break-all`}
                        >
                          {/* <div className="flex gap-2 flex-wrap mb-2">
                            {elm?.attachment &&
                              elm?.attachment.length > 0 &&
                              elm?.attachment.map((media) => (
                                <div
                                  className="w-[150px] h-[150px]"
                                  key={media?._id}
                                >
                                  <a href={media?.url} data-fancybox="gallery">
                                    <Image
                                      src={media?.url}
                                      alt=""
                                      className="object-cover rounded-md"
                                    />
                                  </a>
                                </div>
                              ))}
                          </div> */}
                          {elm?.attachment &&
                            elm?.attachment?.map((ele, idx) => (
                              <div
                                key={idx}
                                className="mb-2 w-full  rounded-lg overflow-hidden border-b border-slate-900 py-1"
                              >
                                {renderAttachment(ele)}
                              </div>
                            ))}
                          {elm?.text}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              <div ref={targetRef}></div>
            </div>

            <div className="flex flex-wrap space-x-3 mb-3 gap-1">
              {attachments.length > 0 &&
                attachments.map((attachment, index) => (
                  <div
                    key={index}
                    className="relative bg-gray-100 border p-2 rounded w-32 h-40"
                  >
                    <button
                      className="absolute top-0 right-0 m-2 text-red-500"
                      onClick={() => handleRemoveAttachment(index)}
                    >
                      <i className="fa-solid fa-times"></i>
                    </button>
                    <div className="w-full h-full overflow-hidden flex items-center justify-center">
                      {renderPreview(attachment)}
                    </div>
                    <span className="text-gray-700 truncate mt-3 block text-center text-xs">
                      {attachment.name}
                    </span>
                  </div>
                ))}
            </div>

            <div
              className="bg-white shadow-md rounded-lg p-6 flex px-10 pt-10 space-x-3"
              onKeyDown={handleKeyDown}
            >
              <textarea
                className="w-full p-2 focus:outline-none text-l rounded-full flex !resize-none max-h-48 !overflow-hidden items-center h-12"
                placeholder="Type your message..."
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              ></textarea>
              <label className="flex items-center bg-gray-200 text-gray-700 p-3 px-4 rounded-full cursor-pointer">
                <i className="fa-solid fa-paperclip"></i>
                <input
                  type="file"
                  className="hidden"
                  multiple
                  onChange={(e) => handleFileUpload(e.target.files)}
                />
              </label>
              <button
                disabled={!submitLoaded}
                ref={buttonRef}
                onClick={handleSendMessage}
                className="flex items-center justify-center bg-prontopsy-pink text-white p-4 rounded-full text-xl w-12 h-12"
              >
                {submitLoaded ? (
                  <i className="fa-solid fa-paper-plane-top"></i>
                ) : (
                  <i className="fa fa-clock fa-lg"></i>
                )}
              </button>
            </div>
          </>
        )}

        {/* {loaded && !allList.length === 0 ? (
          <>
            <div
              className="py-6 px-12 space-y-8 flex-grow overflow-auto"
              style={{ maxHeight: "calc(90vh - 14rem)" }}
              ref={scrollableRef}
            >
              {allList.length > 0 &&
                allList.map((elm, index) => (
                  <div
                    className={`flex items-end ${
                      elm?.fromUser?._id === user?._id ? "flex-row-reverse" : ""
                    } gap-3`}
                    key={elm?._id}
                  >
                    <div className="w-10 aspect-square flex-shrink-0">
                      <Image
                        src={elm?.fromUser?.image?.url} // Replace 'User' with default image source
                        alt=""
                        className="object-cover rounded-full"
                      />
                    </div>
                    <div className="flex-shrink space-y-1 max-w-2xl text-sm bg-white">
                      <div className="relative">
                        <div
                          className={`relative ${
                            elm?.fromUser?._id === user?._id
                              ? "bg-slate-100 inline-block text-right"
                              : "bg-prontopsy-pink text-white text-left"
                          } py-4 px-4 rounded-md break-all`}
                        >
                          <div className="flex gap-2 flex-wrap mb-2">
                            {elm?.attachment &&
                              elm?.attachment.length > 0 &&
                              elm?.attachment.map((media) => (
                                <div
                                  className="w-[150px] h-[150px]"
                                  key={media?._id}
                                >
                                  <a href={media?.url} data-fancybox="gallery">
                                    <Image
                                      src={media?.url}
                                      alt=""
                                      className="object-cover rounded-md"
                                    />
                                  </a>
                                </div>
                              ))}
                          </div>
                          {elm?.text}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              <div ref={targetRef}></div>
            </div>

            <div className="bg-white shadow-md rounded-lg p-6 flex px-10 pt-10 space-x-3">
              <textarea
                className="w-full p-2 focus:outline-none text-l rounded-full flex items-center h-12"
                placeholder="Type your message..."
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              ></textarea>

              <button
                onClick={handleSendMessage}
                className="flex items-center justify-center bg-prontopsy-pink text-white p-4 rounded-full text-xl w-12 h-12"
              >
                <i className="fa-solid fa-paper-plane-top"></i>
              </button>
            </div>
          </>
        ) : (
          <SpinLoader />
        )} */}
      </div>
    </>
  );
};

export default UserEmailDetails;
