import axios from "axios";
import BASE_URL from "../config/host";

export const getTestimonialList = async (
  setList = () => {},
  setLoaded = () => {}
) => {
  setLoaded(false);
  try {
    let response = await axios.get(`${BASE_URL}/testimonial/list`);

    setLoaded(true);
    setList(response?.data?.docs?.data);

    return response;
  } catch (error) {
    setLoaded(true);
    return error.response;
  }
};
