import React, { useEffect, useRef, useState } from "react";
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import Image from "../../src/components/elements/Image";
import User from "../assets/images/user-01.webp";
import SpinLoader from "../components/elements/table/SpinLoader";
import Button from "../components/form/Button";
import Stopwatch from "../components/elements/StopWatch";
import LeaveChatModal from "../components/elements/LeavChatModal";
import { ReviewModal } from "../components/elements/ReviewModal";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSocket } from "../components/socket/SocketProvider";
import { fetchConsultantDetails } from "../service/consultantService";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import {
  appointmentClose,
  chatList,
  mediaChat,
  reviewAddFront,
} from "../service/videoCallService";
import { toast } from "react-toastify";
import { appointmentVerification } from "../service/appointmentService";
import { fetchTextMessageList } from "../service/textChatService";
import EmojiPicker from "emoji-picker-react";
import useClickOutside from "../components/elements/hooks/useClickOutside";
import usePageVisibility from "../util/PageVisibility";
import useBatteryStatus from "../components/elements/hooks/useBatteryStatus";
// import useBeforeUnload from "../components/elements/hooks/useBeforeUnload";
dayjs.extend(duration);

const TextChat = () => {
  useEffect(() => {
    Fancybox.bind("[data-fancybox]", {
      // Customize Fancybox options here
    });
  }, []);
  // Initialize Fancybox
  const [loaded, setLoaded] = useState(true);
  const user = JSON.parse(localStorage.getItem("userDetails"));
  const targetRef = useRef(null);
  const [message, setMessage] = useState("");
  const [isLeaveModalOpen, setIsLeaveModalOpen] = useState(false);
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const appointmentData = JSON.parse(localStorage.getItem("appointmentData"));
  const navigate = useNavigate();
  const socket = useSocket();
  const token = localStorage.getItem("auth_token");
  const [consultantDetails, setConsultantDetails] = useState(null);
  const [list, setList] = useState([]);
  // const callerData = useSelector((state) => state?.callingSlice?.data);
  const callerData = JSON.parse(
    localStorage.getItem("appointmentData")
  )?.callerData;
  const [stop, setStop] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [sending, setSending] = useState(false);
  const [elapsedTime, setElapsedTime] = useState("00:00:00");
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [isOneHourPassed, setIsOneHourPassed] = useState(false);

  // useBeforeUnload();

  useEffect(() => {
    setLoaded(false);
    fetchTextMessageList(id, {}).then((res) => {
      setList(res?.data?.docs?.data);
      setLoaded(true);
    });
  }, []);

  // useEffect(() => {
  //   !list && navigate("/");
  //   localStorage.removeItem("appointmentData");
  // }, [list]);

  useEffect(() => {
    if (id && appointmentData && appointmentData.appointmentId) {
      appointmentVerification({ appointmentId: id }).then((res) => {
        if (res?.data?.data?.status === false) {
          toast.error("Appointment not found!");
          navigate("/");
        }
      });
    } else {
      navigate("/");
    }
  }, [id]);

  const calculateElapsedTime = () => {
    const currentTime = dayjs();
    const time =
      appointmentData && appointmentData.appointmentId
        ? appointmentData?.appointmentStartTime
        : currentTime;
    const startTime = dayjs(
      // appointmentData?.appointmentStartTime || currentTime
      time
    );

    const duration = dayjs.duration(currentTime.diff(startTime));

    const hours = String(duration.hours()).padStart(2, "0");
    const minutes = String(duration.minutes()).padStart(2, "0");
    const seconds = String(duration.seconds()).padStart(2, "0");

    // Check if elapsed time is one hour or more
    if (duration.asHours() >= 1 && !isOneHourPassed) {
      setIsOneHourPassed(true);
    }

    return `${hours} : ${minutes} : ${seconds}`;
  };

  useEffect(() => {
    setElapsedTime(calculateElapsedTime());

    const interval = setInterval(() => {
      setElapsedTime(calculateElapsedTime());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const handleSendMessage = () => {
    if (
      // !message ||
      // message.trim().length === 0 ||
      (!message && attachments?.length == 0) ||
      (message && message.trim().length === 0)
    ) {
      setMessage("");
      return toast.error("Message cannot be empty!");
    }

    setSending(true);

    const mediaObject = {};
    attachments.forEach((attachment, index) => {
      mediaObject[`media[${index}]`] = attachment;
    });

    const data = {
      text: message,
      createdAt: new Date(),
      fromUser: {
        _id: userDetails?._id,
        firstName: userDetails?.firstName,
        lastName: userDetails?.lastName,
        image: userDetails?.image,
        email: userDetails?.email,
        roleCode: userDetails?.roleCode,
      },
      toUser: {
        _id: consultantDetails?._id,
        firstName: consultantDetails?.firstName,
        lastName: consultantDetails?.lastName,
        image: consultantDetails?.image,
        email: consultantDetails?.email,
        roleCode: consultantDetails?.roleCode,
      },
      appointmentId: appointmentData?.appointmentId,
      attachment: attachments.map((attachment) => ({
        url: null,
        loading: true,
        extension: attachment.extension,
      })),
      status: "sending",
    };

    if (attachments?.length > 0) {
      mediaChat({
        ...mediaObject,
        appointmentId: appointmentData?.appointmentId,
        from: userDetails?._id,
        to: consultantDetails?._id,
        text: message,
      }).then((res) => {
        if (res?.status === 200 || res?.status === 201) {
          setSending(false);
          const updatedData = {
            ...data,
            attachment: res?.data?.data[0]?.attachment.map((att) => ({
              ...att,
              loading: false,
            })),
            status: "sent",
          };
          // setList((pre) => [...pre, updatedData]);
          socket.emit("chatSessionMessage", updatedData);

          setMessage("");
          setAttachments([]);
        } else if (res?.status === 415) {
          toast.error("Unsupported file type!");

          setSending(false);
        } else {
          toast.error(res?.message);
          setSending(false);
        }
      });
    } else {
      data.status = "sent";
      socket.emit("chatSessionMessage", data);
      setMessage("");
      setSending(false);
    }

    setMessage("");
    setAttachments([]);
  };

  useSocket("chatSessionMessageResponse", async (data) => {
    appointmentData?.appointmentId === data?.appointmentId &&
      list &&
      Array.isArray(list) &&
      setList((pre) => [...pre, data]);
  });

  useEffect(() => {
    callerData &&
      Object.keys(callerData).length > 0 &&
      userDetails &&
      fetchConsultantDetails(
        token,
        {
          id:
            userDetails?.roleCode === "user"
              ? callerData?.consultant?.id
              : callerData?.user?.id,
        },
        setConsultantDetails
      );
  }, []);

  // Scroll to the bottom when 'list' changes
  useEffect(() => {
    if (targetRef.current) {
      targetRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  }, [list]);

  const onConfirm = () => {
    setLoading(true);
    appointmentData &&
      appointmentData?.appointmentId &&
      appointmentClose({ appointmentId: appointmentData?.appointmentId }).then(
        (res) => {
          if (res?.data?.status == 200 || res?.data?.status == 201) {
            setIsOneHourPassed(false);
            setLoading(false);
            setIsLeaveModalOpen(false);
            socket &&
              socket.emit("chatDisconnect", {
                disconnect: true,
                appointmentId: appointmentData?.appointmentId,
              });
            socket &&
              socket.emit("consultantBusy", {
                consultantId: callerData?.consultant?.id || "",
                isBusy: false,
              });

            if (userDetails?.roleCode == "user") {
              socket &&
                socket.emit("consultantBusy", {
                  consultantId: callerData?.consultant?.id || "",
                  isBusy: false,
                });
              navigate(`/review-appointment/${appointmentData?.appointmentId}`);
            } else {
              socket &&
                socket.emit("consultantBusy", {
                  consultantId: callerData?.consultant?.id || "",
                  isBusy: false,
                });

              navigate("/appointments");
              localStorage.removeItem("appointmentData");
            }
            // setTimeout(() => {
            //   localStorage.removeItem("appointmentData");
            // }, 1000);
          } else {
            setLoading(false);
            toast.error(res?.data?.message);
            localStorage.removeItem("appointmentData");
            navigate("/");
          }
        }
      );
  };

  //one houre --------------------

  // useEffect(() => {
  //   if (isOneHourPassed === true) {
  //     toast.info("You have reached your maximum calling duration!");
  //     onConfirm();
  //   }
  // }, [isOneHourPassed === true]);

  //------------Battery Percentage-----------------------

  const { level, charging } = useBatteryStatus();

  useEffect(() => {
    if (charging === false && Math.round(level * 100) === 20) {
      alert("Your battery is low!");
    } else if (charging === false && Math.round(level * 100) <= 5) {
      toast.warn("Due to low battery call is disconnected!");
      onConfirm();
    }
  }, [level, charging]);

  //-----------------------------------------------------

  // useSocket("userStatusResponse", (info) => {
  //   console.log(info, "online info from textChat");
  // });

  useSocket("chatDisconnectResponse", async (data) => {
    if (data?.disconnect === true) {
      if (userDetails?.roleCode == "user") {
        navigate(`/review-appointment/${appointmentData?.appointmentId}`);
      } else {
        navigate(`/appointments`);
        localStorage.removeItem("appointmentData");
      }
      setStop(true);
    }
  });

  const handleFileUpload = (files) => {
    setAttachments([...attachments, ...files]);
  };

  const handleRemoveAttachment = (index) => {
    const newAttachments = attachments.filter((_, i) => i !== index);
    setAttachments(newAttachments);
  };

  const renderPreview = (file) => {
    const fileType = file.type;
    if (fileType.startsWith("image/")) {
      return (
        <img
          src={URL.createObjectURL(file)}
          alt={file.name}
          className="w-full h-full object-cover"
        />
      );
    } else if (fileType === "application/pdf") {
      return (
        <object
          data={URL.createObjectURL(file)}
          type="application/pdf"
          className="w-full h-full"
        >
          <i className="fa-solid fa-file-pdf text-4xl text-red-600"></i>
        </object>
      );
    } else if (
      fileType === "application/msword" ||
      fileType ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) {
      return <i className="fa-solid fa-file-word text-4xl text-blue-600"></i>;
    } else if (
      fileType === "application/vnd.ms-excel" ||
      fileType ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      return <i className="fa-solid fa-file-excel text-4xl text-green-600"></i>;
    } else {
      return <i className="fa-solid fa-file text-4xl text-gray-600"></i>;
    }
  };

  const renderAttachment = (attachment) => {
    const { url, filename, extension, loading } = attachment;

    if (loading) {
      return <i className="fa fa-spinner fa-spin text-4xl text-gray-600"></i>;
    }

    const handleDownload = async (e) => {
      e.stopPropagation();
      e.preventDefault();
      try {
        const response = await fetch(url, { crossOrigin: "anonymous" });
        const blob = await response.blob();
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error("Download failed", error);
      }
    };

    const downloadIcon = (
      <button
        onClick={handleDownload}
        className="z-40 absolute top-1 right-1 bg-white py-1 px-2 rounded-full hover:bg-slate-200"
      >
        <i className="fa-solid fa-download text-xl text-gray-600"></i>
      </button>
    );

    const commonProps = {
      className: "relative hover:bg-slate-200",
      target: "_blank",
    };

    if (
      extension === "jpeg" ||
      extension === "png" ||
      extension === "jpg" ||
      extension === "svg" ||
      extension == "webp"
    ) {
      return (
        <a href={url} data-fancybox="gallery" {...commonProps}>
          <img
            crossOrigin="anonymous"
            src={url}
            alt={filename}
            className="w-full h-52 object-cover"
          />
          {downloadIcon}
        </a>
      );
    }

    if (extension === "pdf") {
      return (
        <a href={url} {...commonProps}>
          <object data={url} type="application/pdf" className="w-full">
            <i className="fa-solid fa-file-pdf text-9xl text-red-600"></i>
          </object>
          {downloadIcon}
        </a>
      );
    }

    if (extension === "doc" || extension === "docx") {
      return (
        <a href={url} {...commonProps}>
          <i className="fa-solid fa-file-word text-9xl text-blue-600"></i>
          {downloadIcon}
        </a>
      );
    }

    if (extension === "xls" || extension === "xlsx") {
      return (
        <a href={url} {...commonProps}>
          <i className="fa-solid fa-file-excel text-9xl text-green-600"></i>
          {downloadIcon}
        </a>
      );
    }

    return (
      <a href={url} {...commonProps}>
        <i className="fa-solid fa-file text-9xl text-gray-600"></i>
        {downloadIcon}
      </a>
    );
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey && socket) {
      handleSendMessage();
    }
  };
  usePageVisibility(token, socket, userDetails?._id);

  return (
    <>
      {loaded ? (
        <div className="w-full h-[calc(100vh-0rem)] flex flex-col ">
          <div className="flex items-center bg-prontopsy-blue p-3  text-white shadow-lg">
            <div>
              <Image
                src={
                  user &&
                  appointmentData?.callerData &&
                  user?.roleCode === "user"
                    ? appointmentData?.callerData?.consultant?.image
                    : appointmentData?.callerData?.user?.image
                }
                alt="Consultant Image"
                effect="blur"
                className="object-cover !w-14 !h-14 rounded-full"
              />
            </div>
            <div className="pl-4">
              <h2 className="text-lg">
                {user &&
                appointmentData?.callerData &&
                user?.roleCode === "user"
                  ? appointmentData?.callerData?.consultant?.name
                  : appointmentData?.callerData?.user?.name}
              </h2>
              {/* <h3 className="text-xs">online</h3> */}
            </div>
            <div className="flex ml-auto items-center">
              <div>
                <div className="text-xl border-r px-2">{elapsedTime}</div>
              </div>
              <div className="px-2">
                <Button
                  buttonLabel={"Exit"}
                  buttonFunction={() => {
                    setIsLeaveModalOpen(true);
                    setStop(true);
                    // socket &&
                    //   socket.emit("consultantBusy", {
                    //     consultantId: appointmentData?.consultantId || "",
                    //     isBusy: false,
                    //   });
                  }}
                />
              </div>
            </div>
          </div>

          {list && Array.isArray(list) && list.length > 0 ? (
            <div className="flex-1 overflow-auto p-5 bg-gray-100">
              {list.map((data, index) => (
                <div
                  key={index}
                  className={`flex items-start mb-4 ${
                    data?.fromUser?._id === user?._id
                      ? "justify-end"
                      : "justify-start"
                  }`}
                >
                  {data?.fromUser?._id !== user?._id && (
                    <img
                      crossOrigin="anonymous"
                      src={data?.fromUser?.image?.url}
                      className="w-10 h-10 rounded-full object-cover mr-3"
                      alt=""
                    />
                  )}
                  <div
                    className={`p-3 rounded-lg shadow-lg ${
                      data?.fromUser?._id === user?._id
                        ? "bg-blue-100 text-black"
                        : "bg-white text-gray-800"
                    }`}
                    style={{ maxWidth: "70%" }}
                  >
                    {data?.attachment &&
                      data?.attachment?.map((elm, idx) => (
                        <div
                          key={idx}
                          className="mb-2 w-full  rounded-lg overflow-hidden border-b border-slate-900 py-1"
                        >
                          {renderAttachment(elm)}
                        </div>
                      ))}
                    <div>{data?.text}</div>
                    <div className="text-xs mt-2 text-gray-500">
                      {dayjs(data?.createdAt).format("hh:mm A")}
                      {data?.status === "sending" && (
                        <i className="fa fa-clock ml-2"></i>
                      )}
                      {data?.status === "sent" && (
                        <i className="fa fa-check ml-2"></i>
                      )}
                    </div>
                  </div>
                  {data?.fromUser?._id === user?._id && (
                    <img
                      crossOrigin="anonymous"
                      src={data?.fromUser?.image?.url}
                      className="w-10 h-10 rounded-full object-cover ml-3"
                      alt=""
                    />
                  )}
                </div>
              ))}
              <div ref={targetRef}></div>
            </div>
          ) : (
            <>
              <div
                className="py-6 px-12 space-y-8 flex flex-col items-center justify-center flex-grow overflow-auto border"
                style={{ maxHeight: "90vh" }}
                // style={{ maxHeight: "calc(90vh - 14rem)" }}
              >
                <p className="text-center text-lg text-gray-500">No messages</p>
                <p className="text-center text-lg text-gray-500">
                  Please Start Your Conversations..
                </p>
              </div>
            </>
          )}

          <div className="bg-white shadow-md rounded-lg p-6 flex flex-col px-10 pt-10 space-y-3   ">
            <div className="flex flex-wrap space-x-3 mb-3 gap-1">
              {attachments.map((attachment, index) => (
                <div
                  key={index}
                  className="relative bg-gray-100 border p-2 rounded w-32 h-40"
                >
                  <button
                    className="absolute top-0 right-0 m-2 text-red-500"
                    onClick={() => handleRemoveAttachment(index)}
                  >
                    <i className="fa-solid fa-times"></i>
                  </button>
                  <div className="w-full h-full overflow-hidden flex items-center justify-center">
                    {renderPreview(attachment)}
                  </div>
                  <span className="text-gray-700 truncate mt-3 block text-center text-xs">
                    {attachment.name}
                  </span>
                </div>
              ))}
            </div>
            <div className="flex space-x-3" onKeyDown={handleKeyDown}>
              <textarea
                className="w-full p-2 focus:outline-none text-l rounded-full flex !resize-none max-h-48 !overflow-hidden items-center h-12"
                placeholder="Type your message..."
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              ></textarea>

              <label className="flex items-center bg-gray-200 text-gray-700 p-3 px-4 rounded-full cursor-pointer">
                <i className="fa-solid fa-paperclip"></i>
                <input
                  type="file"
                  className="hidden"
                  multiple
                  onChange={(e) => handleFileUpload(e.target.files)}
                />
              </label>
              <button
                onClick={handleSendMessage}
                disabled={
                  sending ||
                  (!message && attachments?.length === 0) ||
                  (message && message.trim().length === 0)
                }
                className="bg-blue-500 text-white rounded-full p-3"
              >
                {sending ? (
                  <i className="fa fa-clock fa-lg"></i>
                ) : (
                  <i className="fa fa-paper-plane-top fa-lg"></i>
                )}
              </button>
            </div>
          </div>
        </div>
      ) : (
        <SpinLoader />
      )}
      <LeaveChatModal
        isOpen={isLeaveModalOpen}
        setIsOpen={setIsLeaveModalOpen}
        onConfirm={onConfirm}
        loading={loading}
      />
      {/* <ReviewModal
        reviewModalOpen={reviewModalOpen}
        setReviewModalOpen={setReviewModalOpen}
        onReviewSubmit={onReviewSubmit}
        navigate={navigate}
      /> */}
    </>
  );
};

export default TextChat;
