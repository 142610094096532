import React, { useEffect, useState } from "react";
import User from "../../../../../assets/images/user-03.webp";
import Button from "../../../../form/Button";
import Textarea from "../../../../form/Textarea";
import { useDispatch, useSelector } from "react-redux";
import { commonFormLabels, profile } from "../../../language";
import { toast } from "react-toastify";
import { updateProfileService } from "../../../../../service/profileService";
import { setProfileData } from "../../../../../store/slice/profileSlice";
const Biography = ({ data, dataLoaded }) => {
  const language = useSelector((state) => state?.languageSlice?.data);
  const profileData = useSelector((state) => state?.profileSlice?.data);
  const role = JSON.parse(localStorage.getItem("userDetails"))?.roleCode;
  const id = JSON.parse(localStorage.getItem("userDetails"))?._id;
  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState(true);
  const [formData, setFormData] = useState({});

  const Biography = {
    title: profile?.biographyInformation?.[language],
    image: User,
  };

  useEffect(() => {
    setFormData((pre) => ({
      ...pre,
      "biography[shortBioDescription]":
        profileData?.biography?.shortBioDescription,
      "biography[longBioDescription]":
        profileData?.biography?.longBioDescription,
    }));
  }, [profileData]);

  const handleFormData = (e) => {
    setFormData((pre) => ({
      ...pre,
      [e.target.name]: e.target.value,
    }));
  };
  const handleSubmit = () => {
    const shortBio = formData?.["biography[shortBioDescription]"];
    const longBio = formData?.["biography[longBioDescription]"];
    if (!shortBio) {
      toast.info("Short biography is required!");
      return;
    }

    // if (shortBio.length > 200) {
    //   toast.info("Short biography should be maximum 200 characters!");
    //   return;
    // }
    if (!longBio) {
      toast.info("Long description is required!");
      return;
    }
    // if (longBio.length > 500) {
    //   toast.info("Long description should be maximum 500 characters!");
    //   return;
    // }

    setLoaded(false);
    updateProfileService({
      ...formData,
      roleCode: role,
      id: id,
    }).then((res) => {
      setLoaded(true);
      if (res?.data?.status === 200) {
        toast.success(res?.data?.message);
        dispatch(setProfileData(res.data.data));
      }
    });
  };

  return (
    <div className="relative">
      <div className="relative w-full border-t border-dashed border-neutral-300 py-4">
        {Biography?.title && (
          <h2
            className="text-base text-prontopsy-blue font-RobotoSlab font-bold"
            dangerouslySetInnerHTML={{ __html: Biography?.title }}
          ></h2>
        )}
      </div>
      <div className="flex  justify-between gap-5 pt-5 pb-5 border-t border-neutral-300">
        <div className="w-full">
          <div className="grid gap-3">
            <div className="relative">
              <Textarea
                label={`*${commonFormLabels?.shortDescription?.[language]} :`}
                inputName={"biography[shortBioDescription]"}
                inputValue={formData?.["biography[shortBioDescription]"]}
                onChange={handleFormData}
                inputPlaceholder={"Short Description"}
                labelClasses={
                  "!uppercase !text-prontopsy-blue !text-[10px] font-RobotoSlab !w-full"
                }
              />
            </div>
            <div className="relative">
              <Textarea
                label={`*${commonFormLabels?.longDescription?.[language]} :`}
                inputName={"biography[longBioDescription]"}
                inputValue={formData?.["biography[longBioDescription]"]}
                onChange={handleFormData}
                inputPlaceholder={"Long Description"}
                labelClasses={
                  "!uppercase !text-prontopsy-blue !text-[10px] font-RobotoSlab !w-full"
                }
              />
            </div>
          </div>
        </div>
      </div>
      <div className="relative flex items-center gap-2">
        <Button
          buttonLabel={"Save Changes"}
          buttonLabelClasses={
            "!uppercase !text-base !whitespace-nowrap !font-medium"
          }
          buttonClasses={
            "!rounded-xl !px-4 !h-12 !text-prontopsy-pink !text-white !bg-gradient-to-r !from-prontopsy-blue !to-prontopsy-pink !hover:bg-prontopsy-pink !duration-300"
          }
          buttonFunction={handleSubmit}
          isDisable={!loaded}
        />
      </div>
    </div>
  );
};

export default Biography;
