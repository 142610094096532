import React from "react";
import Button from "../../form/Button";

const Registeredpsychologist = () => {
  const registerData = {
    title:
      "ARE YOU A <br><span class='text-prontopsy-pink'>REGISTERED PSYCHOLOGIST?</span>",
    subtitle:
      "Start offering online counseling today! Wherever you are in the world, whenever you want, in any field of expertise in which you specialize! Choose how much time to dedicate to your daily consultations. Whether you decide to be online in real time or on booking based on your availability, the more availability you offer and the more users will be able to reach you! A new concept of online psychological counseling is born! Feel free, be Pronto!",
    action: {
      link: "/signup-consultant",
      label: "Signup Now",
    },
  };
  return (
    <>
      <section className="relative py-10 md:py-12 lg:py-16 xl:py-20 2xl:py-24 flex items-center">
        <div className="w-full px-4 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-8xl mx-auto">
          <div className="w-full mx-auto max-w-5xl text-center">
            {registerData?.title && (
              <h2
                className="text-2xl lg:text-3xl xl:text-4xl 2xl:text-[38px] text-prontopsy-blue font-UnisonPro italic font-bold !leading-[1.1]"
                dangerouslySetInnerHTML={{ __html: registerData?.title }}
              ></h2>
            )}
            {registerData?.subtitle && (
              <div
                className="text-sm sm:text-base xl:text-lg text-neutral-700 space-y-4 mt-6 max-w-6xl mx-auto"
                dangerouslySetInnerHTML={{ __html: registerData?.subtitle }}
              ></div>
            )}
          </div>
          <div className="flex items-center justify-center mt-8 md:mt-12 lg:mt-16 xl:mt-10">
            <Button
              buttonHasLink={true}
              buttonLink={registerData?.action?.link}
              buttonLabel={registerData?.action?.label}
              buttonClasses={"!h-10 lg:!h-12 hover:!bg-prontopsy-blue !px-12"}
              buttonLabelClasses={"!text-base"}
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default Registeredpsychologist;
