import React, { useEffect, useRef, useState } from "react";
import {
  fetchConsultantListPrivately,
  fetchWishlistedData,
  mankeFavoriteService,
} from "../../service/consultantService";
import { useSelector } from "react-redux";
import Modal from "../../components/elements/Modal";
import Button from "../../components/form/Button";
import CalendarMonth from "../../components/elements/CalendarMonth";
import Radio from "../../components/form/Radio";
import Image from "../../components/elements/Image";
import Checkbox from "../../components/form/Checkbox";
// import Ac from "../../assets/images/paymentsicon/image19.png";
import Ac from "../../assets/images/paymentsicon/image19.png";
import Ac1 from "../../assets/images/paymentsicon/image20.png";
import Ac2 from "../../assets/images/paymentsicon/image21.png";
import Ac4 from "../../assets/images/paymentsicon/image23.png";
import Ac5 from "../../assets/images/paymentsicon/image24.png";
import Ac6 from "../../assets/images/paymentsicon/image25.png";
import Ac7 from "../../assets/images/paymentsicon/image26.png";
import Ac8 from "../../assets/images/paymentsicon/image27.png";
import Ac9 from "../../assets/images/paymentsicon/image28.png";
import Visa from "../../assets/images/visa.svg";
import Discover from "../../assets/images/discover.svg";
import Stripe from "../../assets/images/stripe.webp";
import ConsultantItem from "../../components/single/ConsultantItem";
import SpinLoader from "../../components/elements/table/SpinLoader";
import { deleteBtn, removeBtn } from "../../components/elements/language";
import { toast } from "react-toastify";
import NoDataFound from "../../components/elements/NoDataFound";
import { useNavigate } from "react-router-dom";

const WishList = () => {
  const [list, setList] = useState([]);
  const [loaded, setLoaded] = useState(true);
  const [removeLoaded, setRemoveLoaded] = useState(true);
  const token = localStorage.getItem("auth_token");
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const language = useSelector((state) => state?.languageSlice?.data);
  //Pagination
  const [limit, setLimit] = useState(15);
  const [totalDocs, setTotalDocs] = useState(null);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [page, setPage] = useState(1);
  const [allList, setAllList] = useState([]);

  const [scrolledToBottom, setScrolledToBottom] = useState(false);
  const scrollableRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      if (scrollableRef.current) {
        const { scrollHeight, scrollTop, clientHeight } = scrollableRef.current;
        const scrolledToBottom = scrollTop + clientHeight >= scrollHeight;

        setScrolledToBottom(scrolledToBottom);
      }
    };

    const scrollableElement = scrollableRef.current;
    if (scrollableElement) {
      scrollableElement.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (scrollableElement) {
        scrollableElement.removeEventListener("scroll", handleScroll);
      }
    };
  }, [allList]);

  useEffect(() => {
    fetchWishlistedData(
      token,
      { userId: userDetails?._id, limit: limit, page: page },
      setList,
      setLoaded,
      setHasNextPage
    ).then((res) => {
      if (res?.data?.docs?.status === 200) {
        let metaData = res?.data?.docs?.metadata;
        setTotalDocs(metaData?.totalDocs);
        page !== 1
          ? setAllList((prevList) => [...prevList, res.data.docs.data])
          : setAllList(res.data.docs.data);
      }
    });
  }, [page]);
  useEffect(() => {
    if (scrolledToBottom && hasNextPage && loaded) {
      setPage((pre) => pre + 1);
    }
  }, [scrolledToBottom, hasNextPage, loaded]);

  const handleRemove = (id) => {
    mankeFavoriteService({ consultantId: id }, token, setRemoveLoaded).then(
      (res) => {
        if (res?.data?.status == 200) {
          toast.success("Item removed successfully!");
          let data = allList.filter((elm) => elm?._id !== id);
          setAllList(data);
        }
      }
    );
  };

  return (
    <div>
      {allList.length === 0 && !loaded ? (
        <SpinLoader />
      ) : (
        <div
          ref={scrollableRef}
          className="h-[80vh] p-10 shadow-md overflow-auto"
        >
          {allList &&
            Array.isArray(allList) &&
            allList?.length > 0 &&
            allList?.map((elm, index) => (
              <div className="flex shadow-md px-5 py-2 mt-3" key={elm?._id}>
                <div className="flex items-center">
                  <div
                    onClick={() =>
                      navigate(`/public-front-consultant-profile/${elm?._id}`)
                    }
                  >
                    <Image
                      src={elm?.consultantData?.image?.url}
                      alt=""
                      effect="blur"
                      className="object-cover !w-14 !h-14 rounded-full cursor-pointer"
                    />
                  </div>
                  <div
                    className="pl-4 cursor-pointer"
                    onClick={() =>
                      navigate(`/public-front-consultant-profile/${elm?._id}`)
                    }
                  >
                    <span className="text-lg whitespace-nowrap">
                      {elm?.consultantData?.firstName +
                        " " +
                        elm?.consultantData?.lastName}
                    </span>

                    <h3 className="text-xs">{elm?.consultantData?.email}</h3>
                  </div>
                </div>

                <div className="ml-auto text-sm flex justify-center items-center">
                  <Button
                    // buttonLabel={removeBtn?.[language]}
                    buttonIcon={"fa-solid fa-heart"}
                    buttonClasses={"!text-red-600 !bg-slate-300"}
                    buttonIconPosition={"left"}
                    buttonFunction={() => handleRemove(elm?._id)}
                    isDisable={!removeLoaded}
                  />
                </div>
              </div>
            ))}
          {allList.length == 0 && <NoDataFound />}
          {!loaded && (
            <div className="flex justify-center mt-4">
              <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-prontopsy-blue"></div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default WishList;
