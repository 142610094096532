import React, { useEffect, useState } from "react";
import Image from "../../elements/Image";
import User from "../../../assets/images/user-01.webp";
import { useNavigate } from "react-router-dom";
import { fetchEmailList } from "../../../service/emailService";
import dayjs from "dayjs";
import SpinLoader from "../../elements/table/SpinLoader";
const UserEmailList = () => {
  const navigate = useNavigate();
  const [list, setList] = useState([]);
  const [loaded, setLoaded] = useState(true);

  useEffect(() => {
    setLoaded(false);
    fetchEmailList().then((res) => {
      setLoaded(true);
      if (res?.data?.docs?.status === 200) {
        setList(res?.data?.docs?.data);
      }
    });
  }, []);

  return (
    <>
      {loaded ? (
        <div className="w-full bg-white pr-12 shadow-xl h-[calc(100vh-8rem)] overflow-auto pl-12">
          {/* <div className="flex justify-center items-center sticky top-0 z-50 bg-white">
        <div className="flex overflow-hidden mb-8 space-x-2">
          <input
            type="text"
            placeholder="Search..."
            className="px-4 py-2 w-80 text-gray-700 focus:outline-none rounded-full"
          />
          <button className="flex items-center justify-center bg-prontopsy-pink text-white p-4 rounded-full text-l w-10 h-10">
            <i class="fa-solid fa-magnifying-glass"></i>
          </button>
        </div>
      </div> */}
          {list.length > 0 &&
            list.map((elm, index) => (
              <div
                key={elm?._id}
                className="flex shadow-md px-5 py-2 cursor-pointer mt-3"
                onClick={() =>
                  navigate(
                    `/email-details/${elm?._id}?consultantId=${elm?.otherUserData?._id}`
                  )
                }
              >
                <div className="flex items-center">
                  <Image
                    src={elm?.otherUserData?.image?.url}
                    alt=""
                    effect="blur"
                    className="object-cover !w-14 !min-h-14 rounded-md"
                  />
                  <div className="pl-4">
                    <h2 className="text-lg whitespace-nowrap">{`${elm?.otherUserData?.firstName} ${elm?.otherUserData?.lastName}`}</h2>
                    {/* <h3 className="text-xs">Messages..</h3> */}
                    <h3 className="text-xs">{elm?.name}</h3>
                  </div>
                </div>

                <div className="ml-auto text-sm text-right flex flex-col items-end">
                  <h3>{dayjs(elm?.createdAt).format("MMM D, YYYY h:mm A")}</h3>
                  {/* <h3 className="text-sm bg-prontopsy-blue rounded-full text-white w-6 min-h-6 flex justify-center items-center">
                2
              </h3> */}
                </div>
              </div>
            ))}
        </div>
      ) : (
        <SpinLoader />
      )}
    </>
  );
};

export default UserEmailList;
