import React from "react";
import { setTitle } from "../helpers/MetaTag";
import MainBanner from "../components/sections/home/MainBanner";
import FeatureSlider from "../components/sections/home/FeatureSlider";
import About from "../components/sections/home/About";
import Statistics from "../components/sections/home/Statistics";
import Consultation from "../components/sections/home/Consultation";
import AreaOfIntervention from "../components/sections/AreaOfIntervention";
import GetStarted from "../components/sections/home/GetStarted";
import OnlineFeatures from "../components/sections/home/OnlineFeatures";
import Register from "../components/sections/Register";
import SmartPortal from "../components/sections/SmartPortal";
import Testimonial from "../components/sections/home/Testimonial";
import Consultants from "../components/sections/Consultants";

const Home = () => {
  setTitle("Prontopsy | Home");
  const interventionData = {
    title: "Area of<br/><span class='text-prontopsy-pink'>Intervention</span>",
    subtitle: "<p>What kind of Psychologist are you looking for?</p>",
    intervention: [
      {
        _id: 1,
        styleType: 1,
        value: 6,
        name: "Community Psychology",
        description: "<p>Consultants are responding to users</p>",
        action: {
          link: "/intervention-details",
          label: "Details",
        },
      },
      {
        _id: 2,
        styleType: 2,
        value: 6,
        name: "Community Psychology",
        description: "<p>Consultants are responding to users</p>",
        action: {
          link: "/intervention-details",
          label: "Details",
        },
      },
      {
        _id: 2,
        styleType: 3,
        value: 6,
        name: "Community Psychology",
        description: "<p>Consultants are responding to users</p>",
        action: {
          link: "/intervention-details",
          label: "Details",
        },
      },
    ],
    action: {
      link: "/intervention-details",
      label: "View All",
    },
  };
  return (
    <>
      <MainBanner />
      <FeatureSlider />
      <About />
      <Statistics />
      <Consultation />
      <AreaOfIntervention interventionData={interventionData} length={3} />
      <Consultants />
      <GetStarted />
      <OnlineFeatures />
      <Testimonial />
      <SmartPortal />
      <Register />
    </>
  );
};

export default Home;
