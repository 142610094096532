import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { appointmentDetailsLang } from "../../../language";
import SpinLoader from "../../../table/SpinLoader";
import DownloadButton from "./invoice";
import duration from "dayjs/plugin/duration";
import americanExpress from "../../../../../assets/images/cardicon/American Express.png";
import dinersClub from "../../../../../assets/images/cardicon/Diners Club.png";
import discover from "../../../../../assets/images/cardicon/Discover.png";
import elo from "../../../../../assets/images/cardicon/elo.png";
import hipercard from "../../../../../assets/images/cardicon/hipercard-29.png";
import jcb from "../../../../../assets/images/cardicon/JCB.png";
import masterCard from "../../../../../assets/images/cardicon/MasterCard.png";
import mnp from "../../../../../assets/images/cardicon/mnp.png";
import troy from "../../../../../assets/images/cardicon/troy.png";
import unionPay from "../../../../../assets/images/cardicon/UnionPay.png";
import visa from "../../../../../assets/images/cardicon/Visa.png";
import Image from "../../../Image";

dayjs.extend(duration);

const PaymentDetails = ({ data, appointmentDetailsData }) => {
  const language = useSelector((state) => state.languageSlice.data);
  const [customerData, setCustomerData] = useState([]);
  const [serviceData, setServiceData] = useState([]);
  const role = data && data?.roleCode;

  const formatDuration = (milliseconds, round = false) => {
    const dur = dayjs.duration(milliseconds);
    const totalMinutes = Math.floor(dur.asMinutes());
    const totalSeconds = dur.asSeconds() % 60;

    if (round) {
      const minutes = totalSeconds >= 1 ? totalMinutes + 1 : totalMinutes;
      const seconds = totalSeconds >= 1 ? 0 : Math.round(totalSeconds);
      return `${minutes}m ${seconds}s`;
    } else {
      let totalSecondsInt = Math.floor(milliseconds / 1000);
      let minutes = Math.floor(totalSecondsInt / 60);
      let seconds = totalSecondsInt % 60;

      seconds = seconds < 10 ? "0" + seconds : seconds;

      return `${minutes}m ${seconds}s`;
    }
  };

  const getCardImage = (brand) => {
    switch (brand) {
      case "amex":
      case "American Express":
        return americanExpress;
      case "diners_club":
      case "Diners Club":
        return dinersClub;
      case "discover":
      case "Discover":
        return discover;
      case "visa":
      case "Visa":
        return visa;
      case "mastercard":
      case "MasterCard":
        return masterCard;
      case "jcb":
      case "JCB":
        return jcb;
      case "unionpay":
      case "UnionPay":
        return unionPay;
      case "hipercard":
      case "Hipercard":
        return hipercard;
      case "mnp":
      case "MNP":
        return mnp;
      case "elo":
      case "ELO":
        return elo;
      default:
        return troy;
    }
  };

  useEffect(() => {
    const customerData = [
      {
        title: `${appointmentDetailsLang.paymentInformation[language]}`,
        fields: [
          {
            label: `${appointmentDetailsLang.paymentStatus[language]}`,
            value: `${appointmentDetailsData?.details?.paymentStatus}`,
          },
          {
            label: `${appointmentDetailsLang.currency[language]}`,
            value: `${appointmentDetailsData?.consultantData?.currencyData?.currency_name}`,
          },
          {
            label: `${appointmentDetailsLang.pricePerMinute[language]}`,
            value: `${appointmentDetailsData?.consultantData?.currencyData?.currency_symbol}  ${appointmentDetailsData?.service?.price}`,
          },
          {
            label: `${appointmentDetailsLang.totalDurationTime[language]}`,
            value: formatDuration(
              appointmentDetailsData?.details?.duration,
              true
            ),
          },
          {
            label: `${appointmentDetailsLang.authorizeAmount[language]}`,
            value: `${appointmentDetailsData?.consultantData?.currencyData?.currency_symbol}  ${appointmentDetailsData?.details?.authorizationAmount}`,
          },
          {
            label: `${appointmentDetailsLang.payableAmount[language]}`,
            value: `${appointmentDetailsData?.consultantData?.currencyData?.currency_symbol}  ${appointmentDetailsData?.details?.capturedAmount}`,
          },
          {
            label: `${appointmentDetailsLang.capturedAmount[language]}`,
            value: `${appointmentDetailsData?.consultantData?.currencyData?.currency_symbol}  ${appointmentDetailsData?.details?.capturedAmount}`,
          },
          {
            label: `${appointmentDetailsLang.paymentMethods[language]}`,
            value: data?.billing?.taxIdentificationCode,
            brand: appointmentDetailsData?.paymentSourceInfo?.brand,
            last4: appointmentDetailsData?.paymentSourceInfo?.last4,
            isCard: true,
          },
        ].filter(Boolean),
      },
    ].filter(Boolean);

    const serviceData = [
      {
        title: `${appointmentDetailsLang?.serviceInformation?.[language]}`,
        fields: [
          {
            label: `${appointmentDetailsLang?.serviceOffered?.[language]}`,
            value: appointmentDetailsData?.service?.service?.title?.en,
          },
          {
            label: `${appointmentDetailsLang?.mode?.[language]}`,
            value: appointmentDetailsData?.service?.mode?.title?.en,
          },
          {
            label: `${appointmentDetailsLang?.dateOfAppointMent?.[language]}`,
            value: dayjs(
              appointmentDetailsData?.details?.appointmentDate
            ).format("YYYY-MM-DD"),
          },
          {
            label: `${appointmentDetailsLang.totalDurationTime[language]}`,
            value: formatDuration(appointmentDetailsData?.details?.duration),
          },
          {
            label: `${appointmentDetailsLang?.startTime?.[language]}`,
            value: dayjs(
              appointmentDetailsData?.details?.appointmentStartTime
            ).format("hh:mm:ss A"),
          },
          {
            label: `${appointmentDetailsLang?.endTime?.[language]}`,
            value: dayjs(
              appointmentDetailsData?.details?.appointmentEndTime
            ).format("hh:mm:ss A"),
          },
        ].filter(Boolean),
      },
    ].filter(Boolean);

    setCustomerData(customerData);
    setServiceData(serviceData);
  }, [data, language, role]);

  const invoiceDetails = {
    appointmentId: appointmentDetailsData?.details?.appointmentId,
    appointmentDate: dayjs(
      appointmentDetailsData?.details?.appointmentDate
    ).format("DD/MM/YYYY"),
    capturedAmount: appointmentDetailsData?.details?.capturedAmount,
    currencySymbol:
      appointmentDetailsData?.consultantData?.currencyData?.currency_symbol,
    clientData: {
      name: `${appointmentDetailsData?.clientDetails?.firstName} ${appointmentDetailsData?.clientDetails?.lastName}`,
      address: `${appointmentDetailsData?.clientData?.billing?.address?.address1}, ${appointmentDetailsData?.clientData?.billing?.address?.city},, ${appointmentDetailsData?.clientData?.billing?.address?.state}, ${appointmentDetailsData?.clientData?.billing?.address?.country}`,
      city: appointmentDetailsData?.clientData?.billing?.address?.city,
      zipcode: appointmentDetailsData?.clientData?.billing?.address?.zipcode,
      country: appointmentDetailsData?.clientData?.billing?.address?.country,
      clientCode: "N/A",
      email: appointmentDetailsData?.clientDetails?.email,
    },
    consultantData: {
      name: `${appointmentDetailsData?.consultantDetails?.firstName} ${appointmentDetailsData?.consultantDetails?.lastName}`,
      address: `${appointmentDetailsData?.consultantData?.billing?.address?.address1}, ${appointmentDetailsData?.consultantData?.billing?.address?.city},, ${appointmentDetailsData?.consultantData?.billing?.address?.state}, ${appointmentDetailsData?.consultantData?.billing?.address?.country}`,
      city: appointmentDetailsData?.consultantData?.billing?.address?.city,
      zipcode:
        appointmentDetailsData?.consultantData?.billing?.address?.zipcode,
      country:
        appointmentDetailsData?.consultantData?.billing?.address?.country,
      taxCode:
        appointmentDetailsData?.consultantData?.billing?.taxIdentificationCode,
      email: appointmentDetailsData?.consultantDetails?.email,
    },
    paymentInfo: {
      service: appointmentDetailsData?.service?.service?.title?.en,
      mode: appointmentDetailsData?.service?.mode?.title?.en,
      date_time: `${dayjs(
        appointmentDetailsData?.details?.appointmentDate
      ).format("YYYY-MM-DD")} ${dayjs(
        appointmentDetailsData?.details?.appointmentStartTime
      ).format("hh:mm:ss A")}`,
      duration: formatDuration(appointmentDetailsData?.details?.duration, true),
      pricePerMin: `${appointmentDetailsData?.service?.price}/min`,
      authorizeAmount: `${appointmentDetailsData?.consultantData?.currencyData?.currency_symbol}  ${appointmentDetailsData?.details?.authorizationAmount}`,
      capturedAmount: `${appointmentDetailsData?.consultantData?.currencyData?.currency_symbol}  ${appointmentDetailsData?.details?.capturedAmount}`,
      paymentGateway: appointmentDetailsData?.paymentMode,
    },
  };

  return (
    <>
      {data ? (
        <div className="relative ">
          {appointmentDetailsData?.status == "close" && (
            <div className="absolute -top-5 right-0">
              <DownloadButton details={invoiceDetails} language={language} />
            </div>
          )}
          <div className="block lg:flex justify-between gap-10">
            <div className="w-full lg:w-1/2">
              <div className="space-y-10 py-2">
                {data !== undefined &&
                  customerData?.map((section, index) => (
                    <div key={index} className="relative space-y-0.5">
                      <div className="text-bas font-medium font-RobotoSlab pb-2 border-b border-dashed border-neutral-400 text-prontopsy-blue">
                        {section.title}
                      </div>
                      <div className="grid grid-cols-2 gap-2 my-2">
                        {section.fields.map((field, idx) => (
                          <div key={idx} className="flex">
                            <div className="">
                              <div className="uppercase text-prontopsy-blue font-medium text-sm font-RobotoSlab">
                                {field.label}
                              </div>
                              <div className="capitalize text-neutral-400 font-medium text-sm font-RobotoSlab">
                                {field?.isCard != true && field.value}
                              </div>
                              {field?.isCard == true && (
                                <div className="flex  items-center justify-center">
                                  <div className="w-10 overflow-hidden mx-2">
                                    <Image
                                      src={getCardImage(field?.brand)}
                                      alt={field?.brand}
                                      className="w-full h-full object-contain"
                                    />
                                  </div>
                                  <span className="mx-4">{`xxx-xxx-xxxx-${field?.last4}`}</span>
                                </div>
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
              </div>
              <div className="relative space-y-0.5 py-3">
                <div className="flex flex-col w-full">
                  <div className="uppercase text-prontopsy-blue font-medium text-sm font-RobotoSlab">
                    {appointmentDetailsLang.disclaimer[language]}
                  </div>
                  <div className="capitalize text-neutral-400 font-medium text-sm font-RobotoSlab">
                    {appointmentDetailsLang.disclaimerText[language]}
                  </div>
                </div>
              </div>
            </div>

            <div className="w-full lg:w-1/2">
              <div className="space-y-10 py-2">
                {data !== undefined &&
                  serviceData?.map((section, index) => (
                    <div key={index} className="relative space-y-0.5">
                      <div className="text-bas font-medium font-RobotoSlab pb-2 border-b border-dashed border-neutral-400 text-prontopsy-blue">
                        {section.title}
                      </div>
                      <div className="grid grid-cols-2 gap-2 my-2">
                        {section.fields.map((field, idx) => (
                          <div key={idx} className="flex">
                            <div className="">
                              <div className="uppercase text-prontopsy-blue font-medium text-sm font-RobotoSlab">
                                {field.label}
                              </div>
                              <div className="capitalize text-neutral-400 font-medium text-sm font-RobotoSlab">
                                {field.value}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <SpinLoader />
      )}
    </>
  );
};

export default PaymentDetails;
