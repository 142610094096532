import React from "react";
import Image from "../elements/Image";
const PageBanner = ({ bannerData }) => {
  return (
    <>
      <section className="relative pt-0 lg:pt-44 pb-0 lg:pb-40 h-80 md:h-96 xl:h-[480px] 2xl:h-[560px] overflow-hidden z-0 flex items-center">
        <div className="absolute top-0 left-0 w-full h-full z-[-1] flex">
          <Image
            src={bannerData?.image}
            alt={"Banner Photo"}
            effect={"blur"}
            className={"object-cover w-full h-full"}
          />
        </div>
        <div className="w-full px-4 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-8xl mx-auto flex items-center">
          <div className="relative lg:w-full w-72 max-w-xl space-y-5">
            {bannerData?.title && (
              <h1
                className="text-2xl lg:text-3xl xl:text-4xl 2xl:text-[38px] text-prontopsy-blue prontopsy-span-color font-UnisonPro italic font-bold !leading-[1.1]"
                dangerouslySetInnerHTML={{ __html: bannerData?.title }}
              ></h1>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default PageBanner;
