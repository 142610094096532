import React from "react";
import { useSelector } from "react-redux";

const DeleteModal = ({ isOpen, onClose, onDelete, loaded = true }) => {
  const language = useSelector((state) => state?.languageSlice?.data);
  if (!isOpen) return null;

  const headingLang = {
    en: "Are you sure you want to delete this card permanently?",
    es: "¿Estás seguro de que deseas eliminar esta tarjeta de forma permanente?",
    it: "Sei sicuro di voler eliminare questa carta in modo permanente?",
  };
  const deleteLang = {
    en: "Delete",
    es: "Borrar",
    it: "Eliminare",
  };
  const cancelLang = {
    en: "Cancel",
    es: "Cancelar",
    it: "Cancellare",
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-75">
      <div className="bg-white rounded-lg shadow-lg p-6 max-w-sm w-full flex flex-col items-center">
        <span className="text-red-600 text-6xl mb-4">
          <i className="fa-solid fa-circle-exclamation"></i>
        </span>
        <h3 className="text-lg font-semibold text-gray-900 text-center mb-6">
          {headingLang?.[language]}
        </h3>
        <div className="flex justify-end space-x-4">
          <button
            className="px-4 py-2 text-center flex justify-center items-center bg-red-600 min-w-[100px] text-white rounded hover:bg-red-700"
            onClick={onDelete}
          >
            {!loaded ? (
              <div className="animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 border-white mr-2"></div>
            ) : (
              `${deleteLang?.[language]}`
            )}
          </button>
          <button
            className="px-4 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400"
            onClick={onClose}
          >
            {cancelLang?.[language]}
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteModal;
