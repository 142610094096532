import React, { useEffect, useRef, useState } from "react";
import Button from "../../components/form/Button";
import Image from "../../components/elements/Image";
import User1 from "../../assets/images/user-04.webp";
import User2 from "../../assets/images/user-05.webp";
import { useDispatch, useSelector } from "react-redux";
import { setTabSelection } from "../../store/slice/selectedTabSlice";
import { useNavigate } from "react-router-dom";
import {
  blockClientService,
  fetchClientList,
} from "../../service/clientService";
import { commonFormLabels } from "../../components/elements/language";
import SpinLoader from "../../components/elements/table/SpinLoader";
import { toast } from "react-toastify";
const MyClients = () => {
  const token = localStorage.getItem("auth_token");
  const [loaded, setLoaded] = useState(true);
  const [blockLoaded, setBlockLoaded] = useState(true);
  const [list, setList] = useState([]);
  const [allList, setAllList] = useState([]);
  const language = useSelector((state) => state.languageSlice.data);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  //For pagination
  const [limit, setLimit] = useState(4);
  const [totalDocs, setTotalDocs] = useState(null);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [page, setPage] = useState(1);

  const [scrolledToBottom, setScrolledToBottom] = useState(false);
  const scrollableRef = useRef(null);

  useEffect(() => {
    dispatch(setTabSelection({}));
  }, []);

  const myClients = {
    en: "My clients",
    es: "Mis clientes",
    it: "I miei clienti",
  };

  useEffect(() => {
    const handleScroll = () => {
      if (scrollableRef.current) {
        const { scrollHeight, scrollTop, clientHeight } = scrollableRef.current;
        const scrolledToBottom = scrollTop + clientHeight >= scrollHeight;

        setScrolledToBottom(scrolledToBottom);
      }
    };

    const scrollableElement = scrollableRef.current;
    if (scrollableElement) {
      scrollableElement.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (scrollableElement) {
        scrollableElement.removeEventListener("scroll", handleScroll);
      }
    };
  }, [allList]);
  useEffect(() => {
    if (scrolledToBottom && hasNextPage && loaded) {
      setPage((pre) => pre + 1);
    }
  }, [scrolledToBottom, hasNextPage, loaded]);

  useEffect(() => {
    fetchClientList(
      { limit: limit, page: page },
      token,
      setList,
      setLoaded,
      setHasNextPage
    ).then((res) => {
      if (res?.data?.docs?.status === 200) {
        let metaData = res?.data?.docs?.metadata;
        setTotalDocs(metaData?.totalDocs);
        page !== 1
          ? setAllList((prevList) => [...prevList, ...res?.data?.docs?.data])
          : setAllList(res?.data?.docs?.data);
      }
    });
  }, [token, page]);

  const handleUnblock = (id) => {
    blockClientService({ clientId: id }, token, setBlockLoaded).then((res) => {
      if (res?.data?.status === 200) {
        toast.success(res?.data?.message);
        setAllList((prevList) =>
          prevList.map((elm) => {
            if (elm?._id?.clientId === id) {
              return { ...elm, isBlocked: !elm?.isBlocked };
            }
            return elm;
          })
        );
      }
    });
  };

  const appointments =
    list.length > 0 &&
    allList.map((elm) => ({
      id: elm?.clientData?._id,
      image: elm?.clientData?.image,
      name: elm?.clientData?.firstName + " " + elm?.clientData?.lastName,
      email: elm?.clientData?.email,
      phone: elm?.clientData?.address?.phone,
      zip: elm?.clientData?.address?.zipcode,
      address1: elm?.clientData?.address?.address1,
      address2: elm?.clientData?.address?.address2,
      city: elm?.clientData?.address?.city,
      state: elm?.clientData?.address?.state,
      country: elm?.clientData?.address?.country,
      isBlocked: elm?.isBlocked || false,
      buttonTextBlock: {
        en: "Block",
        es: "Bloquear",
        it: "Bloccare",
      },
      buttonTextUnblock: {
        en: "Unblock",
        es: "Desbloquear",
        it: "Sbloccare",
      },
      buttonTextNote: {
        en: "Note",
        es: "Nota",
        it: "Nota",
      },
      buttonIconUnblock: "fa-regular fa-unlock-keyhole",
      buttonIconPink: "fa-regular fa-ban",
      buttonIconBlue: "fa-regular fa-file",
    }));

  return (
    <>
      {allList.length === 0 && !loaded ? (
        <SpinLoader />
      ) : (
        <div className="relative divide-y divide-neutral-300">
          <h5 className="text-lg text-prontopsy-blue font-RobotoSlab font-bold py-2">
            {myClients?.[language]}
          </h5>
          <div
            ref={scrollableRef}
            className="h-[80vh] overflow-auto shadow-md p-2"
          >
            {list.length > 0 &&
              appointments.map((appointment) => (
                <div key={appointment.id}>
                  <div className="block lg:flex justify-between gap-5 py-5 space-y-5 lg:space-y-0">
                    <div className="flex items-center gap-5 flex-shrink">
                      <div className="w-16 h-16 rounded-full overflow-hidden flex-shrink-0">
                        <Image
                          src={appointment?.image}
                          alt={""}
                          effect={"blur"}
                          className={"object-cover w-full h-full"}
                        />
                      </div>
                      <div className="flex-shrink space-y-2">
                        <div className="text-xs text-prontopsy-blue font-bold italic font-UnisonPro uppercase">
                          {appointment.name}
                        </div>
                        <div className="text-neutral-400 font-bold font-RobotoSlab text-xs uppercase !leading-relaxed">
                          {`${commonFormLabels?.email?.[language]} : ${
                            appointment?.email || "N/A"
                          }`}
                        </div>
                        <div className="text-neutral-400 font-bold font-RobotoSlab text-xs uppercase !leading-relaxed">
                          {`${commonFormLabels?.phone?.[language]} : ${
                            appointment?.phone || "N/A"
                          }`}
                        </div>
                        <div
                          className="text-neutral-400 font-bold font-RobotoSlab text-xs uppercase !leading-relaxed"
                          dangerouslySetInnerHTML={{
                            __html: `${
                              commonFormLabels?.address?.[language]
                            } : ${appointment?.address1 || "N/A"}`,
                          }}
                        ></div>
                        <div className="text-neutral-400 font-bold font-RobotoSlab text-xs uppercase !leading-relaxed">
                          {`${commonFormLabels?.code?.[language]} : ${
                            appointment?.zipcode || "N/A"
                          }`}
                        </div>
                        <div className="text-neutral-400 font-bold font-RobotoSlab text-xs uppercase !leading-relaxed">
                          {`${commonFormLabels?.city?.[language]} : ${
                            appointment?.city || "N/A"
                          }`}
                        </div>
                        <div className="text-neutral-400 font-bold font-RobotoSlab text-xs uppercase !leading-relaxed">
                          {`${commonFormLabels?.state?.[language]} : ${
                            appointment?.state || "N/A"
                          }`}
                        </div>
                        <div className="text-neutral-400 font-bold font-RobotoSlab text-xs uppercase !leading-relaxed">
                          {`${commonFormLabels?.country?.[language]} : ${
                            appointment?.country || "N/A"
                          }`}
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center justify-between gap-3 flex-shrink-0">
                      <div className="relative">
                        <Button
                          buttonLabel={
                            appointment?.isBlocked
                              ? appointment?.buttonTextUnblock?.[language]
                              : appointment?.buttonTextBlock?.[language]
                          }
                          buttonLabelClasses={
                            "!uppercase !text-" +
                            appointment?.buttonColor +
                            " !text-base !whitespace-nowrap !font-medium"
                          }
                          buttonIconPosition={"left"}
                          buttonIcon={
                            appointment?.isBlocked
                              ? appointment?.buttonIconUnblock
                              : appointment?.buttonIconPink
                          }
                          buttonClasses={`${
                            appointment?.isBlocked
                              ? "!bg-red-500 !rounded-xl !px-2 !h-12 !text-white !border-2 !border-red-600"
                              : "!bg-transparent !rounded-xl !px-2 !h-12 !text-prontopsy-pink !border-2 !border-prontopsy-pink"
                          }`}
                          buttonFunction={() => handleUnblock(appointment?.id)}
                          isDisable={!blockLoaded}
                        />
                      </div>
                      <div className="relative">
                        <Button
                          buttonLabel={appointment?.buttonTextNote?.[language]}
                          buttonLabelClasses={
                            "!uppercase !text-white !text-base !whitespace-nowrap !font-medium"
                          }
                          buttonIconPosition={"left"}
                          buttonIcon={appointment?.buttonIconBlue}
                          buttonClasses={
                            "!bg-prontopsy-blue !rounded-xl !px-6 !h-12 !text-white"
                          }
                          buttonFunction={() =>
                            navigate(`/client-notes/${appointment?.id}`)
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <hr />
                </div>
              ))}
            {!loaded && (
              <div className="flex justify-center mt-4">
                <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-prontopsy-blue"></div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default MyClients;
