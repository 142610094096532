import React, { useEffect, useState } from "react";
import SmartPortalItem from "../single/SmartPortalItem";

import IconDirectAccess from "../../assets/images/icon-portal-direct-access.webp";
import IconOnBooking from "../../assets/images/icon-portal-on-booking.webp";
import {
  fetchBookingTypeHome,
  fetchBookingTypes,
} from "../../service/bookingType";
import { useSelector } from "react-redux";

const SmartPortal = () => {
  const [data, setData] = useState([]);
  const [loaded, setLoaded] = useState(true);
  const [bookingTypeData, setbookingTypeData] = useState({});
  const language = useSelector((state) => state?.languageSlice?.data);
  const pages = useSelector((state) => state?.pageSlice?.data);
  useEffect(() => {
    let info =
      pages.length > 0 &&
      pages.filter((elm) => elm?.title?.en == "Booking_type");
    setbookingTypeData(info[0]);
  }, [pages]);

  useEffect(() => {
    fetchBookingTypeHome(setData, setLoaded);
  }, []);

  let info =
    data.length > 0 &&
    data.map((elm, index) => ({
      _id: elm?._id,
      styleType: index + 1,
      icon: elm?.image?.url,
      name: elm?.title?.[language],
      description: elm?.description?.[language],
    }));

  const smartPortalData = {
    title: bookingTypeData && bookingTypeData?.subTitle?.[language],
    consultation: info,
  };
  return (
    <>
      <section className="relative py-10 md:py-12 lg:py-16 xl:py-20 flex items-center bg-prontopsy-pink">
        <div className="w-full px-4 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-8xl mx-auto">
          <div className="grid grid-cols-1 xl:grid-cols-2 gap-8 md:gap-12 lg:gap-16 xl:gap-20">
            <div className="flex items-center">
              {smartPortalData?.title && (
                <h2
                  className="text-center xl:text-left text-2xl sm:text-3xl lg:text-4xl xltext-5xl 2xl:text-[50px] text-white font-UnisonPro italic font-bold !leading-[1.11] 2xl:max-w-xl xl:max-w-sm"
                  dangerouslySetInnerHTML={{ __html: smartPortalData?.title }}
                ></h2>
              )}
            </div>
            <div className="relative">
              {smartPortalData?.consultation?.length > 0 && (
                <div className="flex flex-wrap gap-8">
                  {smartPortalData?.consultation?.map((item, index) => (
                    <SmartPortalItem
                      key={item?._id}
                      data={item}
                      index={index}
                      length={smartPortalData?.consultation?.length}
                    />
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SmartPortal;
