import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";

const BlankLayout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("appointmentData"));
    const profile = JSON.parse(localStorage.getItem("userDetails"));

    if (!data && profile?.roleCode === "user") {
      navigate("/"); // Redirect if there's no appointment data
    }
  }, [navigate]);

  return (
    <>
      <Outlet />
    </>
  );
};

export default BlankLayout;
