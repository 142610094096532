import React from "react";
import { useSelector } from "react-redux";

const NoDataFound = () => {
  const language = useSelector((state) => state?.languageSlice?.data);

  const languageConfig = {
    noDataFound: {
      en: "No Data Found",
      es: "Datos no encontrados",
      it: "Nessun dato trovato",
    },
    comment: {
      en: "It looks like there is no data available at the moment.",
      es: "Parece que no hay datos disponibles por el momento.",
      it: "Sembra che non ci siano dati disponibili al momento.",
    },
  };

  return (
    <div className="flex flex-col items-center justify-center h-[60vh]">
      <svg
        className="w-16 h-16 text-gray-400"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M20 13V6a2 2 0 00-2-2H6a2 2 0 00-2 2v7m16 0v5a2 2 0 01-2 2H6a2 2 0 01-2-2v-5m16 0h-2.586a1 1 0 00-.707.293l-2.414 2.414a1 1 0 01-.707.293h-3.172a1 1 0 01-.707-.293l-2.414-2.414A1 1 0 006.586 13H4"
        />
      </svg>
      <h2 className="mt-4 text-2xl font-bold text-gray-700">
        {languageConfig?.noDataFound?.[language]}
      </h2>
      <p className="mt-2 text-gray-500">
        {languageConfig?.comment?.[language]}
      </p>
    </div>
  );
};

export default NoDataFound;
