import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  data: "en",
};

export const languageSlice = createSlice({
  name: "language",
  initialState,
  reducers: {
    setLanguages: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
  },
});

export const { setLanguages } = languageSlice.actions;
export default languageSlice.reducer;
