// src/components/LogoutModal.js
import React from "react";
import Button from "../form/Button";
import { useSelector } from "react-redux";

const headingLang = {
  en: "Are you sure you want to logout?",
  es: "¿Estás seguro de que quieres cerrar sesión?",
  it: "Sei sicuro di voler uscire?",
};
const cancelLang = {
  en: "Cancel",
  es: "Cancelar",
  it: "Cancellare",
};
const logoutLang = {
  en: "Logout",
  es: "Cerrar sesión",
  it: "Esci",
};

const LogoutModal = ({ show, onClose, onConfirm, loaded = true }) => {
  const language = useSelector((state) => state?.languageSlice?.data);
  if (!show) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-50">
      <div className="bg-white rounded-lg shadow-lg w-96 p-5">
        <div className="text-center">
          <span className="text-6xl text-yellow-500">
            <i class="fa-solid fa-triangle-exclamation"></i>
          </span>
          <p className="mb-4">{headingLang?.[language]}</p>
        </div>
        <div className="flex justify-center gap-3">
          <Button
            buttonHasLink={true}
            buttonFunction={onClose}
            buttonLabel={`${cancelLang?.[language]}`}
            buttonIcon={"fa-light fa-xmark"}
            buttonIconPosition={"left"}
            buttonClasses={"uppercase text-sm hover:!bg-prontopsy-blue"}
          />

          <Button
            buttonHasLink={true}
            buttonLink={"/"}
            buttonLabel={`${logoutLang?.[language]}`}
            buttonIcon={"fa-solid fa-left-from-bracket"}
            buttonIconPosition={"left"}
            buttonClasses={"uppercase text-sm hover:!bg-prontopsy-blue"}
            buttonFunction={onConfirm}
            isDisable={!loaded}
          />
        </div>
      </div>
    </div>
  );
};

export default LogoutModal;
