import React, { useEffect } from "react";
import Modal from "./Modal";
import { useDispatch, useSelector } from "react-redux";
import { setToken } from "../../store/slice/vonageSlice";
import { useNavigate } from "react-router-dom";
import { setCallerData } from "../../store/slice/callingSlice";
import { useSocket } from "../socket/SocketProvider";
import { appointmentCancel } from "../../service/videoCallService";
// import ring_tone from "../../assets/music/ringtone.mp3";
// import music from "../assets/music/ringtone.mp3";

const headingLang = {
  en: "You have an appointment with",
  es: "Tienes una cita con",
  it: "Hai un appuntamento con",
};
const joinLang = {
  en: "Join Now",
  es: "Únete Ahora",
  it: "Iscriviti Ora",
};
const cancelLang = {
  en: "Cancel",
  es: "Cancelar",
  it: "Cancellare",
};

export const JoinCallModal = ({
  isOpen,
  setIsOpen,
  data,
  sessionId,
  sessionToken,
  callerData,
  audioRef,
}) => {
  const language = useSelector((state) => state?.languageSlice?.data);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const socket = useSocket();
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const appointment = JSON.parse(localStorage.getItem("appointmentData"));
  // const audioRef = useRef(new Audio(music));

  const callData = {
    user: {
      name: userDetails?.firstName + " " + userDetails?.lastName,
      email: userDetails?.email,
      image: userDetails?.image?.url,
      id: userDetails?._id,
    },
    consultant: {
      name: data?.name,
      image: data?.image?.url || data?.image,
      id: data?._id || data?.id,
    },
  };

  const handleJoinNow = () => {
    // localStorage.setItem("callerData", JSON.stringify(callData));
    dispatch(setCallerData(callData));
    dispatch(
      setToken({
        loading: true,
        sessionId: sessionId,
        token: sessionToken,
      })
    );
    if (audioRef) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }

    socket &&
      socket.emit("consultantJoin", {
        joined: true,
        mode: callerData?.mode,
        data: callerData,
        appointmentId: callerData?.appointmentId,
      });
    const appointmentData = {
      ...appointment,
      mode: callerData?.mode,
      appointmentId: callerData?.appointmentId,
      consultantId: callerData?.consultantId,
      appointmentStartTime: callerData?.appointmentStartTime,
      sessionId: sessionId,
      token: sessionToken,
    };
    if (!appointment || appointment?.appointmentId == null) {
      localStorage.setItem("appointmentData", JSON.stringify(appointmentData));
    }

    setIsOpen(false);
    callerData?.appointmentId &&
      sessionId &&
      sessionToken &&
      navigate(`/chats/${callerData?.appointmentId}`);
  };

  useSocket("consultantJoinResponse", async (data) => {
    if (
      userDetails &&
      data?.joined === true &&
      data?.data?.consultant?.id === userDetails?._id
    ) {
      // setConsultantJoined(true);
      // setIsOpen(false);
      // navigate(`/chats/${data?.appointmentId}`);
      handleJoinNow();
    }
  });

  const handleJoinLater = () => {
    setIsOpen(false);
    appointmentCancel({ appointmentId: callerData?.appointmentId });
    socket &&
      socket.emit("consultantBusy", {
        consultantId: callerData?.consultant?.id || "",
        isBusy: false,
      });
    socket &&
      socket.emit("vonageSessionReject", {
        disconnect: true,
        appointmentId: callerData?.appointmentId,
      });
  };

  useEffect(() => {
    let timer;

    if (isOpen) {
      timer = setTimeout(() => {
        handleJoinLater();
      }, 1000 * 60); // 1 minute
    }

    // Cleanup the timeout if isOpen changes or component unmounts
    return () => clearTimeout(timer);
  }, [isOpen]);

  useSocket("vonageSessionRejectResponse", async (data) => {
    if (data?.disconnect === true) {
      setIsOpen(false);
    }
  });

  return (
    <Modal isOpen={isOpen} titleShow={false} size={"xl2"}>
      <div className="flex flex-col items-center justify-center p-8 bg-white  rounded-lg">
        <img
          crossOrigin="anonymous"
          src={data?.image?.url || data?.image}
          alt={`${data?.firstName} ${data?.lastName}`}
          effect={"blur"}
          className="!w-32 !h-32 object-cover rounded-full border-4 border-gray-200 mb-6 flex items-center justify-center"
        />
        <span className="text-xl font-bold text-gray-800 mb-6">
          {headingLang?.[language]}{" "}
          {`${data?.firstName || data?.name} ${data?.lastName || ""}`}
        </span>
        <div className="flex space-x-6 text-xl">
          <button
            onClick={handleJoinNow}
            className="bg-green-500 flex gap-4 items-center text-white px-8 py-4 rounded-lg shadow-md hover:bg-green-600 transition duration-300 ease-in-out"
          >
            <i className="fa-solid fa-phone"></i>
            {joinLang?.[language]}
          </button>
          <button
            onClick={handleJoinLater}
            className="bg-red-500 flex gap-4 items-center text-white px-8 py-4 rounded-lg shadow-md hover:bg-red-600 transition duration-300 ease-in-out"
          >
            <i className="fa-solid fa-xmark"></i>
            {cancelLang?.[language]}
          </button>
        </div>
      </div>
    </Modal>
  );
};
