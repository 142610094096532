import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { classNames } from "../../helpers/classNames";

const Modal = ({
  children,
  isOpen = false,
  setIsOpen = () => {},
  size = "xl4",
  watermark = false,
  modalDialogueStyle = "",
  modalTitle = "Modal Title",
  titleShow = true,
  modalTitleClass = "",
  bodyClass = "",
}) => {
  const modalSize = {
    xs: "sm:max-w-xs",
    sm: "sm:max-w-sm",
    md: "sm:max-w-md",
    lg: "sm:max-w-lg",
    xl: "sm:max-w-xl",
    xl2: "sm:max-w-2xl",
    xl3: "sm:max-w-3xl",
    xl4: "sm:max-w-4xl",
    xl5: "sm:max-w-5xl",
    xl6: "sm:max-w-6xl",
    xl7: "sm:max-w-7xl",
    xl8: "sm:max-w-8xl",
    xl9: "sm:max-w-9xl",
    full: "max-w-full",
  };

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-60"
          onClose={() => setIsOpen(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gradient-to-b from-slate-950/90 via-slate-950/40 to-slate-950/60" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel
                  className={classNames(
                    `w-full ${modalSize[size]} transform overflow-hidden rounded-md bg-white text-left align-middle shadow-xl transition-all`,
                    bodyClass
                  )}
                >
                  <div className={"p-4 " + modalDialogueStyle}>
                    {(titleShow || watermark) && (
                      <div
                        className={
                          `flex justify-between modal-header relative !text-sm md:text-xl font-normal border-b text-forest-100 border-lime-100/50 text-gray-400` +
                          modalTitleClass
                        }
                      >
                        {modalTitle && titleShow && (
                          <div>
                            <span className="w-fit relative after:content-[''] after:w-full after:h-[6px] after:bg-forest-100 after:absolute after:-bottom-4 after:right-0 after:left-0">
                              {modalTitle}
                            </span>
                          </div>
                        )}
                      </div>
                    )}
                    <div className="modal-body">{children}</div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default Modal;
