import React from "react";
import Image from "../elements/Image";
import { useSelector } from "react-redux";
// import Texture01 from "../../../assets/images/texture-01.webp";
import Texture01 from "../../assets/images/texture-01.webp";
import Texture02 from "../../assets/images/texture-02.webp";
import Texture03 from "../../assets/images/texture-03.webp";
import Texture04 from "../../assets/images/texture-04.webp";

const HowItWorkdata = ({ data, index }) => {
  const language = useSelector((state) => state?.languageSlice?.data);
  return (
    <section
      className={`relative py-10 ${
        data.styleType === 1
          ? "bg-white"
          : data.styleType === 2
          ? "bg-prontopsy-blue"
          : data.styleType === 3
          ? "bg-prontopsy-pink"
          : ""
      }`}
    >
      {data.styleType === 2 && (
        <div className="absolute top-0 left-0 aspect-square max-w-96 w-full">
          <Image
            src={Texture01}
            alt=""
            effect="blur"
            className="object-contain w-full h-full"
          />
        </div>
      )}
      {/* {data.styleType === 2 && (
        <div className="absolute bottom-0 right-0 aspect-square max-w-56 w-full">
          <Image
            src={Texture01}
            alt=""
            effect="blur"
            className="object-contain w-full h-full"
          />
        </div>
      )} */}
      {data.styleType === 2 && (
        <div className="absolute bottom-0 right-0 aspect-square max-w-56 w-full">
          <Image
            src={Texture02}
            alt=""
            effect="blur"
            className="object-contain w-full h-full"
          />
        </div>
      )}

      {data.styleType === 3 && (
        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 aspect-[4/3] max-w-3xl m-auto w-full">
          <Image
            src={Texture03}
            alt=""
            effect="blur"
            className="object-contain w-full h-full"
          />
        </div>
      )}

      {data.styleType === 3 && (
        <div className="absolute right-0 top-0 aspect-[4/3] max-w-xs m-auto w-full">
          <Image
            src={Texture04}
            alt=""
            effect="blur"
            className="object-contain w-full h-full"
          />
        </div>
      )}
      <div className="w-full px-4 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-8xl mx-auto">
        <div className="block xl:grid grid-cols-12 gap-10 ">
          <div
            className={`relative xl:col-span-6 ${
              index % 2 === 0 ? "order-1 " : "order-2"
            }`}
          >
            {data?.image && (
              <div className="mx-auto max-w-72 md:max-w-sm lg:max-w-md xl:max-w-lg w-full aspect-auto">
                <Image
                  src={data?.image?.url}
                  alt=""
                  effect="blur"
                  className="object-contain"
                />
              </div>
            )}
          </div>
          <div
            className={`relative xl:col-span-6 flex flex-col justify-center ${
              index % 2 === 0 ? "order-2 " : "order-1"
            }`}
          >
            {data?.title && (
              <h2
                className={`text-center xl:text-left text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl font-UnisonPro italic font-bold leading-[1.1] ${
                  data.styleType === 1
                    ? "text-prontopsy-blue"
                    : data.styleType === 2 || data.styleType === 3
                    ? "text-white"
                    : ""
                }`}
                dangerouslySetInnerHTML={{ __html: data?.title?.[language] }}
              ></h2>
            )}

            {data?.description && (
              <div
                className={`text-center xl:text-left text-sm lg:text-base space-y-4 mt-6 ${
                  data.styleType === 1
                    ? "text-neutral-700"
                    : data.styleType === 2 || data.styleType === 3
                    ? "text-white"
                    : ""
                }`}
                dangerouslySetInnerHTML={{
                  __html: data?.description?.[language],
                }}
              ></div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowItWorkdata;
