import React, { useEffect, useState } from "react";
import Image from "../../elements/Image";
import ImageAbout from "../../../assets/images/what-is-prontopsy.webp";
import Button from "../../form/Button";
import { useSelector } from "react-redux";
import { readmoreBtn } from "../../elements/language";

const About = () => {
  const [about, setAbout] = useState({});
  const language = useSelector((state) => state?.languageSlice?.data);
  const data = useSelector((state) => state?.pageSlice?.data);
  useEffect(() => {
    let info =
      data.length > 0 && data.filter((elm) => elm?.title?.en == "Home_about");
    setAbout(info[0]);
  }, [data]);

  const aboutData = {
    image: about && about?.image?.url,
    title: about && about?.subTitle?.[language],
    description: about && about?.description?.[language],
    action: {
      link: "/about",
      label: readmoreBtn?.[language],
    },
  };
  return (
    <>
      <section className="relative py-10 md:py-12 lg:py-16 xl:py-20 flex items-center">
        <div className="w-full px-4 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-8xl mx-auto flex items-center">
          <div className="block xl:grid grid-cols-12 gap-10 items-center">
            <div className="relative xl:col-span-7">
              {aboutData?.image && (
                <div className="w-full flex relative aspect-[2/1]">
                  <Image
                    src={aboutData?.image}
                    alt={""}
                    effect={"blur"}
                    className={"object-contain w-full h-full"}
                  />
                </div>
              )}
            </div>
            <div className="relative xl:col-span-5">
              {aboutData?.title && (
                <h2
                  className="text-2xl lg:text-3xl xl:text-4xl 2xl:text-[45px] text-prontopsy-blue font-UnisonPro italic font-bold !leading-[1.11]"
                  dangerouslySetInnerHTML={{ __html: aboutData?.title }}
                ></h2>
              )}
              {aboutData?.description && (
                <div
                  className="text-sm lg:text-[17px] text-neutral-700 space-y-4 mt-6 !leading-[1.4rem]"
                  dangerouslySetInnerHTML={{ __html: aboutData?.description }}
                ></div>
              )}
              {aboutData?.action && (
                <div className="flex items-center !mt-10">
                  <Button
                    buttonHasLink={true}
                    buttonLink={aboutData?.action?.link}
                    buttonLabel={aboutData?.action?.label}
                    buttonClasses={
                      "!h-10 lg:!h-12 !bg-prontopsy-blue hover:!bg-prontopsy-pink"
                    }
                    buttonLabelClasses={"!text-[13px]"}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
