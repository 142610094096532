import React, { useEffect, useState } from "react";
import Image from "../../../Image";
import User from "../../../../../assets/images/user-03.webp";
import Button from "../../../../form/Button";
import InputGroup from "../../../../form/Input";
import Select from "../../../../form/Select";
import Checkbox from "../../../../form/Checkbox";
import { useDispatch, useSelector } from "react-redux";
import { saveChange } from "../../../language";
import {
  changePasswordService,
  updateProfileService,
} from "../../../../../service/profileService";
import { toast } from "react-toastify";
import { setProfileData } from "../../../../../store/slice/profileSlice";
const AccountInformation = ({ data, dataLoaded }) => {
  const [loaded, setLoaded] = useState(true);
  const [passwordLoaded, setPasswordLoaded] = useState(true);
  const language = useSelector((state) => state.languageSlice.data);
  const [formData, setFormData] = useState({});
  const [emailData, setEmailData] = useState({});
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const profileData = useSelector((state) => state?.profileSlice?.data);
  // const role = JSON.parse(localStorage.getItem("userDetails"))?.roleCode;
  // const id = JSON.parse(localStorage.getItem("userDetails"))?._id;
  const role = profileData?.roleCode;
  const id = profileData?._id;
  const dispatch = useDispatch();
  const regex =
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]).{8,}$/;
  const [clicked, setClicked] = useState(false);

  const languageConfig = {
    accountInformationLang: {
      en: "Account Informations",
      es: "Información de la cuenta",
      it: "Informazioni dell'account",
    },
    emailNotificationLang: {
      en: "I DO NOT want to receive promotional emails.",
      es: "NO quiero recibir correos electrónicos promocionales.",
      it: "NON voglio ricevere e-mail promozionali.",
    },
    changePassword: {
      en: "Change Password",
      es: "Cambiar contraseña",
      it: "Cambia password",
    },
    oldPassword: {
      en: "Old Password",
      es: "Contraseña antigua",
      it: "Vecchia password",
    },
    newPassword: {
      en: "New Password",
      es: "Nueva contraseña",
      it: "Nuova password",
    },
    confirmPassword: {
      en: "Confirm Password",
      es: "Confirmar contraseña",
      it: "Conferma password",
    },
    enter: {
      en: "Enter",
      es: "Ingresar",
      it: "Inserisci",
    },
  };

  const AccountInformation = {
    title: languageConfig?.accountInformationLang?.[language],
    titleAlt: languageConfig?.changePassword?.[language],
    image: User,
  };
  const isPasswordInvalid = password && !regex.test(password);
  const isFormDataPasswordEmpty = clicked && !formData.password;
  const isNewPasswordInvalid = newPassword && !regex.test(newPassword);
  const isFormDataNewPasswordEmpty = clicked && !formData.newPassword;

  useEffect(() => {
    setEmailData((pre) => ({
      ...pre,
      "setting[emailNotification]": data?.setting?.emailNotification,
    }));
  }, [data]);

  const handleEmailNotificationChange = (value) => {
    setLoaded(false);
    updateProfileService({
      "setting[emailNotification]": value,
      roleCode: role,
      id: id,
    }).then((res) => {
      setLoaded(true);
      if (res.data.status == 200) {
        toast.success(res?.data?.message);
        dispatch(setProfileData(res.data.data));

        setEmailData((pre) => ({
          ...pre,
          "setting[emailNotification]":
            res.data.data?.setting?.emailNotification,
        }));
      }
    });
  };

  const handleData = (e) => {
    setFormData((pre) => ({
      ...pre,
      [e.target.name]: e.target.value,
    }));
    setClicked(false);
  };

  const handleSubmit = () => {
    // Validate form data
    if (!formData.currentPassword) {
      toast.info("Current Password is Required!");
      return;
    }
    if (isPasswordInvalid) {
      toast.info("Invalid Old Password's Format");
      return;
    }
    if (!formData.newPassword) {
      toast.info("New Password is Required!");
      return;
    }
    if (!formData.confirmPassword) {
      toast.info("Confirm Password is Required!");
      return;
    }
    if (formData.newPassword !== formData.confirmPassword) {
      toast.info("New Password and Confirm Password must match!");
      return;
    }

    // Call API to change password
    changePasswordService({ ...formData }, setPasswordLoaded)
      .then((res) => {
        if (res.data.status === 200) {
          toast.success(res.data.message);
          setPassword("");
          setNewPassword("");
          setConfirmPassword("");
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((error) => {
        toast.error("Failed to update password. Please try again later.");
        console.error("Error while updating password:", error);
      });
  };

  return (
    <div className="relative">
      <div className="relative w-full border-t border-dashed border-neutral-300 py-4">
        {AccountInformation?.title && (
          <h2
            className="text-base text-prontopsy-blue font-RobotoSlab font-bold"
            dangerouslySetInnerHTML={{ __html: AccountInformation?.title }}
          ></h2>
        )}
      </div>
      <div className="flex  justify-between gap-5 pt-5 pb-5 border-t border-neutral-300">
        <div className="w-full">
          <div className="grid grid-cols-12 gap-3 mb-4">
            <div className="col-span-12">
              <div className="relative">
                <InputGroup
                  label={"*EMAIL"}
                  inputValue={data && data?.email}
                  labelClasses={
                    "!uppercase !text-prontopsy-blue !text-[10px] font-RobotoSlab"
                  }
                  inputClasses={"!bg-transparent !h-12"}
                  isDisabled={true}
                  loaded={dataLoaded}
                />
              </div>
              <div className="relative mt-2">
                <Checkbox
                  checkboxLabel={
                    languageConfig?.emailNotificationLang?.[language]
                  }
                  isChecked={emailData?.["setting[emailNotification]"]}
                  onChange={(e) => {
                    // setEmailData((pre) => ({
                    //   ...pre,
                    //   "setting[emailNotification]": e.target.checked,
                    // }));
                    handleEmailNotificationChange(e.target.checked);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="relative w-full  py-4">
        {AccountInformation?.titleAlt && (
          <h2
            className="text-base text-prontopsy-blue font-RobotoSlab font-bold"
            dangerouslySetInnerHTML={{ __html: AccountInformation?.titleAlt }}
          ></h2>
        )}
      </div>
      <div className="flex  justify-between gap-5 pt-5 pb-5 border-t border-neutral-300">
        <div className="w-full">
          <div className="grid grid-cols-12 gap-3">
            <div className="col-span-4">
              <div className="relative">
                <InputGroup
                  onChange={(e) => {
                    handleData(e);
                    setPassword(e.target.value);
                  }}
                  label={`*${languageConfig?.oldPassword?.[language]}`}
                  inputValue={password}
                  inputName="currentPassword"
                  //   labelClasses="font-RobotoSlab"
                  inputPlaceholder={`${languageConfig?.enter?.[language]} ${languageConfig?.oldPassword?.[language]}`}
                  inputType="password"
                  //   divClass="!border !border-black !bg-transparent"
                  labelClasses={
                    "!uppercase !text-prontopsy-blue !text-[10px] font-RobotoSlab"
                  }
                  inputClasses={"!bg-transparent !h-12"}
                  errorType={
                    isFormDataPasswordEmpty
                      ? "warning"
                      : isPasswordInvalid
                      ? "warning"
                      : ""
                  }
                  errorText={
                    password && !regex.test(password)
                      ? "Minimum eight characters, at least one letter, one number and one special character"
                      : "Password is required!"
                  }
                  loaded={dataLoaded}
                />
              </div>
            </div>
            <div className="col-span-4">
              <div className="relative">
                <InputGroup
                  onChange={(e) => {
                    handleData(e);
                    setNewPassword(e.target.value);
                  }}
                  label={`*${languageConfig?.newPassword?.[language]}`}
                  inputValue={newPassword}
                  inputName="newPassword"
                  //   labelClasses="font-RobotoSlab"
                  inputPlaceholder={`${languageConfig?.enter?.[language]} ${languageConfig?.newPassword?.[language]}`}
                  inputType="password"
                  //   divClass="!border !border-black !bg-transparent"
                  labelClasses={
                    "!uppercase !text-prontopsy-blue !text-[10px] font-RobotoSlab"
                  }
                  inputClasses={"!bg-transparent !h-12"}
                  errorType={
                    isFormDataNewPasswordEmpty
                      ? "warning"
                      : isNewPasswordInvalid
                      ? "warning"
                      : ""
                  }
                  errorText={
                    newPassword && !regex.test(newPassword)
                      ? "Minimum eight characters, at least one letter, one number and one special character"
                      : "Password is required!"
                  }
                  loaded={dataLoaded}
                />
              </div>
            </div>
            <div className="col-span-4">
              <div className="relative">
                <InputGroup
                  inputName="confirmPassword"
                  onChange={(e) => {
                    handleData(e);
                    setConfirmPassword(e.target.value);
                  }}
                  label={`*${languageConfig?.confirmPassword?.[language]}`}
                  inputValue={confirmPassword}
                  inputPlaceholder={`${languageConfig?.enter?.[language]} ${languageConfig?.confirmPassword?.[language]}`}
                  inputType="password"
                  labelClasses={
                    "!uppercase !text-prontopsy-blue !text-[10px] font-RobotoSlab"
                  }
                  inputClasses={"!bg-transparent !h-12"}
                  errorType={
                    confirmPassword && newPassword !== confirmPassword
                      ? "danger"
                      : ""
                  }
                  errorText={
                    (confirmPassword &&
                      !confirmPassword.match(newPassword) &&
                      "Confirm Password Does Not Match With Password!") ||
                    (clicked &&
                      !formData.confirmPassword &&
                      "Confirm password is required!")
                  }
                  isDisabled={!formData.newPassword}
                  loaded={dataLoaded}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="relative flex items-center gap-2">
        <Button
          buttonLabel={saveChange?.[language]}
          buttonLabelClasses={
            "!uppercase !text-base !whitespace-nowrap !font-medium"
          }
          buttonClasses={
            "!rounded-xl !px-4 !h-12 !text-prontopsy-pink !text-white !bg-gradient-to-r !from-prontopsy-blue !to-prontopsy-pink !hover:bg-prontopsy-pink !duration-300"
          }
          buttonFunction={handleSubmit}
          isDisable={!passwordLoaded}
        />
        {/* <Button
          buttonLabel={"Close My Account"}
          buttonLabelClasses={
            "!uppercase !text-base !whitespace-nowrap !font-medium"
          }
          buttonClasses={
            "!rounded-xl !px-4 !h-12 !text-prontopsy-pink !text-white !bg-gradient-to-r !from-prontopsy-blue !to-prontopsy-pink !hover:bg-prontopsy-pink !duration-300"
          }
        /> */}
      </div>
    </div>
  );
};

export default AccountInformation;
