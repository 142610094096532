import React from 'react';
import Th from './Th';

const Thead = ({ tableHeadData }) => {

  return (
    <>
      <thead>
        <tr className="bg-slate-50 text-slate-700 text-xs leading-none border-b border-slate-200">
          {tableHeadData.map((item) => (
            <Th key={item._id} name={item.name} nameAlign={item.align} isSort={item.isSort} isFilter={item.isFilter} labelClass={item.labelClass} />
          ))}
        </tr>
      </thead>
    </>
  );
};

export default Thead;