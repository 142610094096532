import React, { useEffect, useRef, useState } from "react";
import RatingStars from "../../elements/RatingStars";
import Image from "../../elements/Image";
import User from "../../../assets/images/user.svg";
import noImage from "../../../assets/images/noImageFound.webp";
import {
  fetchAvgStatReview,
  fetchReviewList,
} from "../../../service/reviewService";
import dayjs from "dayjs";
import NoDataFound from "../../elements/NoDataFound";
import { consultantDetails as consultantDetailsLang } from "../../elements/language";
import { useSelector } from "react-redux";

const Reviews = ({ consultantDetails }) => {
  const token = localStorage.getItem("auth_token");
  const [list, setList] = useState([]);
  const [loaded, setLoaded] = useState(true);
  const [statistics, setStatistics] = useState(null);
  // for pagination
  const [limit, setLimit] = useState(4);
  const [totalDocs, setTotalDocs] = useState(null);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [page, setPage] = useState(1);
  const [allList, setAllList] = useState([]);
  const language = useSelector((state) => state?.languageSlice?.data);

  const [scrolledToBottom, setScrolledToBottom] = useState(false);
  const scrollableRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (scrollableRef.current) {
        const { scrollHeight, scrollTop, clientHeight } = scrollableRef.current;
        const scrolledToBottom = scrollTop + clientHeight >= scrollHeight;

        setScrolledToBottom(scrolledToBottom);
      }
    };

    const scrollableElement = scrollableRef.current;
    if (scrollableElement) {
      scrollableElement.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (scrollableElement) {
        scrollableElement.removeEventListener("scroll", handleScroll);
      }
    };
  }, [allList]);

  useEffect(() => {
    if (scrolledToBottom && hasNextPage && loaded) {
      setPage((pre) => pre + 1);
    }
  }, [scrolledToBottom, hasNextPage, loaded]);

  useEffect(() => {
    consultantDetails._id &&
      fetchReviewList(
        consultantDetails?._id,
        { limit: 5, page: page },
        token,
        setList,
        setLoaded,
        setHasNextPage
      ).then((res) => {
        if (res?.data?.docs?.status === 200) {
          let metaData = res?.data?.docs?.metadata;
          setTotalDocs(metaData?.totalDocs);
          page !== 1
            ? setAllList((prevList) => [...prevList, ...res?.data?.docs?.data])
            : setAllList(res?.data?.docs?.data);
        }
      });
  }, [consultantDetails._id, page]);

  useEffect(() => {
    consultantDetails._id &&
      fetchAvgStatReview(consultantDetails?._id, token, setStatistics);
  }, [consultantDetails._id]);

  const sections = [
    {
      title: {
        en: "SKILL AND KNOWLEDGE",
        es: "HABILIDAD Y CONOCIMIENTO",
        it: "COMPETENZA E CONOSCENZA",
      },
      ratingData: statistics && statistics?.data?.avgKnowledgeSkill,
    },
    {
      title: {
        en: "CORDIALITY AND COMMUNICATION",
        es: "CORDIALIDAD Y COMUNICACIÓN",
        it: "CORDIALITÀ E COMUNICAZIONE",
      },
      ratingData: statistics && statistics?.data?.avgCommunication,
    },
    {
      title: {
        en: "INTERNET ACCESS",
        es: "ACCESO A INTERNET",
        it: "ACCESSO AD INTERNET",
      },
      ratingData: statistics && statistics?.data?.avgInternetAccess,
    },
  ];

  const reviewsData =
    list &&
    Array.isArray(list) &&
    list.length > 0 &&
    allList.map((elm, index) => ({
      id: elm?._id,
      image: elm?.clientData?.image || noImage,
      user:
        elm?.clientData?.firstName && elm?.clientData?.lastName
          ? `${elm.clientData.firstName} ${elm.clientData.lastName}`
          : "N/A",
      rating: elm?.review,
      date: dayjs(elm?.createdAt).format("MMM D, YYYY"),
      ratingData: elm?.rating,
    }));

  // const reviewsData = [
  //   {
  //     id: 1,
  //     image: User,
  //     user: "Franco R.",
  //     rating: "Ottimo",
  //     date: "11 gennaio 2023",
  //     ratingData: 4,
  //   },
  //   {
  //     id: 2,
  //     image: User,
  //     user: "Verdinelli F.",
  //     rating: "Carino",
  //     date: "10 gennaio 2023",
  //     ratingData: 4,
  //   },
  // ];
  return (
    <div className="relative">
      <h5 className="text-lg text-prontopsy-blue font-UnisonPro font-bold py-2 italic">
        {consultantDetailsLang?.summery?.[language]}
      </h5>
      <div className="grid md:grid-cols-3 grid-cols-2 gap-5">
        {statistics &&
          sections.map((section, index) => (
            <div
              key={index}
              className={`flex flex-col items-center justify-center w-full py-6 space-y-2 border border-neutral-300 ${
                index % 2 === 1 ? " border-l border-r border-neutral-300" : ""
              }`}
            >
              <div className="max-w-32 w-full text-xs sm:text-base font-RobotoSlab font-bold text-center text-prontopsy-blue">
                {section?.title?.[language]}
              </div>
              <RatingStars data={section.ratingData} />
            </div>
          ))}
      </div>
      <div className="grid grid-cols-1 gap-0 pt-8">
        <h5 className="text-lg text-prontopsy-blue font-UnisonPro font-bold italic">
          {consultantDetailsLang?.clientFeedback?.[language]}
        </h5>
        {list && Array.isArray(list) && list.length > 0 ? (
          <div ref={scrollableRef} className="h-[40vh] overflow-auto">
            {list.length > 0 &&
              reviewsData.map((review, index) => (
                <div key={index} className="flex items-center gap-6 py-4">
                  <div className="w-20 h-20 rounded-full overflow-hidden border border-neutral-300 p-2 flex-shrink-0">
                    <div className="w-full h-full">
                      <Image
                        src={review.image}
                        alt={""}
                        effect={"blur"}
                        className={"object-cover w-full h-full rounded-full"}
                      />
                    </div>
                  </div>
                  <div className="w-[calc(100%-80px)] flex-shrink">
                    <div className="font-RobotoSlab text-xs sm:text-base lg:text-lg text-prontopsy-blue capitalize font-bold">
                      {review.user}
                    </div>
                    <RatingStars data={review.ratingData} />
                    <div
                      className="text-neutral-500 text-xs sm:text-base lg:text-lg font-RobotoSlab capitalize"
                      dangerouslySetInnerHTML={{ __html: review.rating }}
                    ></div>
                    <div className="text-neutral-400 text-xs sm:text-base lg:text-lg font-RobotoSlab">
                      {review.date}
                    </div>
                  </div>
                </div>
              ))}
            {!loaded && (
              <div className="flex justify-center mt-4">
                <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-prontopsy-blue"></div>
              </div>
            )}
          </div>
        ) : (
          <div className="mt-7">
            <NoDataFound />
          </div>
        )}
      </div>
    </div>
  );
};

export default Reviews;
