import React, { useEffect, useRef, useState } from "react";
import ConsultantItem from "../../single/ConsultantItem";
import Table from "../../elements/table/Table";
import ImageUser03 from "../../../assets/images/user-01.webp";
import Reviews from "../reviews/Reviews";
import { useDispatch, useSelector } from "react-redux";
import { consultantDetails } from "../../elements/language";
import SpinLoader from "../../elements/table/SpinLoader";
import { useSocket } from "../../socket/SocketProvider";
import { debounce } from "lodash";
import { fetchConsultantStatusList } from "../../../service/consultantService";
import { setConsultantSwitch } from "../../../store/slice/switchSlice";

const ConsultantDetailsContent = ({ data, loaded }) => {
  const language = useSelector((state) => state?.languageSlice?.data);
  const [showMore, setShowMore] = useState(false);
  const [onlineData, setOnlineData] = useState([]);
  const [onlineUniqueData, setOnlineUniqueData] = useState({});
  const [busyData, setbusyData] = useState(null);
  const profile = useSelector((state) => state?.initialProfileSlice?.data);
  const socket = useSocket();
  const dispatch = useDispatch();
  const busyConsultants = useSelector(
    (state) => state?.consultantStatusSlice?.data
  );
  const [consultantStatusList, setConsultantStatusList] = useState([]);
  const [foundConsultant, setFoundConsultant] = useState({});

  const isSwitchClicked = useSelector(
    (state) => state?.consultantSwitchSlice?.data
  );

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  useSocket(
    "userStatusResponse",
    (data) => {
      // data && setOnlineData((pre) => [...pre, data]);
      setOnlineData((pre) => {
        // Create a map from the existing consultants
        const consultantMap = new Map(pre.map((elm) => [elm.userId, elm]));

        // Update or add the new data
        consultantMap.set(data.userId, data);

        // Convert the map back to an array
        return Array.from(consultantMap.values());
      });

      // setTimeout(() => {
      //   fetchConsultantStatusList().then((res) => {
      //     setConsultantStatusList(res?.data?.data);
      //   });
      // }, 1000);
    },

    [socket, profile]
  );
  useEffect(() => {
    fetchConsultantStatusList().then((res) => {
      setConsultantStatusList(res?.data?.data);
      dispatch(setConsultantSwitch({ status: false }));
    });
  }, []);
  // useEffect(() => {
  //   fetchConsultantStatusList().then((res) => {
  //     setConsultantStatusList(res?.data?.data);
  //     dispatch(setConsultantSwitch({ status: false }));
  //   });
  // }, [foundConsultant]);
  useEffect(() => {
    isSwitchClicked.status === true &&
      fetchConsultantStatusList().then((res) => {
        setConsultantStatusList(res?.data?.data);
        dispatch(setConsultantSwitch({ status: false }));
      });
  }, [isSwitchClicked, foundConsultant]);

  useEffect(() => {
    if (consultantStatusList.length > 0) {
      const formattedData = consultantStatusList.map((elm) => ({
        userId: elm._id, // Directly accessing _id
        online: elm.onlineStatus === "online" ? true : false, // Simplified conditional
      }));
      const formattedBusyData = consultantStatusList.map((elm) => ({
        consultantId: elm?._id,
        isBusy: elm?.onlineStatus === "busy" ? true : false,
      }));

      setbusyData(formattedBusyData);
      setOnlineData(formattedData); // Replace the old state with the new data
    } else {
      setOnlineData([]); // Optionally reset initialData if consultantStatusList is empty
    }
  }, [consultantStatusList]);

  useEffect(() => {
    if (onlineData.length > 0) {
      const foundUser = onlineData.find((elm) => elm?.userId === data?._id);
      if (foundUser) {
        setOnlineUniqueData(foundUser);
      }
    }
  }, [onlineData, data?._id, socket]);

  useEffect(() => {
    if (
      busyConsultants &&
      Array.isArray(busyConsultants) &&
      busyConsultants.length > 0
    ) {
      const foundUser = busyConsultants.find(
        (elm) => elm?.consultantId === data?._id
      );
      if (foundUser) {
        setbusyData(foundUser);
      }
    }
  }, [busyConsultants, data?._id, socket]);

  // useSocket("consultantBusyResponse", (data) => {
  //   setbusyData(data);
  // });

  const onlineStatus =
    onlineUniqueData && data && onlineUniqueData?.online === true
      ? "online"
      : "offline";

  useEffect(() => {
    const Consultant =
      busyData &&
      Array.isArray(busyData) &&
      busyData.length > 0 &&
      busyData.find((elm) => elm?.consultantId === data?._id);

    Consultant && setFoundConsultant(Consultant);
  }, [busyData, consultantStatusList, data?._id]);

  useSocket("consultantBusyResponse", (dataa) => {
    if (
      data &&
      dataa &&
      dataa.consultantId &&
      data?._id == dataa?.consultantId
    ) {
      setFoundConsultant(dataa);
    }

    setTimeout(() => {
      fetchConsultantStatusList().then((res) => {
        setConsultantStatusList(res?.data?.data);
      });
    }, 1000);
  });

  const busyStatus =
    busyData && data && foundConsultant && foundConsultant?.isBusy === true
      ? "busy"
      : "online";

  useSocket(
    "chatJoinConfirmResponse",
    async (data) => {
      // setTimeout(() => {
      //   fetchConsultantStatusList().then((res) => {
      //     setConsultantStatusList(res?.data?.data);
      //   });
      // }, 1000);
    },
    []
  );
  // useSocket("consultantJoinResponse", async (data) => {
  // setTimeout(() => {
  //   fetchConsultantStatusList().then((res) => {
  //     // console.log(res?.data?.data);
  //     setConsultantStatusList(res?.data?.data);
  //   });
  // }, 1000);
  // });
  useSocket("videoSessionDisconnectResponse", async (data) => {
    // setTimeout(() => {
    //   fetchConsultantStatusList().then((res) => {
    //     // console.log(res?.data?.data);
    //     setConsultantStatusList(res?.data?.data);
    //   });
    // }, 1000);
  });
  // useSocket("chatDisconnectResponse", async (data) => {
  // setTimeout(() => {
  //   fetchConsultantStatusList().then((res) => {
  //     // console.log(res?.data?.data);
  //     setConsultantStatusList(res?.data?.data);
  //   });
  // }, 1000);
  // });

  const consultantData = {
    _id: data && data?._id,
    name:
      data && data?.firstName
        ? data.firstName + " " + data.lastName
        : "Consultant",
    image: data && data?.image?.url,
    specialization:
      (data && data?.specialization?.inventionArea?.title?.[language]) || "N/A",

    intervention:
      (data && data?.member?.personalQualification?.title[language]) || "N/A",

    language: {
      _id: data && data?.languagesData?._id,
      name: data && data?.languagesData?.name,
      code: data?.languagesData?.code,
    },

    nationality: {
      name: (data && data?.personal?.nationality?.nationality) || "N/A",
      code: (data && data?.personal?.nationality?.code.toLowerCase()) || "N/A",
    },
    rate: {
      currency: (data && data?.currencyData?.currency) || "N/A",
      value:
        data &&
        data?.serviceDataTableMap &&
        Array.isArray(data?.serviceDataTableMap) &&
        data?.serviceDataTableMap.length > 0 &&
        data?.serviceDataTableMap[0]?.price,
    },
    isOnline:
      busyStatus && onlineStatus && busyStatus == "busy"
        ? "busy"
        : onlineStatus,

    isFavorite: data?.isFavourite,
    rating: {
      value: data && data?.ratingData?.rating,
      reviews: data && data?.ratingData?.reviewCount,
    },
    biography: {
      title: consultantDetails?.biography?.[language],
      description: (data && data?.biography?.longBioDescription) || "N/A",
    },
    areaofSpecialization: {
      title: consultantDetails?.spelizationArea?.[language],
      content: [
        {
          _id: 1,
          label: consultantDetails?.areaOfIntervention?.[language],
          data:
            data &&
            data.specialization &&
            data.specialization.inventionArea &&
            data.specialization.inventionArea.title &&
            data.specialization.inventionArea.title[language]
              ? `<li>${data.specialization.inventionArea.title[language]}</li>`
              : "N/A",
        },
        {
          _id: 2,
          label: consultantDetails?.mentalHealth?.[language],
          data:
            (data &&
              data?.specialization?.mental?.map((elm) => {
                return `<li key=${elm._id}>${elm?.title?.[language]}</li>`;
              })) ||
            "N/A",
        },
        {
          _id: 3,
          label: consultantDetails?.psyChologicalProblems?.[language],
          data:
            (data &&
              data?.specialization?.psycological?.map((elm) => {
                return `<li key=${elm._id}>${elm?.title?.[language]}</li>`;
              })) ||
            "N/A",
        },
        {
          _id: 4,
          label: consultantDetails?.theraputicProblems?.[language],
          data:
            (data &&
              data?.specialization?.theraputic?.map((elm) => {
                return `<li key=${elm._id}>${elm?.title?.[language]}</li>`;
              })) ||
            "N/A",
        },
        {
          _id: 4,
          label: consultantDetails?.otherTopics?.[language],
          data:
            (data &&
              data?.specialization?.other?.map((elm) => {
                return `<li key=${elm._id}>${elm?.title?.[language]}</li>`;
              })) ||
            "N/A",
        },
      ],
    },
    serviceoffered: {
      title: consultantDetails?.serviceOffered?.[language],
      tableHeadData: [
        {
          _id: 1,
          name: consultantDetails?.serviceName?.[language],
          labelClass: "font-UnisonPro !font-bold italic text-base !text-blue",
          align: "left",
        },
        {
          _id: 2,
          name: consultantDetails?.cost?.[language],
          labelClass: "font-UnisonPro !font-bold italic text-base !text-blue",
          align: "left",
        },
        {
          _id: 3,
          name: consultantDetails?.mode?.[language],
          labelClass: "font-UnisonPro !font-bold italic text-base !text-blue",
          align: "left",
        },
        {
          _id: 4,
          name: consultantDetails?.duration?.[language],
          labelClass: "font-UnisonPro !font-bold italic text-base !text-blue",
          align: "left",
        },
      ],
      tableData:
        data &&
        data?.serviceDataTableMap &&
        Array.isArray(data?.serviceDataTableMap) &&
        data?.serviceDataTableMap.length > 0 &&
        data?.serviceDataTableMap.map((elm, index) => ({
          _id: elm?._id,
          rowData: [
            {
              _id: elm?.service?._id,
              type: "text",
              align: "center",
              cellClass: "!min-w-[100px] !py-6",
              data: elm?.service?.title?.[language] || "N/A",
            },
            {
              _id: index,
              type: "text",
              align: "center",
              cellClass: "!min-w-[100px] !py-6",
              data: `${data && data?.currencyData?.currency} ${
                elm?.price
              } / min`,
            },
            {
              _id: "03",
              type: "text",
              align: "center",
              cellClass: "!min-w-[100px] !py-6",
              data:
                elm?.mode &&
                Array.isArray(elm.mode) &&
                elm.mode.length > 0 &&
                elm.mode.map((item) => item.title?.[language]).join(", "),
            },
            {
              _id: "04",
              type: "text",
              align: "center",
              cellClass: "!min-w-[100px] !py-6",
              data: "60 min (Max)",
            },
          ],
        })),
    },
    reviews: {
      title: consultantDetails?.review?.[language],
    },
    actions: {
      call: {
        isLink: false,
        click: () => {},
        label: consultantDetails?.callNow?.[language],
      },
      booking: {
        isLink: false,
        click: () => {},
        label: consultantDetails?.bookConsult?.[language],
      },
      email: {
        isLink: true,
        // link: "mailto:info@prontopsy.com",
        link: `/email-details/${consultantDetails?.chatDetail?._id}`,
        label: consultantDetails?.emailMe?.[language],
      },
    },
    serviceDataTableMap: data?.serviceDataTableMap || [],
  };

  return !loaded ? (
    <SpinLoader />
  ) : (
    <section className="relative py-10 md:py-12 lg:py-16 xl:py-20 ">
      <div className="w-full px-4 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-8xl mx-auto">
        <div className=" flex flex-col lg:flex-row lg:justify-between lg:items-start md:items-center">
          <div className="w-full xl:w-1/3 md:w-10/12">
            <ConsultantItem
              data={consultantData}
              mainClasses={"!py-0"}
              nameClasses={"!text-base"}
              bottomClasses={"m-auto"}
              topClasses={"mx-auto"}
              divClasses={"flex-col !border-black"}
              hasLink={true}
              isConsultant={true}
            />
          </div>
          <div className="w-full xl:w-2/3 md:w-11/12 space-y-5 mt-5 sm:mt-0:">
            {consultantData?.biography && (
              <div className="w-full shadow-2xl">
                <div className="py-5 px-5 bg-prontopsy-blue">
                  {consultantData?.biography?.title && (
                    <h2 className="uppercase text-lg lg:text-xl text-white font-UnisonPro italic font-bold !leading-[1.1]">
                      {consultantData?.biography?.title}
                    </h2>
                  )}
                </div>
                <div className="pt-4 pb-8 px-3">
                  {consultantData?.biography?.description && (
                    <div
                      className="font-RobotoSlab font-normal text-base"
                      dangerouslySetInnerHTML={{
                        __html: showMore
                          ? consultantData.biography.description
                          : consultantData.biography.description.slice(0, 200) +
                            "...",
                      }}
                    ></div>
                  )}
                  {consultantData?.biography?.description?.length > 200 && (
                    <button
                      className="text-prontopsy-blue font-bold mt-4"
                      onClick={toggleShowMore}
                    >
                      {showMore
                        ? consultantDetails?.showLess?.[language]
                        : consultantDetails?.showMore?.[language]}
                    </button>
                  )}
                </div>
              </div>
            )}

            {consultantData?.areaofSpecialization && (
              <div className="w-full shadow-2xl">
                <div className="py-5 px-5 bg-prontopsy-blue">
                  <h2 className="uppercase text-lg lg:text-xl text-white font-UnisonPro italic font-bold !leading-[1.1]">
                    {consultantData?.areaofSpecialization?.title}
                  </h2>
                </div>
                {consultantData?.areaofSpecialization?.content?.length > 0 && (
                  <div className="pt-4 pb-8 px-3 space-y-7">
                    {consultantData?.areaofSpecialization?.content?.map(
                      (item, index) => (
                        <div
                          className="text-lg text-neutral-600 font-RobotoSlab !leading-8"
                          key={index}
                        >
                          {item.label && (
                            <h2 className="font-UnisonPro font-bold text-lg pb-2 italic text-prontopsy-blue">
                              {item.label}
                            </h2>
                          )}
                          {Array.isArray(item.data) ? (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: item.data.join(""),
                              }}
                            ></div>
                          ) : (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: item.data,
                              }}
                            ></div>
                          )}
                        </div>
                      )
                    )}
                  </div>
                )}
              </div>
            )}
            {consultantData?.serviceoffered && (
              <div className="w-full shadow-2xl">
                <div className="py-5 px-5 bg-prontopsy-blue">
                  {consultantData?.serviceoffered?.title && (
                    <h2 className="uppercase text-lg lg:text-xl text-white font-UnisonPro italic font-bold !leading-[1.1]">
                      {consultantData?.serviceoffered?.title}
                    </h2>
                  )}
                </div>

                <div className="pt-4 pb-8 px-3">
                  <div className="text-lg text-neutral-600 font-RobotoSlab !leading-8">
                    <Table
                      tableHeadData={
                        consultantData?.serviceoffered?.tableHeadData
                      }
                      tableData={consultantData?.serviceoffered?.tableData}
                      isLoading={false}
                      tableContainer={"!rounded-xl !border !border-slate-600"}
                      tableClasses={""}
                      trClasses={"!border-0"}
                      thClasses={"!text-xl !font-bold !text-black"}
                    />
                  </div>
                </div>
              </div>
            )}

            {consultantData?.reviews && (
              <div className="w-full shadow-2xl">
                <div className="py-5 px-5 bg-prontopsy-blue">
                  {consultantData?.reviews?.title && (
                    <h2 className="uppercase text-lg lg:text-xl text-white font-UnisonPro italic font-bold !leading-[1.1]">
                      {consultantData?.reviews?.title}
                    </h2>
                  )}
                </div>
                <div className="pt-4 pb-8 px-3">
                  <div className="text-lg text-neutral-600 font-RobotoSlab !leading-8">
                    <div className="text-lg text-neutral-600 font-RobotoSlab !leading-8">
                      <Reviews consultantDetails={data} />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ConsultantDetailsContent;
