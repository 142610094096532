import React from "react";
import Modal from "./Modal";
import { useNavigate } from "react-router-dom";
import { useSocket } from "../socket/SocketProvider";
import { appointmentCancel } from "../../service/videoCallService";

export const JoinChatConfirmModal = ({ isOpen, setIsOpen }) => {
  const navigate = useNavigate();
  const socket = useSocket();
  const appointmentData = JSON.parse(localStorage.getItem("appointmentData"));
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));

  useSocket("chatJoinConfirmResponse", async (data) => {
    if (
      userDetails &&
      data?.joined === true &&
      data?.callerData?.user?.id === userDetails?._id &&
      data?.appointmentId
    ) {
      setIsOpen(false);
      navigate(`/text-chat/${data?.appointmentId}`);
    }
  });

  const handleJoinLater = () => {
    setIsOpen(false);
    appointmentCancel({ appointmentId: appointmentData?.appointmentId });
    socket &&
      socket.emit("chatSessionReject", {
        disconnect: true,
        appointmentId: appointmentData?.appointmentId,
      });
    socket &&
      socket.emit("consultantBusy", {
        consultantId: appointmentData?.consultantId || "",
        isBusy: false,
      });
  };

  useSocket("chatSessionRejectResponse", async (data) => {
    if (data?.disconnect === true) {
      setIsOpen(false);
    }
  });

  return (
    <Modal isOpen={isOpen} titleShow={false} size={"md"}>
      <div className="flex flex-col items-center justify-center p-2 bg-white  rounded-lg">
        <span className="text-xl font-bold text-gray-800 mb-6 blink">
          Waiting for your consultant to join ...
        </span>
        <div className="flex space-x-6 text-xl">
          <button
            onClick={handleJoinLater}
            className="bg-red-500 flex gap-4 items-center  text-white px-8 py-4 rounded-lg shadow-md hover:bg-red-600 transition duration-300 ease-in-out"
          >
            <i class="fa-solid fa-xmark"></i>
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
};
