import React, { useContext, useEffect, useState } from "react";
import Button from "../form/Button";
import Checkbox from "../form/Checkbox";
import Image from "./Image";
import CalendarMonth from "./CalendarMonth";
import Modal from "./Modal";
import Stripe from "../../assets/images/stripe.webp";
import Cards from "./payment/Cards";
import AddNewCards from "./payment/AddNewCards";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useDispatch, useSelector } from "react-redux";
import { appointmentModalLang } from "./language";
import SuccessModal from "./SuccessModal";
import { toast } from "react-toastify";
import RadioButton from "../form/RadioButton";
import { bookAppointment } from "../../service/videoCallService";
import { fetchConsultantDetails } from "../../service/consultantService";
import { useNavigate } from "react-router-dom";
import { setToken } from "../../store/slice/vonageSlice";
import { setCallerData } from "../../store/slice/callingSlice";
import SocketContext, { useSocket } from "../socket/SocketProvider";
import { JoinChatConfirmModal } from "./JoinChatConfirmationModal";
// import useBatteryStatus from "./hooks/useBatteryStatus";
import JoinCallConfirmationModal from "./JoinCallConfirmationModal";
import useBatteryStatus from "./hooks/useBatteryStatus";
import useInternetSpeed from "./hooks/useInternetSpeed";

const CallingModal = ({ isOpen, setIsOpen, data, serviceId, modes }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedTime, setSelectedTime] = useState(null);
  const lang = useSelector((state) => state?.languageSlice?.data);
  const [checked, setChecked] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isConsentAccepted, setIsConsentAccepted] = useState(false);
  const [isPrivecyAccepted, setIsPrivecyAccepted] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [isCardAvailable, setIsCardAvailable] = useState(true);
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const token = localStorage.getItem("auth_token");
  const [details, setDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const vonageData = useSelector((state) => state?.vonageSlice?.data);
  const socket = useSocket();
  const [selectedCard, setSelectedCard] = useState(null);
  const [modeId, setModeId] = useState(null);
  const [isOpenConfirmationChatModal, setIsOpenConfirmationChatModal] =
    useState(false);
  const [callConfirmModal, setCallConfirmModal] = useState(false);

  const handleOptionChange = (option, id) => {
    setSelectedOption(option);
    setModeId(id);
  };
  const handleDateSelect = (value) => {
    setSelectedDate(value);
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const callerData = {
    user: {
      name: userDetails?.firstName + " " + userDetails?.lastName,
      email: userDetails?.email,
      image: userDetails?.image?.url,
      id: userDetails?._id,
    },
    consultant: {
      name: data?.name,
      image: data?.image,
      id: data?._id,
    },
    mode: selectedOption,
  };

  //-----------------Internet Speed------------------
  const { speed, error, checkInternetSpeed } = useInternetSpeed();
  useEffect(() => {
    checkInternetSpeed();
  }, []);

  //--------------------******--------------------------

  //--------------Battery Percentage------------------
  const { level, charging } = useBatteryStatus();

  const handleContinue = () => {
    if (charging === false && Math.round(level * 100) <= 5) {
      toast.error(
        "Your battery level is low (bellow 5%). Please charge your device and try again."
      );
      return;
    }

    if (!modeId) {
      toast.error("Select mode first.");
      return;
    }
    if (!isConsentAccepted) {
      toast.error("Check consent to the psychological consult first.");
      return;
    }
    if (checked) {
      toast.error("Please add your new card!");
      return;
    }
    if (!isPrivecyAccepted) {
      toast.error("Check privacy and policy first.");
      return;
    }
    if (speed && speed * 1 < 1) {
      alert(
        "Your network speed is too low. Please check your internet connection and try again."
      );
      return;
    }
    setLoading(true);

    bookAppointment({
      consultantId: data?._id,
      details: {
        appointmentDate: formatDate(new Date()),
      },
      modeId: modeId,
      serviceId: serviceId,
    }).then((res) => {
      if (res?.status == 200 || res?.status == 201) {
        const appointmentData = {
          appointmentId: res?.data?.data?.appointment?._id,
          consultantId: res?.data?.data?.appointment?.consultantId,
          appointmentStartTime:
            res?.data?.data?.appointment?.consultantJoinTime,
          mode: selectedOption,
          callerData: callerData,
          sessionId: res?.data?.data?.appointment?.sessionId,
          token: res?.data?.data?.appointment?.sessionToken,
        };
        localStorage.setItem(
          "appointmentData",
          JSON.stringify(appointmentData)
        );
        localStorage.setItem(
          "consultantId",
          JSON.stringify(res?.data?.data?.appointment?.consultantId)
        );
        socket &&
          socket.emit("consultantBusy", {
            consultantId: res?.data?.data?.appointment?.consultantId,
            isBusy: true,
          });

        setLoading(false);
        setIsOpen(false);
        if (selectedOption === "chat") {
          socket &&
            socket.emit("chatSession", {
              ...appointmentData,
              // ...callerData,
              // appointmentId: res?.data?.data?.appointment?._id,
              // appointmentStartTime:
              //   res?.data?.data?.appointment?.details?.appointmentStartTime,
            });
          setIsOpenConfirmationChatModal(true);
        } else if (selectedOption === "video" || selectedOption === "voice") {
          dispatch(setCallerData(callerData));
          socket &&
            socket.emit("consultantCall", {
              ...callerData,
              ...appointmentData,
            });
          socket &&
            socket.emit("vonageTokBoxSession", {
              // sessionId: res?.data?.data?.appointment?.sessionId,
              // token: res?.data?.data?.appointment?.sessionToken,
              ...appointmentData,
            });

          dispatch(
            setToken({
              loading: true,
              sessionId: res?.data?.data?.appointment?.sessionId,
              token: res?.data?.data?.appointment?.sessionToken,
              mode: selectedOption,
            })
          );
          setCallConfirmModal(true);
        }
      } else {
        setLoading(false);
        toast.error(res?.data?.message);
      }
    });
  };

  return (
    <div>
      <Modal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        modalTitle={appointmentModalLang?.title1?.[lang]}
      >
        <Button
          buttonClasses={
            "!w-10 !h-10 !rounded-full !border-black !bg-transparent shadow-md !absolute !top-2 !right-2 !text-black !flex-row !items-center !justify-center"
          }
          buttonIcon={"fa-regular fa-xmark"}
          buttonIconPosition={"left"}
          buttonFunction={() => setIsOpen(false)}
        />
        <div className="relative space-y-2  ">
          <div className="relative">
            <div className="text-2xl font-bold text-prontopsy-blue">
              {data?.name}
            </div>
          </div>
          <div className="relative flex flex-wrap justify-between gap-8 w-full">
            <div className="relative flex flex-col text-xs text-left font-RobotoSlab">
              {data?.rate?.value || "N/A"}{" "}
              <span>{data?.rate?.currency}/MIN</span>
            </div>
            {/* <div className="relative flex flex-col text-xs text-left font-RobotoSlab">
              IMPORTO MASSIMO
              <span>CATTURATO:EURO 60,00</span>
            </div> */}
          </div>

          {/* M O D E S */}
          <div className="relative flex items-center flex-wrap gap-3 my-5 w-full">
            {modes &&
              modes.length > 0 &&
              modes.map((elm, index) => (
                <RadioButton
                  label={elm?.title?.[lang]}
                  icon={`${
                    elm?.slug == "voice"
                      ? "fa-solid fa-phone-volume"
                      : elm?.slug == "video"
                      ? "fas fa-video"
                      : "fa-solid fa-comments"
                  }`}
                  isSelected={selectedOption === elm?.slug}
                  onChange={() => handleOptionChange(elm?.slug, elm?._id)}
                  key={index}
                />
              ))}
            {/* <RadioButton
              label={appointmentModalLang?.voice?.[lang]}
              icon="fa-solid fa-phone-volume"
              isSelected={selectedOption === "voice"}
              onChange={() => handleOptionChange("voice")}
            />
            <RadioButton
              label={appointmentModalLang?.video?.[lang]}
              icon="fas fa-video"
              isSelected={selectedOption === "video"}
              onChange={() => handleOptionChange("video")}
            />
            <RadioButton
              label={appointmentModalLang?.chat?.[lang]}
              icon="fa-solid fa-comments"
              isSelected={selectedOption === "chat"}
              onChange={() => handleOptionChange("chat")}
            /> */}
          </div>
          {/* <div className="relative flex items-center flex-wrap  gap-3 !my-5 !w-full">
            <Button
              buttonLabel={appointmentModalLang?.voice?.[lang]}
              buttonClasses={
                "relative px-4 py-2 !bg-prontopsy-blue hover:!bg-prontopsy-lightblue !text-white !text-xs text-center uppercase"
              }
              buttonIcon={"fa-solid fa-phone-volume"}
              buttonIconPosition={"left"}
              buttonLabelClasses={"font-UnisonPro font-semibold italic"}
            />
            <Button
              buttonLabel={appointmentModalLang?.video?.[lang]}
              buttonClasses={
                "relative px-4 py-2 !bg-prontopsy-blue hover:!bg-prontopsy-lightblue !text-white !text-xs text-center uppercase"
              }
              buttonIcon={"fas fa-video"}
              buttonIconPosition={"left"}
              buttonLabelClasses={"font-UnisonPro font-semibold italic"}
            />
            <Button
              buttonLabel={appointmentModalLang?.chat?.[lang]}
              buttonClasses={
                "relative px-4 py-2 !bg-prontopsy-blue hover:!bg-prontopsy-lightblue !text-white !text-xs text-center uppercase"
              }
              buttonIcon={"fa-solid fa-comments"}
              buttonIconPosition={"left"}
              buttonLabelClasses={"font-UnisonPro font-semibold italic"}
            />
          </div> */}

          {/* C A L E N D A R */}
          {/* <div>
            <div className="relative !mb-4">
              <CalendarMonth value={selectedDate} onChange={handleDateSelect} />
            </div>
          </div> */}

          <div className="flex items-start flex-col gap-1 !mb-4 space-y-1">
            {/* <div className="text-xs text-slate-500 w-full">
              {appointmentModalLang?.title2?.[lang]}
              <div className="mt-3 overflow-x-auto w-full pb-2">
                <div className="flex items-center gap-3 justify-start whitespace-nowrap">
                  {times.map((time) => (
                    <div
                      key={time.id}
                      className={`flex items-center gap-3 justify-center border w-[230px] p-2 rounded-md ${
                        selectedTime === time.id
                          ? "bg-[#B32CBD] text-white"
                          : "bg-slate-100"
                      } cursor-pointer shadow-sm`}
                      onClick={() => setSelectedTime(time.id)}
                    >
                      <div>
                        <p className="text-xl">{time.start}</p>
                        <p>{time.date}</p>
                      </div>
                      <div className="w-4 border border-gray-400" />
                      <div>
                        <p className="text-xl">{time.end}</p>
                        <p>{time.date}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div> */}

            {/*E X I S T I N G     C A R D S */}
            {!checked && (
              <Cards
                setIsCardAvailable={setIsCardAvailable}
                setSelectedCard={setSelectedCard}
              />
            )}

            {/* A D D   N E W   C A R D */}

            <AddNewCards checked={checked} setChecked={setChecked} />
          </div>

          <div className="bg-neutral-200 w-full py-2 px-4">
            <div className="flex items-center gap-5">
              <span className="text-green-600 flex-shrink-0">
                <i className="fa-solid fa-lock"></i>
              </span>
              <div className="text-xs text-prontopsy-blue font-UnisonPro font-bold flex-shrink w-full">
                {appointmentModalLang?.title3?.[lang]}
              </div>
              <div className="h-8 min-w-32 w-full border border-black flex items-center justify-center gap-1">
                <div className="font-UnisonPro text-black font-bold text-[10px] italic">
                  powered by
                </div>
                <div className="w-6 h-6 overflow-hidden">
                  <Image
                    src={Stripe}
                    alt="Stripe"
                    className="w-full h-full object-contain"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="relative space-y-2">
            <Checkbox
              checkboxLabel={appointmentModalLang?.title4?.[lang]}
              checkboxLabelClass={`!font-RobotoSlab ${
                isConsentAccepted ? "!text-black" : "!text-neutral-400"
              }`}
              isChecked={isConsentAccepted}
              onChange={(e) => setIsConsentAccepted(e.target.checked)}
            />
            <Checkbox
              checkboxLabel={appointmentModalLang?.title5?.[lang]}
              checkboxLabelClass={`!font-RobotoSlab ${
                isPrivecyAccepted ? "!text-black" : "!text-neutral-400"
              }`}
              isChecked={isPrivecyAccepted}
              onChange={(e) => setIsPrivecyAccepted(e.target.checked)}
            />
          </div>
          <div className="relative">
            <Button
              buttonLabel={appointmentModalLang?.continue?.[lang]}
              buttonClasses={`!bg-prontopsy-blue !h-12 !px-6`}
              buttonLabelClasses={`!font-UnisonPro !text-xs !text-white !font-bold !italic`}
              buttonFunction={handleContinue}
              isDisable={loading}
              loading={loading}
            />
          </div>
        </div>
      </Modal>
      <SuccessModal
        isOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        isModalOpen={isModalOpen}
        setIsConsentAccepted={setIsConsentAccepted}
        setIsPrivecyAccepted={setIsPrivecyAccepted}
        setSelectedTime={setSelectedTime}
      />
      <JoinChatConfirmModal
        isOpen={isOpenConfirmationChatModal}
        setIsOpen={setIsOpenConfirmationChatModal}
      />
      <JoinCallConfirmationModal
        isOpen={callConfirmModal}
        setIsOpen={setCallConfirmModal}
        callerData={callerData}
      />
    </div>
  );
};

export default CallingModal;
