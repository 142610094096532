import React, { useEffect, useState } from "react";
import { classNames } from "../../helpers/classNames";

const Checkbox = ({
  divClass,
  checkboxName,
  checkboxInputClass,
  isChecked,
  checkboxLabel,
  checkboxLabelClass,
  onChange = () => {},
  ...props
}) => {
  const [value, setValue] = useState(false);
  useEffect(() => {
    setValue(isChecked);
  }, [isChecked]);

  return (
    <>
      <div className="relative overflow-hidden flex items-center">
        <input
          type="checkbox"
          name={checkboxName}
          className={classNames(
            "peer absolute top-0 left-0 w-5 h-5 opacity-0 z-10 cursor-pointer",
            checkboxInputClass
          )}
          checked={value}
          onChange={(e) => {
            onChange(e);
          }}
        />
        <div
          className={classNames(
            "w-5 h-5 min-w-[20px] mr-3 border border-slate-300 rounded flex items-center justify-center text-sm bg-white text-black/0 peer-checked:bg-prontopsy-blue peer-checked:border-prontopsy-blue peer-checked:text-white",
            divClass
          )}
        >
          <i className="fa-light fa-fw fa-check"></i>
        </div>
        <div
          className={classNames(
            "text-sm text-slate-500 w-full",
            checkboxLabelClass
          )}
        >
          {checkboxLabel}
        </div>
      </div>
    </>
  );
};

export default Checkbox;
