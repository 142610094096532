const RadioButton = ({ label, icon, isSelected, onChange }) => {
  return (
    <label
      className={`font-UnisonPro font-semibold italic relative px-4 py-2 !bg-prontopsy-blue hover:!bg-prontopsy-lightblue !text-white !text-xl text-center uppercase rounded-md ${
        isSelected
          ? "border-2 border-prontopsy-lightblue !bg-prontopsy-lightblue"
          : "border border-transparent"
      }`}
    >
      <input
        type="radio"
        className="hidden"
        checked={isSelected}
        onChange={onChange}
      />
      <i className={`mr-2 ${icon}`}></i>
      {label}
    </label>
  );
};
export default RadioButton;
