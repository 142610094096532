import React from "react";
import Image from "../../elements/Image";
import UserOne from "../../../assets/images/videocall22.jpg";

const UserVideoOne = ({ isAudioEnabled, data }) => {
  return (
    <div className="w-full relative text-white text-l ">
      <div id="subscriber" className="w-full h-[90%] "></div>
      <div>{data}</div>
      <div
        id="publisher"
        className="shadow-md m-5  p-1 bg-slate-800 w-1/4 h-1/4   rounded-md absolute right-0 top-0"
      >
        <i
          className={`fa-solid ${
            isAudioEnabled ? "fa-microphone" : "fa-microphone-slash"
          } absolute bottom-3 right-3 text-[15px] bg-slate-800 rounded-full p-2`}
        ></i>
      </div>
      {/* <div className="flex justify-end mx-4 my-2">You</div> */}
    </div>
  );
};

export default UserVideoOne;
