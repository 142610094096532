import React, { useEffect, useState } from "react";
import Image from "../../../Image";
import User from "../../../../../assets/images/user-03.webp";
import Button from "../../../../form/Button";
import InputGroup from "../../../../form/Input";
import Select from "../../../../form/Select";
import Checkbox from "../../../../form/Checkbox";
import { useDispatch, useSelector } from "react-redux";
import { commonFormLabels, profile } from "../../../language";
import dayjs from "dayjs";
import { updateProfileService } from "../../../../../service/profileService";
import { setProfileData } from "../../../../../store/slice/profileSlice";
import { toast } from "react-toastify";
import { isValidDate } from "../../../../../util/CheckValidDate";
const InsuranceInfo = ({ data, dataLoaded }) => {
  const language = useSelector((state) => state?.languageSlice?.data);
  const profileData = useSelector((state) => state?.profileSlice?.data);
  const role = JSON.parse(localStorage.getItem("userDetails"))?.roleCode;
  const id = JSON.parse(localStorage.getItem("userDetails"))?._id;
  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState(true);
  const [formData, setFormData] = useState({});

  const InsuranceInfo = {
    title: profile?.insuranceInformation?.[language],
    titleAlt: "Change Password",
    image: User,
  };
  const checkBoxTitle = {
    en: "I do not have insurance",
    es: "No tengo seguro",
    it: "Non sono sicuro",
  };

  useEffect(() => {
    setFormData((pre) => ({
      ...pre,
      "insurance[companyName]":
        profileData && profileData?.insurance?.companyName,
      "insurance[expirationDate]":
        profileData &&
        profileData?.insurance?.expirationDate &&
        dayjs(profileData?.insurance?.expirationDate).format("YYYY-MM-DD"),
      "insurance[number]": profileData && profileData?.insurance?.number,
      "insurance[hasInsurance]":
        profileData && profileData?.insurance?.hasInsurance,
    }));
  }, [profileData]);

  const handleFormData = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = () => {
    if (!formData?.["insurance[companyName]"]) {
      toast.info("Company name is required!");
      return;
    }
    if (!formData?.["insurance[expirationDate]"]) {
      toast.info("Expiration date is required!");
      return;
    }
    if (!isValidDate(formData?.["insurance[expirationDate]"])) {
      toast.warning("Enter a valid expiration date!");
      return;
    }
    if (!formData?.["insurance[number]"]) {
      toast.info("Insurance number is required!");
      return;
    }

    setLoaded(false);
    updateProfileService({
      ...formData,
      roleCode: role,
      id: id,
    }).then((res) => {
      setLoaded(true);
      if (res.data.status == 200) {
        toast.success(res?.data?.message);
        dispatch(setProfileData(res.data.data));
      }
    });
  };
  return (
    <div className="relative">
      <div className="relative w-full border-t border-dashed border-neutral-300 py-4">
        {InsuranceInfo?.title && (
          <h2
            className="text-base text-prontopsy-blue font-RobotoSlab font-bold"
            dangerouslySetInnerHTML={{ __html: InsuranceInfo?.title }}
          ></h2>
        )}
      </div>

      <div className="flex  justify-between gap-5 pt-5 pb-6 border-t border-neutral-300">
        <div className="w-full">
          <div className="grid grid-cols-12 gap-3">
            <div className="col-span-4">
              <div className="relative">
                <InputGroup
                  label={`*${commonFormLabels?.insuranceCompaneyName?.[language]} :`}
                  inputName={"insurance[companyName]"}
                  inputValue={formData?.["insurance[companyName]"]}
                  onChange={handleFormData}
                  labelClasses={
                    "!uppercase !text-prontopsy-blue !text-[10px] font-RobotoSlab"
                  }
                  inputClasses={"!bg-transparent !h-12"}
                  inputPlaceholder={"Company Name"}
                />
              </div>
              <div className="relative mt-2">
                <Checkbox
                  checkboxName={"insurance[hasInsurance]"}
                  checkboxLabel={checkBoxTitle?.[language]}
                  onChange={(e) => handleFormData(e)}
                  isChecked={formData?.["insurance[hasInsurance]"]}
                />
              </div>
            </div>
            <div className="col-span-4">
              <div className="relative">
                <InputGroup
                  label={`*${commonFormLabels?.insuranceNumber?.[language]} :`}
                  inputName={"insurance[number]"}
                  inputValue={formData?.["insurance[number]"]}
                  onChange={handleFormData}
                  labelClasses={
                    "!uppercase !text-prontopsy-blue !text-[10px] font-RobotoSlab"
                  }
                  inputClasses={"!bg-transparent !h-12"}
                  inputPlaceholder={"Insurance Number"}
                />
              </div>
            </div>
            <div className="col-span-4">
              <div className="relative">
                <InputGroup
                  label={`*${commonFormLabels?.insuranceExpirationDate?.[language]} :`}
                  inputName={"insurance[expirationDate]"}
                  inputValue={formData?.["insurance[expirationDate]"]}
                  onChange={handleFormData}
                  labelClasses={
                    "!uppercase !text-prontopsy-blue !text-[10px] font-RobotoSlab"
                  }
                  inputClasses={"!bg-transparent !h-12"}
                  inputPlaceholder={"YYYY-MM-DD"}
                  errorType={
                    !isValidDate(formData?.["insurance[expirationDate]"]) &&
                    "warning"
                  }
                  errorText={"The date value should be in YYYY-MM-DD format!"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="relative flex items-center gap-2">
        <Button
          buttonLabel={"Save Changes"}
          buttonLabelClasses={
            "!uppercase !text-base !whitespace-nowrap !font-medium"
          }
          buttonClasses={
            "!rounded-xl !px-4 !h-12 !text-prontopsy-pink !text-white !bg-gradient-to-r !from-prontopsy-blue !to-prontopsy-pink !hover:bg-prontopsy-pink !duration-300"
          }
          buttonFunction={handleSubmit}
          isDisable={!loaded}
        />
      </div>
    </div>
  );
};

export default InsuranceInfo;
