import React, { useEffect, useState } from "react";
import StatisticsItem from "../../single/StatisticsItem";
import { useSelector } from "react-redux";
import { fetchDashboardStatistics } from "../../../service/dashboardService";

const Statistics = () => {
  const [statistics, setStatistics] = useState({});
  const [numbers, setNumbers] = useState({});
  const language = useSelector((state) => state?.languageSlice?.data);
  const data = useSelector((state) => state?.pageSlice?.data);
  useEffect(() => {
    let info =
      data.length > 0 &&
      data.filter((elm) => elm?.title?.en == "Home_Statistics");
    setStatistics(info);
  }, [data]);
  useEffect(() => {
    fetchDashboardStatistics(setNumbers);
  }, []);

  // let info =
  //   about.length > 0 &&
  //   about.map((elm, index) => ({
  //     _id: elm?._id,
  //     subTitle: elm?.subTitle,
  //     description: elm?.description,
  //   }));

  const statisticsData = {
    title:
      statistics && statistics.length > 0 && statistics[0].subTitle[language],
    subtitle:
      statistics &&
      statistics.length > 0 &&
      statistics[0].description[language],
    statistics: [
      {
        _id: 1,
        name: {
          en: "Consultants",
          es: "Consultores",
          it: "Consulenti",
        },
        value: (numbers && numbers?.readForConsultant) || "0",
      },
      {
        _id: 2,
        name: {
          en: "Areas Of Intervention",
          es: "Áreas de intervención",
          it: "Aree di intervento",
        },
        value: (numbers && numbers?.totalAreaOfIntervention) || "0",
      },
      {
        _id: 3,
        name: {
          en: "Consultings Completed",
          es: "Consultorías Completadas",
          it: "Consulenze completate",
        },
        value: (numbers && numbers?.totalConsultingCompleted) || "0",
      },
      {
        _id: 4,
        name: {
          en: "Satisfied Customers",
          es: "Clientes Satisfechos",
          it: "Clienti Soddisfatti",
        },
        value: (numbers && numbers?.satisfiedCustomer) || "0",
      },
    ],
  };

  return (
    <>
      <section className="relative py-10 md:py-12 lg:py-16 xl:py-20 2xl:py-24 flex items-center bg-prontopsy-blue">
        <div className="w-full px-4 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-8xl mx-auto">
          <div className="w-full mx-auto max-w-6xl text-center">
            {statisticsData?.title && (
              <h2
                className="text-2xl lg:text-3xl xl:text-4xl 2xl:text-[45px] text-white font-UnisonPro italic font-bold !leading-[1.1]"
                dangerouslySetInnerHTML={{ __html: statisticsData?.title }}
              ></h2>
            )}
            {statisticsData?.subtitle && (
              <div
                className="text-sm md:text-base lg:text-[17px] leading-[1.4rem] text-white space-y-4 mt-10 font-light"
                dangerouslySetInnerHTML={{ __html: statisticsData?.subtitle }}
              ></div>
            )}
          </div>
          {statisticsData?.statistics?.length > 0 && (
            <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-6 md:gap-8 lg:gap-10 mt-16">
              {statisticsData?.statistics?.map((item, index) => (
                <StatisticsItem key={index} data={item} />
              ))}
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default Statistics;
