import React from "react";
import Td from "./Td";

const Tbody = ({ tableData }) => {
  return (
    <>
      <tbody className="text-slate-700 text-sm font-light">
        {tableData &&
          tableData.map((item) => (
            <tr className="border-b border-slate-200" key={item._id}>
              {item.rowData?.map((itemData) => (
                <Td
                  key={itemData._id}
                  type={itemData.type}
                  data={itemData.data}
                  image={itemData.image}
                  statusLabel={itemData.statusLabel}
                  statusType={itemData.statusType}
                />
              ))}
            </tr>
          ))}
      </tbody>
    </>
  );
};

export default Tbody;
