import React, { useEffect } from "react";
import Modal from "./Modal";
import { useNavigate } from "react-router-dom";
import { useSocket } from "../socket/SocketProvider";
import { appointmentCancel } from "../../service/videoCallService";
import { useSelector } from "react-redux";

const headingLang = {
  en: "You have an appointment with",
  es: "Tienes una cita con",
  it: "Hai un appuntamento con",
};
const joinLang = {
  en: "Join Now",
  es: "Únete Ahora",
  it: "Iscriviti Ora",
};
const cancelLang = {
  en: "Cancel",
  es: "Cancelar",
  it: "Cancellare",
};

export const JoinChatModal = ({
  isOpen,
  setIsOpen,
  appointmentData,
  callerData,
  audioRef,
  from = null,
}) => {
  const language = useSelector((state) => state?.languageSlice?.data);
  const navigate = useNavigate();
  const appointment =
    appointmentData || JSON.parse(localStorage.getItem("appointmentData"));
  const socket = useSocket();
  const userDetails = useSelector((state) => state?.profileSlice?.data);
  // const userDetails = JSON.parse(localStorage.getItem("userDetails"));

  const handleJoinNow = () => {
    if (audioRef) {
      audioRef.current && audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
    appointment &&
      localStorage.setItem("appointmentData", JSON.stringify(appointment));
    socket &&
      socket.emit("chatJoinConfirm", {
        joined: true,
        ...appointment,
        // appointmentId: appointment?.appointmentId,
        // appointmentStartTime: appointment?.appointmentStartTime,
        // data: callerData,
      });
    setIsOpen(false);

    // if (
    //   appointment &&
    //   data &&
    //   appointment?.appointmentId !== data?.appointmentId
    // ) {
    //   localStorage.removeItem("appointmentData");
    // }

    // if (!appointment || appointment?.appointmentId == null) {
    //   localStorage.setItem(
    //     "appointmentData",
    //     JSON.stringify({
    //       ...appointment,
    // callerData: callerData,
    // appointmentId: callerData?.appointmentId,
    // appointmentStartTime: callerData?.appointmentStartTime,
    //     })
    //   );
    // }

    // from &&
    //   from == "appointment" &&
    //   appointment?.appointmentId &&
    appointment &&
      appointment?.appointmentId &&
      navigate(`/text-chat/${appointment?.appointmentId}`);
  };

  useSocket(
    "chatJoinConfirmResponse",
    async (data) => {
      if (data?.joined === true && data?.consultantId === userDetails?._id) {
        handleJoinNow();
        // setIsOpen(false);

        // appointment &&
        //   appointment?.appointmentId === data?.appointmentId &&
        //   navigate(`/text-chat/${data?.appointmentId}`);
      }
    },
    [socket]
  );

  const handleJoinLater = () => {
    setIsOpen(false);
    appointmentCancel({ appointmentId: appointment?.appointmentId });
    socket &&
      socket.emit("chatSessionReject", {
        disconnect: true,
        appointmentId: appointment?.appointmentId,
      });

    socket &&
      socket.emit("consultantBusy", {
        consultantId: appointment?.consultantId || "",
        isBusy: false,
      });
  };

  useEffect(() => {
    let timer;

    if (isOpen) {
      timer = setTimeout(() => {
        handleJoinLater();
      }, 1000 * 60); // 1 minute
    }

    // Cleanup the timeout if isOpen changes or component unmounts
    return () => clearTimeout(timer);
  }, [isOpen]);

  useSocket("chatSessionRejectResponse", async (data) => {
    if (data?.disconnect === true) {
      setIsOpen(false);
    }
  });
  // console.log(appointment, "appointment");
  return (
    <Modal
      isOpen={isOpen}
      // setIsOpen={setIsOpen}
      titleShow={false}
      size={"xl2"}
    >
      <div className="flex flex-col items-center justify-center p-8 bg-white  rounded-lg">
        <img
          crossOrigin="anonymous"
          src={
            userDetails?.roleCode == "user"
              ? appointment?.callerData?.consultant?.image
              : appointment?.callerData?.user?.image
          }
          alt={`${
            userDetails?.roleCode == "user"
              ? appointment?.callerData?.consultant?.name
              : appointment?.callerData?.user?.name
          }`}
          effect={"blur"}
          className="!w-32 !h-32 object-cover rounded-full border-4 border-gray-200 mb-6 flex items-center justify-center"
        />
        <span className="text-xl font-bold text-gray-800 mb-6">
          {headingLang?.[language]}{" "}
          {`${
            userDetails?.roleCode == "user"
              ? appointment?.callerData?.consultant?.name
              : appointment?.callerData?.user?.name
          }`}
        </span>
        <div className="flex space-x-6 text-xl">
          <button
            onClick={handleJoinNow}
            className="bg-green-500  flex gap-4 items-center  text-white px-8 py-4 rounded-lg shadow-md hover:bg-green-600 transition duration-300 ease-in-out"
          >
            <i class="fa-solid fa-message"></i>
            {joinLang?.[language]}
          </button>
          <button
            onClick={handleJoinLater}
            className="bg-red-500 flex gap-4 items-center  text-white px-8 py-4 rounded-lg shadow-md hover:bg-red-600 transition duration-300 ease-in-out"
          >
            <i class="fa-solid fa-xmark"></i>
            {cancelLang?.[language]}
          </button>
        </div>
      </div>
    </Modal>
  );
};
