import React from "react";
import { useSelector } from "react-redux";

const StatisticsItem = ({ data }) => {
  const language = useSelector((state) => state?.languageSlice?.data);
  return (
    <>
      <div className="relative bg-white rounded-lg aspect-[3/1] xl:aspect-[4/3] flex flex-col items-center justify-center py-2 md:py-4 lg:py-6 px-6 text-center gap-3">
        {data.value && (
          <div className="font-Robo text-2xl lg:text-3xl xl:text-4xl 2xl:text-[50px] text-prontopsy-blue">
            {data.value}
          </div>
        )}
        {data.name && (
          <div className="font-UnisonPro font-bold text-prontopsy-pink italic uppercase text-base md:text-lg xl:text-[20px] !leading-[1.5rem]">
            {data?.name?.[language]}
          </div>
        )}
      </div>
    </>
  );
};

export default StatisticsItem;
