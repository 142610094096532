import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import { toast } from "react-toastify";
import Modal from "../components/elements/Modal";
import Button from "../components/form/Button";

const CheckProfile = ({ children }) => {
  const profile = useSelector((state) => state?.profileSlice?.data);
  const check = profile?.completeProfile;
  const user = JSON.parse(localStorage.getItem("userDetails"));
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate(); // Initialize useNavigate

  useEffect(() => {
    if (
      user &&
      check &&
      user?.roleCode === "user" &&
      (!check?.isPersonalComplete ||
        !check?.isBillingComplete ||
        !check?.isLanguageComplete)
    ) {
      setIsOpen(true);
    } else if (
      user &&
      check &&
      user?.roleCode === "consultant" &&
      (!check?.isMemberComplete ||
        !check?.isPersonalComplete ||
        !check?.isBillingComplete ||
        !check?.isInsuranceComplete ||
        !check?.isServiceComplete ||
        !check?.isDailyScheduleComplete ||
        !check?.isSpecializationComplete ||
        !check?.isLanguageComplete)
    ) {
      setIsOpen(true);
    }
  }, [user, check]);

  const handleOkClick = () => {
    setIsOpen(false); // Close the modal
    navigate("/profile"); // Redirect to /profile
  };

  if (isOpen) {
    return (
      <Modal isOpen={isOpen} titleShow={false} bodyClass="!w-[250px]">
        <div className="flex flex-col items-center">
          <h2 className="text-2xl text-center font-bold">Confirmation Alert</h2>
          <p className="text-sm text-center">Complete Your Profile Info</p>
          <Button
            buttonLabel={"Ok"}
            buttonClasses={"mt-4"}
            buttonFunction={handleOkClick} // Use the handleOkClick function
          />
        </div>
      </Modal>
    );
  }

  return children;
};

export default CheckProfile;
