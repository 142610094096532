import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import Image from "../components/elements/Image";
import Button from "../components/form/Button";
import NavigationPanel from "./NavigationPanel";
import Logo from "../assets/images/logo.png";
import { classNames } from "../helpers/classNames";
import { toast } from "react-toastify";
import LogoutModal from "../components/elements/LogoutModal";
import { useDispatch, useSelector } from "react-redux";
import { setAuthData } from "../store/slice/authSlice";
import { fetchLanguageList } from "../service/languageService";
import { setLanguages } from "../store/slice/languageSlice";
import { sideBar } from "../components/elements/language";
import { Listbox } from "@headlessui/react";
import ukFlag from "../assets/images/flag/uk.png";
import spainFlag from "../assets/images/flag/spain.png";
import italyFlag from "../assets/images/flag/italy.png";
import { getProfileDetails } from "../service/authService";
import { setInitialProfileData } from "../store/slice/initialProfileSlice";
import { JoinCallModal } from "../components/elements/JoinCallModal";
import { JoinChatModal } from "../components/elements/JoinChatModal";
import { setCallerData } from "../store/slice/callingSlice";
import { useSocket } from "../components/socket/SocketProvider";
import music from "../assets/music/ringtone.mp3";
import { setConsultantStatus } from "../store/slice/consultantStatusSlice";
import useBatteryStatus from "../components/elements/hooks/useBatteryStatus";
import LanguageDropdown from "../components/elements/LanguageDropdown";
// import usePageVisibility from "../util/PageVisibility";
// import SpeedTest from "@cloudflare/speedtest";

const DefaultHeader = ({ headerClass }) => {
  const [loaded, setLoaded] = useState(true);
  const token = localStorage.getItem("auth_token");
  const localProfileData = JSON.parse(localStorage.getItem("userDetails"));
  const [scrolled, setScrolled] = useState(false);
  const [menuToggle, setMenuToggle] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [languageList, setLanguageList] = useState([]);
  const language = useSelector((state) => state?.languageSlice?.data);
  const profile = useSelector((state) => state?.initialProfileSlice?.data);
  const [isOpen, setIsOpen] = useState(false);
  const [isChatModalOpen, setIsChatModalOpen] = useState(false);
  const callerData = useSelector((state) => state?.callingSlice?.data);
  const dispatch = useDispatch();
  const [sessionId, setSessionId] = useState(null);
  const [sessionToken, setSessionToken] = useState(null);
  const audioRef = useRef(new Audio(music));
  const socket = useSocket();
  const [busyConsultants, setBusyConsultants] = useState([]);
  const { level, charging } = useBatteryStatus();
  const [appointment, setAppointment] = useState(null);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const handleLogOut = () => {
    if (socket) {
      profile &&
        profile?.onlineStatus == "online" &&
        socket.emit("userOffline", profile?._id);
      localStorage.removeItem("appointmentData");
    } else if (!socket) {
      toast.error("Network error. Please reload your browser once!");
      window.location.reload();
    }
    dispatch(setAuthData({}));
    localStorage.removeItem("auth_token");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("userDetails");
    toast.success("You are logged out!");
    setLoaded(false);
    setTimeout(() => {
      // navigate("/");
      setShowModal(false);
      setLoaded(true);
    }, 500);
  };

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    token &&
      getProfileDetails(token).then((response) => {
        if (response?.data?.status !== 200) {
          handleLogOut();
        }
      });
    dispatch(setInitialProfileData(localProfileData));
  }, [token]);

  const profileData = {
    image: profile?.image?.url,
    userName: (profile?.firstName || "") + " " + (profile?.lastName || ""),
    userEmail: profile?.email,
  };

  const menuData = [
    {
      _id: 1,
      name: sideBar?.Home_Label?.[language],
      link: "/",
      click: () => setMenuToggle(false),
    },
    {
      _id: 2,
      name: sideBar?.Find_a_Psychologist_Label?.[language],
      link: "/find-psychologist",
      click: () => setMenuToggle(false),
    },
    {
      _id: 3,
      name: sideBar?.How_It_Works_Label?.[language],
      link: "/howitworks",
      click: () => setMenuToggle(false),
    },
    // {
    //   _id: 4,
    //   name: sideBar?.Community_Psychology_Label?.[language],
    //   link: "/community-psychology",
    //   click: () => setMenuToggle(false),
    // },
    {
      _id: 5,
      name: sideBar?.Area_of_Intervention_Label?.[language],
      link: "/area-of-intervention",
      click: () => setMenuToggle(false),
    },
    {
      _id: 6,
      name: sideBar?.About_us_Label?.[language],
      link: "/about",
      click: () => setMenuToggle(false),
    },
    {
      _id: 7,
      name: sideBar?.Frequently_Asked_Questions_Label?.[language],
      link: "/frequently-asked-questions",
      click: () => setMenuToggle(false),
    },
    {
      _id: 8,
      name: sideBar?.Blog_Label?.[language],
      link: "/blog",
      click: () => setMenuToggle(false),
    },
    {
      _id: 9,
      name: sideBar?.Leave_a_Feedback_Label?.[language],
      link: "/feedback",
      click: () => setMenuToggle(false),
    },
    {
      _id: 10,
      name: sideBar?.Book_an_Interview_dial_Label?.[language],
      link: "/book-an-interview",
      click: () => setMenuToggle(false),
    },
    // {
    //   _id: 11,
    //   name: sideBar?.Public_Front_Consultant_profile_Label?.[language],
    //   link: "/public-front-consultant-profile",
    //   click: () => setMenuToggle(false),
    // },
    {
      _id: 12,
      name: sideBar?.Contact_us_Label?.[language],
      link: "/contact-us",
      click: () => setMenuToggle(false),
    },
  ];
  const navigate = useNavigate();
  const handleScroll = () => {
    if (menuToggle) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  };

  useEffect(() => {
    function handleScroll() {
      const isScrolled = window.scrollY > 100;
      setScrolled(isScrolled);
    }
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    fetchLanguageList(setLanguageList, setLoaded);
  }, []);

  useEffect(() => {
    handleScroll();
  }, [menuToggle]);

  if (!token) {
    dispatch(setAuthData({}));
    localStorage.removeItem("auth_token");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("userDetails");
  }

  const closeModal = () => setShowModal(false);

  useEffect(() => {
    if (isOpen || isChatModalOpen) {
      audioRef?.current
        .play()
        .catch((err) => console.error("Error playing audio:", err));
    } else if (!isOpen || !isChatModalOpen) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
  }, [isOpen, isChatModalOpen]);

  useEffect(() => {
    dispatch(setConsultantStatus(busyConsultants));
  }, [busyConsultants]);

  useSocket("consultantCallResponse", async (data) => {
    dispatch(setCallerData(data));
    if (localProfileData && localProfileData._id === data.consultant.id) {
      setIsOpen(true);
    }
  });

  useSocket("chatSessionResponse", async (data) => {
    dispatch(setCallerData(data));
    if (localProfileData && localProfileData?._id === data?.consultantId) {
      setIsChatModalOpen(true);
      localStorage.setItem("appointmentData", JSON.stringify(data));
      setAppointment(data);
    }
  });
  useSocket(
    "chatJoinConfirmResponse",
    async (data) => {
      if (data?.joined === true) {
        setIsChatModalOpen(false);
      }
    },
    []
  );
  useSocket("chatSessionRejectResponse", async (data) => {
    if (data?.disconnect === true) {
      setIsChatModalOpen(false);
    }
  });

  useSocket("vonageTokBoxSessionResponse", (data) => {
    setSessionId(data?.sessionId);
    setSessionToken(data?.token);
    setAppointment(data);
    localStorage.setItem("appointmentData", JSON.stringify(data));
  });

  useSocket("consultantBusyResponse", (data) => {
    setBusyConsultants((prevConsultants) => {
      // Create a map from the existing consultants
      const consultantMap = new Map(
        prevConsultants.map((consultant) => [
          consultant.consultantId,
          consultant,
        ])
      );

      // Update or add the new data
      consultantMap.set(data.consultantId, data);

      // Convert the map back to an array
      return Array.from(consultantMap.values());
    });
  });

  // usePageVisibility(token, socket, profile?._id);

  //=========================================================================

  return (
    <>
      <header
        className={classNames(
          "fixed top-0 left-0 w-full z-10 bg-transparent flex items-center transition-all duration-200",
          scrolled ? "md:h-24 h-20 bg-white shadow-md" : "md:h-32 h-20",
          headerClass
        )}
      >
        {/* <ReactInternetSpeedMeter
          customClassName={null}
          pingInterval={3000} // milliseconds
          thresholdUnit="megabyte" // "byte" , "kilobyte", "megabyte"
          // threshold={100}
          imageUrl="https://www.sammobile.com/wp-content/uploads/2019/03/keyguard_default_wallpaper_silver.png"
          downloadSize="2550420" //bytes
          callbackFunctionOnNetworkTest={(speed) => console.log(speed, "speed")}
        /> */}
        <div className="w-full px-4 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-8xl mx-auto flex items-center">
          <div
            className={classNames(
              "relative transition-all duration-200",
              scrolled ? "h-10 md:h-12 lg:h-16" : "h-10 md:h-20 lg:h-24"
            )}
          >
            <Link to={"/"} className="flex w-full h-full">
              <Image
                src={Logo}
                alt={"Prontopsy Logo"}
                effect={"blur"}
                className={"object-contain"}
              />
            </Link>
          </div>
          <div className="flex ml-auto space-x-3">
            <div className="relative">
              <LanguageDropdown
                language={language}
                setLanguages={setLanguages}
                languageList={languageList}
                ukFlag={ukFlag}
                spainFlag={spainFlag}
                italyFlag={italyFlag}
                dropdownClasses={screenWidth < 480 ? "min-w-40" : "min-w-40"}
              />
            </div>
            {!token && (
              <Button
                buttonHasLink={true}
                buttonLink={"/signin"}
                buttonLabel={"Signin"}
                buttonLabelClasses={"md:block hidden"}
                buttonIcon={"fa-light fa-user-plus"}
                buttonIconPosition={"left"}
                buttonClasses={"uppercase text-sm hover:!bg-prontopsy-blue"}
              />
            )}

            <Button
              buttonHasLink={false}
              buttonIcon={"fa-solid fa-bars-staggered"}
              buttonIconPosition={"left"}
              buttonClasses={
                "!px-0 text-xl aspect-square !rounded-full !bg-prontopsy-yellow !text-black hover:!bg-prontopsy-blue hover:!text-prontopsy-yellow"
              }
              buttonFunction={() => setMenuToggle(true)}
            />
          </div>
          {token && (
            <div className="flex items-center space-x-2 ml-2">
              <div
                className="w-16 h-16 rounded-full overflow-hidden"
                onClick={() => navigate("/profile")}
              >
                <Image
                  src={profileData && profileData?.image}
                  alt={""}
                  effect={"blur"}
                  className={"object-cover w-full h-full cursor-pointer"}
                />
              </div>
              <div
                className="text-sm  font-semibold text-prontopsy-blue hidden md:block cursor-pointer"
                onClick={() => navigate("/profile")}
              >
                {profileData.userName ? profileData?.userName : ""}
              </div>

              <div className="relative !ml-0">
                <Button
                  buttonIcon={"fa-solid fa-bell"}
                  buttonIconPosition={"left"}
                  buttonClasses={
                    "!px-0 !text-2xl aspect-square !text-prontopsy-blue !bg-transparent"
                  }
                />
              </div>
            </div>
          )}
        </div>
      </header>

      <NavigationPanel
        data={menuData}
        toggle={menuToggle}
        setToggle={setMenuToggle}
      />
      <LogoutModal
        show={showModal}
        onClose={closeModal}
        onConfirm={handleLogOut}
        loaded={loaded}
      />

      <JoinCallModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        data={callerData?.user}
        callerData={callerData}
        sessionId={sessionId}
        sessionToken={sessionToken}
        audioRef={audioRef}
      />
      <JoinChatModal
        isOpen={isChatModalOpen}
        setIsOpen={setIsChatModalOpen}
        appointmentData={appointment}
        callerData={callerData}
        audioRef={audioRef}
      />
    </>
  );
};

export default DefaultHeader;
