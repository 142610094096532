import React from "react";
import Image from "../../elements/Image";
import img from "../../../../src/assets/images/aboutgroup.webp";

const AboutSectionAbout = () => {
  return (
    <>
      <section className="relative py-10 md:py-12 lg:py-16 xl:py-20 flex items-center">
        <div className="w-full px-4 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-8xl mx-auto flex items-center">
          <div className="block xl:grid grid-cols-12 gap-10">
            <div className="relative xl:col-span-5">
              <h2 className="text-2xl lg:text-3xl xl:text-4xl 2xl:text-[45px] text-prontopsy-blue font-UnisonPro italic font-bold !leading-[1.1]">
                What is <span className="text-prontopsy-pink">Prontopsy</span>
              </h2>

              <div className="text-sm lg:text-[17px] text-neutral-700 space-y-4 mt-6 !leading-[1.4rem]">
                <p>We are a Global Psychological Consultation Portal</p>
                <p>
                  We are a Global Psychological Consultation Portal Whether it’s
                  early in the morning or late at night, with Prontopsy you are
                  always connected to expert Psychologists and Psychotherapists
                  from all over the world at a day and time that is convenient
                  for you. You will be the one who decides how long to stay and
                  how much to spend. With over 26 areas of intervention to
                  choose from, you can easily find the expert specializing in
                  your exact need. Over thousand satisfied clients have already
                  awarded our Psychologists and Psychotherapists with top rating
                  and reviews for providing them with first-rate service.
                </p>
              </div>
            </div>
            <div className="relative xl:col-span-7">
              <div className="w-full flex relative aspect-[2/1]">
                <Image
                  src={img}
                  alt={""}
                  effect={"blur"}
                  className={"object-contain w-full h-full"}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutSectionAbout;
