import React, { useEffect, useState } from "react";
import { setTitle } from "../helpers/MetaTag";
import PageBanner from "../components/sections/PageBanner";
import ImageBanner from "../assets/images/punlicfront.webp";
import SmartPortal from "../components/sections/SmartPortal";
import Register from "../components/sections/Register";
import ConsultantDetailsContent from "../components/sections/consultantDetails/ConsultantDetailsContent";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchConsultantDetails } from "../service/consultantService";
const ConsultantDetails = ({ userId }) => {
  const [bannerInfo, setbannerInfo] = useState({});
  setTitle("Prontopsy | Public Front Consultant profile");
  const data = useSelector((state) => state?.pageSlice?.data);
  const language = useSelector((state) => state?.languageSlice?.data);
  const token = localStorage.getItem("auth_token");
  let { id } = useParams();
  const [details, setDetails] = useState({});
  const [loaded, setLoaded] = useState(true);

  useEffect(() => {
    let info =
      data.length > 0 &&
      data.filter((elm) => elm?.title?.en == "Public_front_consultant_profile");
    setbannerInfo(info[0]);
  }, [data]);

  useEffect(() => {
    fetchConsultantDetails(
      token,
      { id: userId ? userId : id },
      setDetails,
      setLoaded
    );
  }, []);

  const bannerData = {
    image:
      bannerInfo &&
      bannerInfo?.banner &&
      bannerInfo?.banner.length > 0 &&
      bannerInfo?.banner[0]?.image?.url,
    title:
      bannerInfo &&
      bannerInfo?.banner &&
      bannerInfo?.banner.length > 0 &&
      bannerInfo?.banner[0]?.title?.[language],
  };

  // const bannerData = {
  //   image: ImageBanner,
  //   title: "Public Front<br/>Consultant<br/>profile",
  // }
  return (
    <>
      <PageBanner bannerData={bannerData} />
      <ConsultantDetailsContent data={details} loaded={loaded} />
      <SmartPortal />
      <Register />
    </>
  );
};

export default ConsultantDetails;
