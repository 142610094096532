import React from 'react';
import TableFilter from './TableFilter';
import TableSort from './TableSort';
import { classNames } from "../../../helpers/classNames";

const Th = ({
  name,
  nameAlign,
  isSort,
  isFilter,
  labelClass,
  ...props
}) => {

  const align = {
    left: "justify-start",
    right: "justify-end",
    center: "justify-center",
    "": "justify-start",
  }

  return (
    <>
      <th className="py-4 px-2 text-left max-w-[240px]">
        <div className={classNames("flex items-center w-full", align[nameAlign])}>
          <div className={classNames("text-[11px] uppercase font-normal text-slate-400", labelClass)}>{name}</div>
          {isSort && isFilter &&
            <div className="ml-auto flex justify-end items-center">
              {isSort &&
                <TableSort sortState={'nostate'} />
              }
              {isFilter &&
                <TableFilter xPlacement={'right'} />
              }
            </div>
          }
        </div>
      </th>
    </>
  );
};

export default Th;