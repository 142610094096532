import React from "react";
import Button from "../form/Button";
import { useSelector } from "react-redux";

const Register = () => {
  const data = useSelector((state) => state.pageSlice.data);
  const language = useSelector((state) => state?.languageSlice?.data);
  const loginData = useSelector((state) => state.authSlice.data);
  const token = localStorage.getItem("auth_token");
  const info =
    data &&
    Array.isArray(data) &&
    data.length > 0 &&
    data.filter((elm) => elm.title.en === "signup");
  const registerData = {
    title: info[0]?.subTitle?.[language],
    subtitle: info[0]?.description?.[language],
    action: {
      link: "/signup-consultant",
      label: info[0]?.banner[0]?.buttonText?.[language],
    },
  };


  return (
    <>
      <section className="relative py-10 md:py-12 lg:py-16 xl:py-20 2xl:py-24 flex items-center">
        <div className="w-full px-4 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-8xl mx-auto">
          <div className="w-full mx-auto max-w-6xl text-center">
            {registerData?.title && (
              <h2
                className="text-2xl lg:text-3xl xl:text-4xl 2xl:text-[45px] text-prontopsy-blue font-UnisonPro italic font-bold !leading-[1.1]"
                dangerouslySetInnerHTML={{ __html: registerData?.title }}
              ></h2>
            )}
            {registerData?.subtitle && (
              <div
                className="text-sm sm:text-base xl:text-[17px] text-neutral-700 space-y-4 mt-6 max-w-6xl mx-auto"
                dangerouslySetInnerHTML={{ __html: registerData?.subtitle }}
              ></div>
            )}
          </div>
          {registerData?.action && !token && (
            <div className="flex items-center justify-center mt-8 md:mt-12 lg:mt-12">
              <Button
                buttonHasLink={true}
                buttonLink={registerData?.action?.link}
                buttonLabel={registerData?.action?.label}
                buttonClasses={"!h-10 lg:!h-12 hover:!bg-prontopsy-blue !px-12"}
                buttonLabelClasses={"!text-[13px]"}
              />
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default Register;
