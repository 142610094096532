import React, { useEffect, useState } from "react";
import Image from "../../../Image";
import User from "../../../../../assets/images/user-03.webp";
import Button from "../../../../form/Button";
import InputGroup from "../../../../form/Input";
import Select from "../../../../form/Select";
import Checkbox from "../../../../form/Checkbox";
import MultiSelect from "../../../../form/MultiSelect";
import { useDispatch, useSelector } from "react-redux";
import { fetchServicesList } from "../../../../../service/servicesService";
import { fetchModeList } from "../../../../../service/modeService";
import {
  fetchCountryList,
  updateProfileService,
} from "../../../../../service/profileService";
import { setProfileData } from "../../../../../store/slice/profileSlice";
import { toast } from "react-toastify";
import {
  addBtn,
  commonFormLabels,
  deleteBtn,
  saveChange,
} from "../../../language";
import { fetchBookingTypes } from "../../../../../service/bookingType";

const ServiceOffered = ({ data, dataLoaded }) => {
  const [initialData, setInitialData] = useState([]);
  const [loaded, setLoaded] = useState(true);
  const dispatch = useDispatch();
  const role = JSON.parse(localStorage.getItem("userDetails"))?.roleCode;
  const id = JSON.parse(localStorage.getItem("userDetails"))?._id;
  const language = useSelector((state) => state?.languageSlice?.data);
  const profileData = useSelector((state) => state?.profileSlice?.data);
  const [serviceList, setServiceList] = useState([]);
  const [serviceTemp, setServiceTemp] = useState([]);
  const [modeList, setModeList] = useState([]);
  const [formData, setFormData] = useState({});
  const [countryList, setCountryList] = useState([]);
  const [currency, setCurrency] = useState({
    value: data?.currencyData?._id,
    name: data?.currencyData?.currency,
  });

  const [initialServiceObject, setInitialServiceObject] = useState({
    service: "",
    mode: [],
    price: "",
    _id: Math.random(100),
  });

  useEffect(() => {
    let info =
      profileData &&
      profileData?.serviceData &&
      Array.isArray(profileData?.serviceData) &&
      profileData?.serviceData.map((elm, index) => ({
        service: { name: elm?.title?.[language], value: elm?._id },
        mode:
          elm?.modes &&
          elm?.modes.map((ele) => ({
            name: ele?.title?.[language],
            value: ele?._id,
          })),
        price: elm?.price,
        _id: elm?._id,
      }));
    setInitialData(info);

    setCurrency({
      value: profileData?.currencyData?._id,
      name: profileData?.currencyData?.currency,
    });
    let serviceId =
      profileData &&
      profileData?.serviceData &&
      Array.isArray(profileData?.serviceData) &&
      profileData?.serviceData.map((item) => ({
        name: item?.title?.[language],
        value: item?._id,
      }));
    setServiceTemp(serviceId);
    let modes =
      profileData &&
      profileData?.serviceData &&
      Array.isArray(profileData?.serviceData) &&
      profileData?.serviceData.map((elm) => ({
        name: elm?.title?.[language],
        value: elm?._id,
      }));
  }, [profileData]);

  useEffect(() => {
    fetchCountryList(setCountryList);
    fetchBookingTypes(setServiceList);
    fetchModeList(setModeList);
  }, []);

  const handleSelect = (items, index) => {
    const updatedData = [...initialData];
    updatedData[index].mode = items.map((item) => ({
      name: item.name,
      value: item.value,
    }));
    setInitialData(updatedData);
  };

  const handleRemove = (items, index) => {
    const updatedData = [...initialData];
    updatedData[index].mode = items.map((item) => ({
      name: item.name,
      value: item.value,
    }));
    setInitialData(updatedData);
  };

  const handleServiceSelect = (val, index) => {
    const updatedData = [...initialData];
    updatedData[index].service = {
      name: val?.name,
      value: val?.value,
    };
    setInitialData(updatedData);
  };

  const handlePriceInput = (e, index) => {
    const updatedData = [...initialData];
    updatedData[index].price = Number(e.target.value);
    setInitialData(updatedData);
  };

  const serviceData =
    serviceList.length > 0 &&
    serviceList.map((elm) => ({
      value: elm._id,
      name: elm?.title?.[language],
    }));

  const modeData =
    modeList.length > 0 &&
    modeList.map((elm) => ({
      value: elm._id,
      name: elm?.title?.[language],
    }));

  const currencyData =
    countryList.length > 0 &&
    countryList.map((elm) => ({
      value: elm._id,
      name: elm?.currency,
    }));

  const ServiceOffered = {
    title: {
      en: "Service Offered",
      es: "Servicio ofrecido",
      it: "Service Offered",
    },
    titleAlt: "Change Password",
    image: User,
  };

  const handleAddMore = () => {
    const newData = {
      ...initialServiceObject,
      _id: Math.random(100),
    };

    setInitialData((pre) => [...pre, newData]);
  };

  const handleRemoveService = (id) => {
    let filtered = initialData.filter((data) => data._id !== id);
    setInitialData(filtered);
  };

  //Formatting final data
  useEffect(() => {
    let object = {};
    object["currency"] = currency?.value;
    initialData &&
      initialData.length > 0 &&
      initialData.forEach((elm, index) => {
        object[`service[${index}][service]`] = elm?.service?.value;
        object[`service[${index}][price]`] = elm?.price;
        elm.mode.forEach((ele, idx) => {
          object[`service[${index}][mode][${idx}]`] = ele?.value;
        });
      });
    setFormData(object);
  }, [initialData, currency]);

  const handleSubmit = () => {
    if (!formData?.currency) {
      toast.info("Currency is required!");
      return;
    }

    //api call to update data
    setLoaded(false);
    updateProfileService({
      ...formData,
      roleCode: role,
      id: id,
    }).then((res) => {
      setLoaded(true);
      if (res.data.status == 200) {
        toast.success(res?.data?.message);
        dispatch(setProfileData(res.data.data));
      } else {
        toast.error(res?.data?.message);
      }
    });
  };

  return (
    <div className="relative">
      <div className="relative w-full border-t border-dashed border-neutral-300 py-4">
        {ServiceOffered?.title && (
          <h2
            className="text-base text-prontopsy-blue font-RobotoSlab font-bold"
            dangerouslySetInnerHTML={{
              __html: ServiceOffered?.title?.[language],
            }}
          ></h2>
        )}
      </div>
      {/*------------ Currency -------------- */}
      <div className="w-full col-span-12 mb-2">
        <Select
          label={`*${commonFormLabels?.currency?.[language]}`}
          placeholder={"Select currency.."}
          labelClasses={
            "!uppercase !text-prontopsy-blue !text-[10px] font-RobotoSlab"
          }
          dropdownButtonClass={"!h-12 !bg-transparent"}
          isSearch={true}
          dropdownData={currencyData}
          dropdownClass={"w-full"}
          selectedValue={currency}
          handleSelect={(val) => {
            setCurrency(val);
          }}
        />
      </div>
      <hr />

      <div className="max-h-[61vh] overflow-auto p-2">
        {initialData &&
          Array.isArray(initialData) &&
          initialData.map((elm, index) => (
            <div
              key={elm?._id}
              className="p-2 mt-2 border border-neutral-300 rounded-md"
            >
              <div className="w-full">
                <div className="grid grid-cols-12 gap-3 mb-4">
                  <div className="w-full col-span-12">
                    <Select
                      label={`*${commonFormLabels?.service?.[language]}`}
                      placeholder="Select Service.."
                      labelClasses={
                        "!uppercase !text-prontopsy-blue !text-[10px] font-RobotoSlab"
                      }
                      dropdownButtonClass={"!h-12 !bg-transparent"}
                      isSearch={true}
                      dropdownData={serviceData}
                      dropdownClass={"w-full"}
                      selectedValue={elm?.service}
                      handleSelect={(val) => handleServiceSelect(val, index)}
                    />
                  </div>

                  <div className="w-full col-span-6">
                    <MultiSelect
                      label={`*${commonFormLabels?.mode?.[language]}`}
                      placeholder="Select Modes.."
                      dropdownData={modeData}
                      selectedValues={elm?.mode}
                      handleSelect={(items) => handleSelect(items, index)}
                      handleRemove={(items) => handleRemove(items, index)}
                      isSearch={true}
                      loaded={true}
                      labelClasses={
                        "!uppercase !text-prontopsy-blue !text-[10px] font-RobotoSlab"
                      }
                      dropdownButtonClass={"!h-12 !bg-transparent"}
                      dropdownClass={"w-full"}
                    />
                  </div>

                  <div className="w-full col-span-6">
                    <InputGroup
                      inputType={"Number"}
                      label={`*${commonFormLabels?.priceMnt?.[language]}`}
                      inputValue={elm.price}
                      labelClasses={
                        "!uppercase !text-prontopsy-blue !text-[10px] font-RobotoSlab"
                      }
                      inputClasses={"!bg-transparent !h-12"}
                      onChange={(e) => handlePriceInput(e, index)}
                    />
                  </div>
                </div>
              </div>
              <div className="mb-2 flex justify-end ">
                <Button
                  buttonLabel={deleteBtn?.[language]}
                  buttonLabelClasses={
                    "!uppercase !text-base !whitespace-nowrap !font-medium"
                  }
                  buttonClasses={
                    "!rounded-xl !px-4 !h-12 !text-prontopsy-pink !text-white !bg-gradient-to-r !from-prontopsy-blue !to-prontopsy-pink !hover:bg-prontopsy-pink !duration-300"
                  }
                  buttonFunction={() => handleRemoveService(elm?._id)}
                />
              </div>
            </div>
          ))}
      </div>
      <hr />
      <div className="mt-3 mb-3">
        <Button
          buttonLabel={addBtn?.[language]}
          buttonLabelClasses={
            "!uppercase !text-base !whitespace-nowrap !font-medium"
          }
          buttonClasses={
            "!rounded-xl !px-4 !h-12 !text-prontopsy-pink !text-white !bg-gradient-to-r !from-prontopsy-blue !to-prontopsy-pink !hover:bg-prontopsy-pink !duration-300"
          }
          buttonFunction={handleAddMore}
          isDisable={
            initialData && Array.isArray(initialData) && initialData.length >= 2
          }
        />
      </div>
      <hr />
      <div className="relative flex items-center gap-2 mt-5">
        <Button
          buttonLabel={saveChange?.[language]}
          buttonLabelClasses={
            "!uppercase !text-base !whitespace-nowrap !font-medium"
          }
          buttonClasses={
            "!rounded-xl !px-4 !h-12 !text-prontopsy-pink !text-white !bg-gradient-to-r !from-prontopsy-blue !to-prontopsy-pink !hover:bg-prontopsy-pink !duration-300"
          }
          buttonFunction={handleSubmit}
          isDisable={!loaded}
        />
      </div>
    </div>
  );
};

export default ServiceOffered;
