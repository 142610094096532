import React, { useEffect, useState } from "react";
import { setTitle } from "../helpers/MetaTag";
import PageBanner from "../components/sections/PageBanner";
import ImageBanner from "../assets/images/aboutbanner.webp";
import SmartPortal from "../components/sections/SmartPortal";
import Register from "../components/sections/Register";
import AboutSection1 from "../components/sections/about/aboutSection1";
import AboutSection2 from "../components/sections/about/aboutSection2";
import FeatureSlider from "../components/sections/home/FeatureSlider";
import { useSelector } from "react-redux";
import AboutSectionAbout from "../components/sections/about/AboutSectionAbout";
const About = () => {
  const [bannerInfo, setbannerInfo] = useState({});
  setTitle("Prontopsy | About Us");
  const data = useSelector((state) => state?.pageSlice?.data);
  const language = useSelector((state) => state?.languageSlice?.data);
  useEffect(() => {
    let info =
      data.length > 0 && data.filter((elm) => elm?.title?.en == "About");
    setbannerInfo(info[0]);
  }, [data]);
  const bannerData = {
    image:
      bannerInfo &&
      bannerInfo?.banner &&
      bannerInfo?.banner.length > 0 &&
      bannerInfo?.banner[0]?.image?.url,
    title:
      bannerInfo &&
      bannerInfo?.banner &&
      bannerInfo?.banner.length > 0 &&
      bannerInfo?.banner[0]?.title?.[language],
  };
  return (
    <>
      <PageBanner bannerData={bannerData} />
      <AboutSection1 />
      <AboutSection2 />
      <div className="lg:-mt-64 -mt-40">
        <FeatureSlider />
      </div>
      <AboutSectionAbout />
      <SmartPortal />
      <Register />
    </>
  );
};

export default About;
