import React, { useEffect, useState } from "react";
import { setTitle } from "../helpers/MetaTag";
import ImageBanner from "../assets/images/bookinterview.webp";
import PageBanner from "../components/sections/PageBanner";
import SmartPortal from "../components/sections/SmartPortal";
import Register from "../components/sections/Register";
import BookAnInterview from "../components/sections/bookinterview/BookAnInterview";
import { useSelector } from "react-redux";

const BookInterview = () => {
  const [bannerInfo, setbannerInfo] = useState({});

  setTitle("Prontopsy | Book An Interview");
  const data = useSelector((state) => state?.pageSlice?.data);
  const language = useSelector((state) => state?.languageSlice?.data);

  useEffect(() => {
    let info = data.length > 0 && data.filter((elm) => elm?.title?.en == "Book_an_interview_dial");
    setbannerInfo(info[0]);
  }, [data]);

  const bannerData = {
    image: bannerInfo && bannerInfo?.banner && bannerInfo?.banner.length > 0 && bannerInfo?.banner[0]?.image?.url,
    title:
      bannerInfo && bannerInfo?.banner && bannerInfo?.banner.length > 0 && bannerInfo?.banner[0]?.title?.[language],
  };

  // const bannerData = {
  //   image: ImageBanner,
  //   title: "Book<br/>An<br/> Interview<br/>Dial",
  // }
  return (
    <>
      <PageBanner bannerData={bannerData} />
      <BookAnInterview bannerTitle={bannerData?.title} language={language} />
      <SmartPortal />
      <Register />
    </>
  );
};

export default BookInterview;
