import { Listbox } from "@headlessui/react";
import React from "react";
import { useDispatch } from "react-redux";
import { classNames } from "../../helpers/classNames";

const LanguageDropdown = ({
  language,
  setLanguages,
  languageList,
  ukFlag,
  spainFlag,
  italyFlag,
  dropdownClasses,
}) => {
  const dispatch = useDispatch();
  return (
    <div>
      <Listbox
        value={language}
        onChange={(value) => dispatch(setLanguages(value))}
      >
        {({ open }) => (
          <>
            <Listbox.Button className="border border-gray-300 rounded-md px-3 py-2 w-full flex items-center bg-white">
              <div className="relative w-6 h-6 rounded-full overflow-hidden md:mr-2 mr-0 flex-shrink-0">
                <img
                  src={
                    language === "en"
                      ? ukFlag
                      : language === "es"
                      ? spainFlag
                      : italyFlag
                  }
                  alt="Selected Language"
                  className="w-full h-full object-cover"
                />
              </div>
              <div className="flex-shrink flex-grow md:block hidden">
                {languageList.find((item) => item.code === language)?.name ||
                  "Select Language"}
              </div>
            </Listbox.Button>
            <Listbox.Options
              className={classNames(
                "absolute mt-0 bg-white border border-gray-300 rounded-md shadow-lg z-10 w-full overflow-hidden text-center",
                dropdownClasses
              )}
            >
              {languageList.map((item) => (
                <Listbox.Option
                  key={item.code}
                  value={item.code}
                  className={({ active }) =>
                    `cursor-pointer select-none relative py-2 px-4 mx-auto ${
                      active ? "bg-prontopsy-pink text-white" : "text-gray-900"
                    }`
                  }
                >
                  {({ selected }) => (
                    <>
                      <div className="flex items-center">
                        <div className="flex-shrink-0">
                          <img
                            src={
                              item?.code === "en"
                                ? ukFlag
                                : item.code === "es"
                                ? spainFlag
                                : italyFlag
                            }
                            alt={`${item.name} Flag`}
                            className="w-6 h-6 mr-2 rounded"
                          />
                        </div>
                        <span
                          className={`flex-shrink flex-grow text-left ${
                            selected ? "font-semibold" : "font-normal"
                          }`}
                        >
                          {item.name}
                        </span>
                        {selected ? (
                          <span className="absolute right-0 top-0 bottom-0 px-4 py-2"></span>
                        ) : null}
                      </div>
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </>
        )}
      </Listbox>
    </div>
  );
};

export default LanguageDropdown;
