import React from "react";
import UserVideoOne from "./UserVideoOne";
import UserVideoTwo from "./UserVideoTwo";

const UserVideo = ({ isAudioEnabled, data }) => {
  return (
    <div className="flex space-x-4 px-20 py-14 min-h-screen flex-shrink flex-grow">
      {/* <UserVideoTwo /> */}
      <UserVideoOne isAudioEnabled={isAudioEnabled} data={data} />
    </div>
  );
};

export default UserVideo;
