import React, { useEffect, useState, useRef } from "react";
import { setTitle } from "../helpers/MetaTag";
import PageBanner from "../components/sections/PageBanner";
import ImageBanner from "../assets/images/howitworksbanner.webp";
import JoinOurCommunity from "../components/sections/howItWorks/JoinOurCommunity";
import Work from "../components/sections/howItWorks/Work";
import SmartPortal from "../components/sections/SmartPortal";
import Register from "../components/sections/Register";
import { useSelector } from "react-redux";

const HowItWorks = () => {
  const [bannerInfo, setbannerInfo] = useState({});
  const [roleData, setRoleData] = useState({});
  const language = useSelector((state) => state?.languageSlice?.data);
  setTitle("Prontopsy | How It Works");
  const ref = useRef();

  const data = useSelector((state) => state?.pageSlice?.data);

  useEffect(() => {
    let info =
      data.length > 0 &&
      data.filter((elm) => elm?.title?.en === "How_it_works");
    setbannerInfo(info[0]);
  }, [data]);

  const bannerData = {
    image: bannerInfo?.banner?.[0]?.image?.url,
    title: bannerInfo?.banner?.[0]?.title?.[language],
  };

  return (
    <>
      <PageBanner bannerData={bannerData} />
      <JoinOurCommunity
      // setData={setRoleData}
      // roledata={roleData}
      // scrollDown={handleClick}
      />
      <Work role={roleData} ref={ref} />
      <SmartPortal />
      <Register />
    </>
  );
};

export default HowItWorks;
