import React from "react";
import PageBanner from "../components/sections/PageBanner";
import ImageBanner from "../assets/images/banner.webp";
// import Button from "../components/form/Button";
import SmartPortal from "../components/sections/SmartPortal";
import Register from "../components/sections/Register";
import PrivacyPolicy from "../components/sections/privacypolicy/PrivacyPolicy";
import Button from "../components/form/Button";
import Registeredpsychologist from "../components/sections/registeredpsychologist/Registeredpsychologist";

const TermsandConditions = () => {
  const bannerData = {
    image: ImageBanner,
    // image: "",
    title:
      "General<br/><span class='text-prontopsy-pink'>Terms and</span><br>Conditions",
  };

  const registerData = {
    title: "ARE YOU A <br><span class='text-prontopsy-pink'>REGISTERED PSYCHOLOGIST?</span>",
    subtitle: "Start offering online counseling today! Wherever you are in the world, whenever you want, in any field of expertise in which you specialize! Choose how much time to dedicate to your daily consultations. Whether you decide to be online in real time or on booking based on your availability, the more availability you offer and the more users will be able to reach you! A new concept of online psychological counseling is born! Feel free, be Pronto!",
    action: {
      link: "/signup-consultant",
      label: "Signup Now",
    },
  };

  return (
    <>
      <PageBanner bannerData={bannerData} />
      <PrivacyPolicy />
      <SmartPortal />
      {/* <Register/> */}
      <Registeredpsychologist/>
      
      
    </>
  );
};

export default TermsandConditions;
